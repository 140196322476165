import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    loading: true,
    membershipsMap: {},
    membershipTypesLoading: false,
    membershipTypes: null,
};

const membershipsSlice = createSlice({
    name: 'memberships',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        membershipsSuccess(state, action) {
            state.loading = false;
            state.membershipsMap = action.payload.reduce((ret, membership) => ({
                ...ret,
                [membership.id]: membership,
            }), {});
        },
        membershipSuccess(state, action) {
            state.membershipsMap[action.payload.id] = action.payload;
        },
        membershipTypesLoading(state, action) {
            state.membershipTypesLoading = action.payload;
        },
        membershipTypesSuccess(state, action) {
            state.membershipTypesLoading = false;
            state.membershipTypes = action.payload;
        },
    }
});

export const { loading, membershipsSuccess, membershipSuccess, membershipTypesLoading, membershipTypesSuccess } = membershipsSlice.actions;

export default membershipsSlice.reducer;

export const fetchMemberships = (orgIds, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.memberships.getByOrgId(orgIds)
            .then(memberships => {
                return dispatch(membershipsSuccess(memberships));
            })
            .catch(error => {
                console.error(error);
                dispatch(loading(false));
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const addLocationToMembership = (membership, location, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const newMembership = {
            ...membership,
            locationId: location.id,
        };
        // Optimistic update
        dispatch(membershipSuccess(newMembership));

        return api.memberships.save({
            ...membership,
            locationId: location.id,
        })
            .catch(error => {
                console.error(error);
                dispatch(membershipSuccess(membership));
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const loadMembershipTypes = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(membershipTypesLoading(true));
        return api.membershipTypes.getAll()
            .then(membershipTypes => {
                dispatch(membershipTypesSuccess(membershipTypes));
            })
            .catch(e => {
                dispatch(membershipTypesLoading(false));
                enqueueSnackbar(e.message, {variant: 'error'});
            })
    }
)
