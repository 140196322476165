import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar/AppBar";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, subWeeks, format } from 'date-fns';
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import BackIcon from '@mui/icons-material/ArrowBack';

import GoogleHeatMap from "../../_common/googleHeatMap";
import Loading from "../../_common/loading";
import {useLocationReportSelector, useLocationSelector} from "../../../redux/locations/selectors";
import {fetchLocationReport} from "../../../redux/locations";
import {fetchLocation} from "../../../redux/locations";
import {loadAttributes} from "../../../redux/attributes/actions";
import {useMedicationsAvailableSelector, useSpecialtiesSelector} from "../../../redux/attributes";
import useReactRouter from "../../../hooks/useReactRouter";
import {parsePoint} from "../../../utils/point";
import {METERS_IN_MILE} from "../../../utils/meters";

import './index.css';
import TextField from "@mui/material/TextField";

const LocationReport = ({locationId}) => {
    const [startDate, setStartDate] = useState(subWeeks(new Date(), 1));
    const [endDate, setEndDate] = useState(addDays(new Date(), 1));
    const {report, locationReportLoading} = useLocationReportSelector(locationId);
    const location = useLocationSelector(locationId);
    const specialties = useSpecialtiesSelector();
    const medications = useMedicationsAvailableSelector();
    const dispatch = useDispatch();
    const { history } = useReactRouter();

    useEffect(() => {
        const pattern = "yyyy-MM-dd";
        dispatch(fetchLocationReport(locationId, format(startDate, pattern), format(endDate, pattern)));
    }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(loadAttributes());
        dispatch(fetchLocation(locationId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const distanceSearches = (report && report.searchesByDistance.reduce((result, search) => {
        const distanceInMiles = search.distanceInMeters / METERS_IN_MILE;
        result["50"].push(search);
        if (distanceInMiles <= 30) {
            result["30"].push(search);
        }
        if (distanceInMiles <= 10) {
            result["10"].push(search);
        }
        return result;
    }, {"50": [], "30": [], "10":[]})) || null;

    const specialtyRecords = specialties && specialties.map(specialty => (
        {
            id: specialty.id,
            name: specialty.name,
            value: _getSpecialtyCount(report, specialty.id)
        }
    )).filter(record => record.value > 0);

    const medicationRecords = medications && medications.map(medication => (
        {
            id: medication.id,
            name: medication.name,
            value: _getMedicationCount(report, medication.id)
        }
    )).filter(record => record.value > 0);;

    const onClose = () => history.goBack();

    return (
        <div className="app-page flex flex-col flex-1">
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-left w-full flex">
                        <div>
                            <IconButton onClick={onClose} >
                                <BackIcon htmlColor="#ffffff" />
                            </IconButton>
                        </div>
                        <div className="home-toolbar-title flex items-center">
                            {(location && location.name) || "Location Report"}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex p-10">
                <div className="mr-7 flex-1">
                    <div className="mb-2">
                        <Card>
                            <CardContent>
                                <div className="flex">
                                    <div>
                                        <DatePicker
                                            value={startDate}
                                            onChange={date => setStartDate(date)}
                                            inputFormat="MM/dd/yyyy"
                                            margin="dense"
                                            renderInput={(params) =>
                                                <TextField
                                                    variant="standard"
                                                    label="Start Date"
                                                    {...params}
                                                />
                                            }/>
                                        {/*<KeyboardDatePicker*/}
                                        {/*    disableToolbar*/}
                                        {/*    variant="inline"*/}
                                        {/*    format="MM/dd/yyyy"*/}
                                        {/*    margin="dense"*/}
                                        {/*    label="Start Date"*/}
                                        {/*    value={startDate}*/}
                                        {/*    onChange={date => setStartDate(date)}*/}
                                        {/*    autoOk={true}*/}
                                        {/*    KeyboardButtonProps={{*/}
                                        {/*        'aria-label': 'change date',*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </div>
                                    <div className="ml-7">
                                        <DatePicker
                                            value={endDate}
                                            onChange={date => setEndDate(date)}
                                            inputFormat="MM/dd/yyyy"
                                            margin="dense"
                                            renderInput={(params) =>
                                                <TextField
                                                    variant="standard"
                                                    label="End Date"
                                                    {...params}
                                                />
                                            }/>
                                        {/*<KeyboardDatePicker*/}
                                        {/*    disableToolbar*/}
                                        {/*    variant="inline"*/}
                                        {/*    format="MM/dd/yyyy"*/}
                                        {/*    margin="dense"*/}
                                        {/*    label="End Date"*/}
                                        {/*    value={endDate}*/}
                                        {/*    onChange={date => setEndDate(date)}*/}
                                        {/*    autoOk={true}*/}
                                        {/*    KeyboardButtonProps={{*/}
                                        {/*        'aria-label': 'change date',*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </div>
                                    <div className="ml-7">
                                        <div className="admin-query-report-label">
                                            Total # Searches
                                        </div>
                                        <div className="mt-2">
                                            {(report && report.totalSearches && report.totalSearches[0].numSearches) || 0}
                                        </div>
                                    </div>
                                    <div className="flex flex-1 justify-end items-center">
                                        {locationReportLoading &&
                                        <CircularProgress size={35} />
                                        }
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="flex-1">
                        <Card className="location-report-card">
                            <CardContent>
                                {!location ?
                                    <Loading size={45} />
                                    :
                                    <div>
                                        <div className="flex">
                                            <div className="flex-1">
                                                <div className="location-details-section-header">
                                                    Searches by Distance
                                                </div>
                                                <div className="flex flex-col mt-2">
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            &lt; 50 miles
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {(distanceSearches && distanceSearches["50"].length) || 0}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            &lt; 30 miles
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {(distanceSearches && distanceSearches["30"].length) || 0}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            &lt; 10 miles
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {(distanceSearches && distanceSearches["10"].length) || 0}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="location-details-section-header">
                                                    Searches by Specialty
                                                </div>
                                                <div className="flex flex-col mt-2">
                                                    {/*{location && location.attributes && location.attributes[attributeIds.practiceSpecialty] && location.attributes[attributeIds.practiceSpecialty].choice.map(specialtyId => (*/}
                                                    {/*    <div key={`specialty-${specialtyId}`} className="mb-2 flex">*/}
                                                    {/*        <div className="admin-query-report-label flex-1">*/}
                                                    {/*            {_getSpecialtyName(specialties, specialtyId)}*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className="flex-1 flex justify-center">*/}
                                                    {/*            {_getSpecialtyCount(report, specialtyId)}*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*))}*/}
                                                    {specialtyRecords && specialtyRecords.length ?
                                                        specialtyRecords.map(record => (
                                                            <div key={`specialty-${record.id}`} className="mb-2 flex">
                                                                <div className="admin-query-report-label flex-1">
                                                                    {record.name}
                                                                </div>
                                                                <div className="flex-1 flex justify-center">
                                                                    {record.value}
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div>No Searches by Specialty</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="location-details-section-header">
                                                    Searches by Medication
                                                </div>
                                                <div className="flex flex-col mt-2">
                                                    {medicationRecords && medicationRecords.length ?
                                                        medicationRecords.map(record => (
                                                            <div key={`medication-${record.id}`} className="mb-2 flex">
                                                                <div className="admin-query-report-label flex-1">
                                                                    {record.name}
                                                                </div>
                                                                <div className="flex-1 flex justify-center">
                                                                    {record.value}
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div>No Searches by Medication</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flex-1">
                                                <div className="location-details-section-header">
                                                    Events
                                                </div>
                                                <div className="flex flex-col mt-2">
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            # of Details Visits
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {_getEventCount(report,'visits')}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            # of Contacts
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {_getEventCount(report,'contact_emails')}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            # of Directions Clicks
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {_getEventCount(report,'directions_clicks')}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            # of Phone Clicks
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {_getEventCount(report,'phone_clicks')}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 flex">
                                                        <div className="admin-query-report-label flex-1">
                                                            # of Website Clicks
                                                        </div>
                                                        <div className="flex-1 flex justify-center">
                                                            {_getEventCount(report,'website_clicks')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1" />
                                            <div className="flex-1" />
                                        </div>
                                    </div>
                                }

                            </CardContent>
                        </Card>
                    </div>
                </div>
                <div className="flex-1 h-full">
                    <Card className="h-full relative">
                        <GoogleHeatMap
                            data={(report && report.searchesByDistance.map(search => parsePoint(search.point))) || []}
                            lat={location ? location.latitude : undefined}
                            lng={location ? location.longitude : undefined}
                            zoom={location ? 10 : undefined} />
                    </Card>
                </div>
            </div>
        </div>
    )
};

function _getSpecialtyCount(report, specialtyId) {
    if (report && report.searchesBySpecialties) {
        const specialty = report.searchesBySpecialties.find(search => search.specialtyId === specialtyId);
        if (specialty) {
            return specialty.Count;
        }
    }
    return 0;
}

function _getMedicationCount(report, medicationId) {
    if (report && report.searchesByMedication) {
        const medication = report.searchesByMedication.find(search => search.medicationId === medicationId);
        if (medication) {
            return medication.Count;
        }
    }
    return 0;
}


function _getEventCount(report, type) {
    if (report && report.events) {
        const event = report.events.find(event => event.type === type);
        if (event) {
            return event.Count;
        }
    }
    return 0;
}

export default LocationReport;
