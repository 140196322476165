import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import {useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/PersonAdd';
import {string} from 'yup';
import {useSnackbar} from "notistack";

import {useLocationSelector} from "../../../../redux/locations/selectors";
import {saveLocation} from "../../../../redux/locations";

import './index.css';

const EditLocationUsers = ({ locationId, saveRef, onClose }) => {
    const [addEmail, setAddEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const location = useLocationSelector(locationId);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onAddClick = async (users, setFieldValue) => {
        try {
            await string()
                .email('Please enter a valid email')
                .required('Please enter a valid email')
                .validate(addEmail);

            if (!users.includes(addEmail)) {
                setFieldValue(`users[${users.length}]`, addEmail);
            } else {
                enqueueSnackbar('Email already added', {variant: 'error'});
            }

            setAddEmail('');
            setEmailError(null);
        } catch (err) {
            setEmailError(err.message);
        }
    };

    return (
        <div>
            <Formik
                initialValues={{
                    users: [],
                    ...location
                }}
                onSubmit={(values) => {
                    dispatch(saveLocation(values, () => {}, null, false, enqueueSnackbar));
                    onClose();
                }}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <input type="submit" ref={saveRef} style={{display: "none"}} />
                        <div>
                            <div className="flex">
                                <div className="flex-1">
                                    <TextField variant="standard"
                                        name="email"
                                        type="email"
                                        label="User Email"
                                        placeholder="sue.smith@gmail.com"
                                        value={addEmail}
                                        onChange={event => setAddEmail(event.target.value)}
                                        onKeyDown={event => {
                                            if (event.keyCode === 13) {
                                                onAddClick(values.users, setFieldValue);
                                                event.preventDefault();
                                            }
                                        }}
                                        inputProps={{autoComplete: "off"}}
                                        error={Boolean(emailError)}
                                        helperText={emailError ? emailError : null}
                                        fullWidth />
                                </div>
                                <div className="ml-2 flex items-end">
                                    <Button variant="text" color="primary" onClick={() => onAddClick(values.users, setFieldValue)}>
                                        <AddIcon className="mr-1"/> Add User
                                    </Button>
                                </div>
                            </div>
                            <div className="mt-2">
                                {values.users.map((userEmail, index) => (
                                    <Chip
                                        key={userEmail}
                                        color="default"
                                        label={userEmail}
                                        className="mr-4 mb-2"
                                        onDelete={() => {
                                            const newEmails = [...values.users];
                                            newEmails.splice(index, 1);
                                            setFieldValue('users', newEmails, false);
                                        }}  />
                                ))}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

export default EditLocationUsers;
