import React, {useState, useEffect} from "react";
import {useSnackbar} from "notistack";
import {useLoginSelector} from "../redux/login/selectors";
import {useDispatch} from "react-redux";
import Button from "@mui/material/Button";
import {loginWithToken} from "../redux/login/";

function useStopImpersonatingSnackbar() {
    const [showStopImpersonating, setShowStopImpersonating] = useState(false);
    const { claims: { returnToken } } = useLoginSelector();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (returnToken && !showStopImpersonating) {
            setShowStopImpersonating(true);
            const snackbar = enqueueSnackbar("You are impersonating this user", {
                variant: "warning",
                persist: true,
                action: key => (
                    <div style={{color: "white"}}>
                        <Button variant="text" color="inherit" onClick={() => {
                            dispatch(loginWithToken(returnToken));
                            closeSnackbar(snackbar);
                            setShowStopImpersonating(false);
                        }}>
                            Stop Impersonating
                        </Button>
                    </div>
                )
            });
        }
    }, [returnToken]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useStopImpersonatingSnackbar;
