import {createSelector} from "@reduxjs/toolkit";

import {useShallowEqualSelector} from "../index";
import _ from "lodash";

const cartSelector = state => state.shoppingCart.cart;

export const isCartEmptySelector = createSelector(
    cartSelector, cart => !cart || cart.length === 0
);

/**
 * Returns the data necessary to render the Admin Query Report
 */
export const useShoppingCartSelector = () => {
    return useShallowEqualSelector(({ shoppingCart }) => {
        return _.pick(shoppingCart, ['showCart', 'cart', 'error', 'cartOrg']);
    });
};

export const discountCodeLoadingSelector = ({ shoppingCart }) => shoppingCart.discountCodeLoading;
export const discountCodeSelector = ({ shoppingCart }) => shoppingCart.discountCode;
export const salesTaxRatesSelector = ({ shoppingCart }) => shoppingCart.salesTaxRates;
