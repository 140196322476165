import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Card, IconButton, Divider, Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useHistory, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";

import Loading from "../_common/loading";
import AdminOnly from "../_common/adminOnly";
import { loadPurchasables, savePurchasablesOrder } from "../../redux/purchasables";
import {
    purchasablesLoadingSelector,
} from "../../redux/purchasables/selectors";
import AdminResourceItem from "./item";
import FullscreenDialog from "../_common/dialog/fullScreen";
import useSortedPurchasables from "../../hooks/useSortedPurchasables";

const AdminResources = () => {
    const { enqueueSnackbar } = useSnackbar();
    const sortedPurchasables = useSortedPurchasables();
    const purchasablesLoading = useSelector(purchasablesLoadingSelector);
    const history = useHistory();
    const { resourceId } = useParams();
    const dispatch = useDispatch();
    const [localSort, setLocalSort] = useState([]);

    useEffect(() => {
        dispatch(loadPurchasables(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (sortedPurchasables) {
            setLocalSort(sortedPurchasables.map(item => ({ id: item.id })));
        }
    }, [sortedPurchasables]);

    const localSortedPurchasables = useMemo(() => {
        if (sortedPurchasables) {
            return localSort.map(item => sortedPurchasables?.find(purchasable => purchasable.id === item.id));
        }
        return [];
    }, [localSort, sortedPurchasables]);

    const handleEditClick = (id) => {
        history.push(`/admin-resources/${id}`);
    };

    const onClose = () => history.push("/admin-resources");

    const onAddClick = () => {
        history.push("/admin-resources/add");
    };

    const onSortEnd = () => {
        dispatch(savePurchasablesOrder(localSort, enqueueSnackbar));
    };

    return (
        <AdminOnly>
            <div className="relative w-full">
                <div className="absolute right-4 -top-6 z-[1201]">
                    <Fab onClick={onAddClick} color="secondary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            <FullscreenDialog open={!!resourceId} onClose={onClose}>
                <AdminResourceItem resourceId={resourceId} />
            </FullscreenDialog>
            <div className="p-12">
                {purchasablesLoading ? (
                    <Loading size={45} />
                ) : (
                    <div>
                        <Card>
                            <ReactSortable
                                onEnd={onSortEnd}
                                animation={500}
                                list={localSort}
                                setList={setLocalSort}
                            >
                                {localSortedPurchasables.map((purchasable) => (
                                    <div id={purchasable.id} className="cursor-grab bg-white" key={purchasable.id}>
                                        <div className="flex items-center justify-between px-4 py-2 uppercase font-oswald">
                                            <div>{purchasable.title}</div>
                                            <div>
                                                <IconButton onClick={() => handleEditClick(purchasable.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Divider />
                                    </div>
                                ))}
                            </ReactSortable>
                        </Card>
                    </div>
                )}
            </div>
        </AdminOnly>
    );
};

export default AdminResources;
