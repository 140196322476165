import {
    QUERIES_LOADING,
    QUERIES_SUCCESS,
    QUERIES_ERROR
} from "./types";

const INITIAL_STATE = {
    loading: true,
    results: null,
    specialties: {},
    specialtyQueries: {},
    medicationsAvailable: {},
    medicationQueries: {},
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case QUERIES_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case QUERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                results: action.payload.all,
                specialties: action.payload.specialties.reduce((ret, record) => {
                    if (ret[record.sid]) {
                        ret[record.sid].push(record.qid);
                    } else {
                        ret[record.sid] = [record.qid];
                    }
                    return ret;
                }, {}),
                specialtyQueries: action.payload.specialties.reduce((ret, record) => {
                    ret[record.qid] = true
                    return ret;
                }, {}),
                medicationsAvailable: action.payload.medicationsAvailable.reduce((ret, record) => {
                    if (ret[record.mid]) {
                        ret[record.mid].push(record.qid);
                    } else {
                        ret[record.mid] = [record.qid];
                    }
                    return ret;
                }, {}),
                medicationQueries: action.payload.medicationsAvailable.reduce((ret, record) => {
                    ret[record.qid] = true
                    return ret;
                }, {}),
            };
        case QUERIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;