import React from 'react';
import TextField from '@mui/material/TextField';
import numeral from 'numeral';

/**
 * This component formats input as its entered into a $0,0k format
 */
class FormattedTextField extends React.Component {

    state = {
        formattedValue: this.formatValue(this.props.value || '0').formatted
    };

    onChange = (event) => {
        let formattedValue = this.formatValue(event.target.value);
        this.setState({
            formattedValue: formattedValue.formatted
        });
        this.props.onChange({
            target: {
                name: event.target.name,
                id: event.target.id,
                value: formattedValue.raw
            }
        });
    };

    formatValue(value) {
        if (value.indexOf('k') === -1 && value.indexOf('$') > -1) {
            value = value.substring(0, value.length - 1);
        }
        let numbers = value.replace(/[^0-9]+/g, "");
        if (!numbers.length) {
            numbers = "0";
        }
        return {
            formatted: `${numeral(numbers).format("$0,0")}k`,
            raw: numbers
        };
    }

    render() {
        return (
            <TextField variant="standard"
                {...this.props}
                value={this.state.formattedValue}
                onChange={this.onChange} />
        )
    }
}

export default FormattedTextField;
