import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from "@mui/material/CircularProgress";

import {useSpecialtiesSelector} from "../../../redux/attributes";

import './index.css';

const SpecialtySelect = ({name, value, onChange, labelId}) => {
    const specialties = useSpecialtiesSelector();

    return (
        <div className="specialty-select-container">
            {!specialties ?
                <CircularProgress size={35} />
                :
                <FormControl fullWidth variant="standard">
                    <InputLabel id={labelId}>Specialty</InputLabel>
                    <Select
                        labelId={labelId}
                        multiple
                        name={name}
                        onChange={onChange}
                        value={value}
                        variant="standard"
                        renderValue={selected => (
                            <span className="admin-location-report-specialties">
                            {_getSpecialtyById(selected[0], specialties).name} {selected.length > 1 ? `(+${selected.length - 1} more)` : null}
                        </span>
                        )}>
                        {specialties.map(specialty => {
                            return (
                                <MenuItem key={`specialty-${specialty.id}`} value={specialty.id}>
                                    {specialty.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            }
        </div>
    )
};

SpecialtySelect.defaultProps = {
    name: "specialties",
    labelId: "specialty-label"
};

function _getSpecialtyById(specialtyId, specialties) {
    return specialties.find(specialty => specialty.id === specialtyId);
}

export default SpecialtySelect;
