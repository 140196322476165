import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

import './index.css';

const AttributeOption = ({type, attributeId, parentId, parent, optionId, option, value, setFieldValue}) => {
    const { name } = option;

    const handleAttributeChange = event => {
        let { target: { checked, name } } = event;

        updateAttributeChoices({...value}, attributeId, checked, name);

        if (parent) {
            updateAttributeChoices({...parent}, parentId, checked, name);
        }
    };

    const updateAttributeChoices = (update, attributeId, checked, name) => {
        if (type === "many") {
            if (checked) {
                if (update.choice) {
                    update.choice.push(name);
                } else {
                    update.choce = [name];
                }
            } else {
                const index = update.choice.indexOf(name);
                if (index > -1) {
                    update.choice.splice(index, 1);
                }
            }
        } else {
            update.choice = [name];
        }

        setFieldValue(`attributes.${attributeId}`, update);
    };

    const optionSelected = item => Boolean(item && item.choice && item.choice.includes(optionId));

    return (
        <div className="location-details-attribute-option flex">
            <div className="flex items-start">
                {type === "many" ?
                    <Checkbox
                        name={optionId}
                        checked={optionSelected(value) || optionSelected(parent)}
                        value={optionId}
                        color="primary"
                        onChange={handleAttributeChange}
                        inputProps={{ 'aria-label': name }} />
                    :
                    <Radio
                        name={optionId}
                        checked={optionSelected(value) || optionSelected(parent)}
                        value={optionId}
                        color="primary"
                        onChange={handleAttributeChange}
                        inputProps={{ 'aria-label': name }} />
                }
            </div>
            <div className="flex mt-2">
                {name}
            </div>
        </div>
    )
};

export default AttributeOption
