import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Drawer from '@mui/material/Drawer';
import {useSnackbar} from "notistack";

import BillingInfo from "./billingInfo";
import Details from "./details";
import ThankYou from "./thankYou";
import {salesTaxRatesSelector, useShoppingCartSelector} from "../../redux/shoppingCart/selectors";
import {useLoginSelector} from "../../redux/login/selectors";
import {toggleCart, clearCart, fetchSalesTaxRates} from "../../redux/shoppingCart/";
import {cartItemTotal} from "../../utils/shoppingCart";
import {defaultOrgIdSelector} from "../../redux/organizations/selectors";

import closeIcon from '../../img/ic_close_white_24px.svg';
import cartIcon from '../../img/ic_shopping_cart_white_36px.svg';

import './index.css';

const ShoppingCart = () => {
    const [showBilling, setShowBilling] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const {cart, showCart} = useShoppingCartSelector();
    const {claims: {email}} = useLoginSelector();
    const salesTaxRates = useSelector(salesTaxRatesSelector);
    const cartOrg = useSelector(defaultOrgIdSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!salesTaxRates) {
            dispatch(fetchSalesTaxRates(enqueueSnackbar));
        }
    }, [dispatch, enqueueSnackbar, salesTaxRates]);

    const onToggleCart = () => {
        if (showCart && showThankYou) {
            onDoneClick();
        } else {
            dispatch(toggleCart());
        }
    };

    const cartTotal = cart.reduce((total, item) => total += cartItemTotal(item), 0);

    const onBackClick = () => setShowBilling(false);

    const onCheckoutClick = () => {
        setShowBilling(true);
    };

    const onDoneClick = () => {
        setShowBilling(false);
        setShowThankYou(false);
        dispatch(clearCart());
    };

    const mainContent = () => {
        if (showThankYou) {
            return (
                <ThankYou onDoneClick={onDoneClick} />
            )
        } else if (showBilling) {
            return (
                <BillingInfo {...{cart, cartTotal, onBackClick, setShowThankYou, email, cartOrg, salesTaxRates}} />
            )
        }
        return (
            <Details {...{cart, cartTotal, onCheckoutClick}} />
        )
    };

    return (
        <div className="relative">
            {/* use disableEnforceFocus to keep Plaid Link from fighting the Drawer for focus */}
            <Drawer anchor="right" open={showCart} onClose={onToggleCart} disableEnforceFocus>
                <div className="shopping-cart-container flex h-full">
                    <div className="shopping-cart-card flex flex-col flex-1">
                        <div className="shopping-cart-card-inner-container flex flex-1">
                            <div className="flex flex-col flex-1">
                                <div className="shopping-cart-card-top">
                                    <div className="flex">
                                        <div className="shopping-cart-card-title oswald flex-1">
                                            Shopping Cart
                                        </div>
                                        <div className="shopping-cart-close-button">
                                            <IconButton aria-label="close" onClick={onToggleCart}>
                                                <img src={closeIcon} alt="close icon" />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-2">
                                        <Button variant="text" color="secondary" onClick={() => {
                                            setShowThankYou(false);
                                            setShowBilling(false);
                                            dispatch(clearCart());
                                        }}>
                                            Clear Cart
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col">
                                    {mainContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <div className={"shopping-cart-handle" + (cart && cart.length ? " shopping-cart-container-visible" : "")} onClick={onToggleCart}>
                <img className="shopping-cart-handle-img" src={cartIcon} alt="shopping cart icon" />
            </div>
        </div>
    )
};

export default ShoppingCart;
