import React from 'react';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import 'date-fns';
import { snackbarErrorLogger, orgsApi, membershipsApi, purchasesApi } from "@devsontap/nica-redux-commons";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// TODO: Should this go elsewhere?


import api from './api';
import reducers from './redux';
import AuthProvider from "./components/_common/authProvider";
import Login from "./components/login";
import Home from './components/home';
import ErrorBoundary from "./components/_common/errorBoundary";

import './css/App.css';

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createTheme({
    palette: {
        primary: {
            main: '#1c5c7f',
            dark: '#1c5c7f',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#53c9e8',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    typography: {
        fontFamily: [
            'Lato, sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        useNextVariants: true
    },
    components: {
        // MuiInputAdornment: {
        //     root: {
        //         marginRight: "14px"
        //     }
        // },
        // MuiInputBase: {
        //     adornedEnd: {
        //         paddingRight: "0px !important"
        //     }
        // },
        MuiInput: {
            styleOverrides: {
                root: {
                    color: "#18496c",
                    fontSize: "18px",
                    fontWeight: 200,
                },
                underline: {
                    '&:before': { //underline color when textfield is inactive
                        borderBottom: '2px solid #D5D5D5',
                    },
                    '&:hover:not($disabled):before': { //underline color when hovered
                        borderBottom: '2px solid #A4A4A4',
                    },
                    '&:after': {
                        borderBottom: '2px solid #1c5c7f'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#A4A4A4',
                    fontSize: "18px",
                    fontWeight: 300
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    fontFamily: "Oswald",
                    fontSize: "16px",
                    fontWeight: 400,
                    minWidth: "80px"
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                button: {
                    paddingTop: "2px",
                    paddingBottom: "2px"
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginLeft: "5px",
                    minWidth: "50px"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    // zIndex: 1302
                },
                paperWidthSm: {
                    margin: "25px",
                    width: "100%"
                },
                paperFullScreen: {
                    margin: "0px",
                }
            }
        }
    }
});

/**
 * Create a Redux store and configure extra argument for Thunk middleware
 */
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { api }
            },
            serializableCheck: false,
        }).concat([orgsApi.middleware, membershipsApi.middleware, purchasesApi.middleware, snackbarErrorLogger]),
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS === "true",
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Provider store={store}>
                        <div className="App">
                            <ErrorBoundary>
                                <AuthProvider unAuthed={<Login />}>
                                    <Home />
                                </AuthProvider>
                            </ErrorBoundary>
                        </div>
                    </Provider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
