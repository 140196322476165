import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Button, Card, CardContent } from "@mui/material";
import { PersonAddOutlined, Edit, ShoppingCart, CloudDownload } from "@mui/icons-material";
import { Link } from "react-router-dom";

import ChooseLocationsDialog from "./chooseLocationsDialog";
import Loading from "../_common/loading";
import MemberResourceItem from "./item";
import useSortedPurchasables from "../../hooks/useSortedPurchasables";
import { fetchActivePurchases } from "../../redux/activePurchases/actions";
import { loadOrgLocations } from "../../redux/locations";
import { useActivePurchasesSelector } from "../../redux/activePurchases/selectors";
import { useLoginSelector } from "../../redux/login/selectors";
import { useLocationsByOrgSelector } from "../../redux/locations/selectors";
import { addItemToCart } from "../../redux/shoppingCart/";
import { cartItemTotal } from "../../utils/shoppingCart";
import { fetchMemberships } from "../../redux/memberships";
import { isMemberSelector, memberPricingTypeSelector } from "../../redux/memberships/selectors";
import { purchasablesLoadingSelector } from "../../redux/purchasables/selectors";

import {loadPurchasables} from "../../redux/purchasables";
import {defaultOrgIdSelector} from "../../redux/organizations/selectors";
import {orgIdsSelector} from "../../redux/login/selectors";

const MemberResources = () => {
    const [selectedPurchasable, setSelectedPurchasable] = useState(null);
    const { loading: activePurchasesLoading, resources: activePurchases } = useActivePurchasesSelector();
    const purchasablesLoading = useSelector(purchasablesLoadingSelector);
    const purchasables = useSortedPurchasables();
    const pricingType = useSelector(memberPricingTypeSelector);
    const isMember = useSelector(isMemberSelector);
    const orgIds = useSelector(orgIdsSelector);
    const { claims, numOrgIds } = useLoginSelector();
    const orgId = useSelector(defaultOrgIdSelector);
    const locations = useLocationsByOrgSelector(orgIds); // TODO: This needs to select ALL MAH DAMN locations (check locations.users)
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!purchasables || purchasables.length === 0) {
            dispatch(loadPurchasables(enqueueSnackbar));
        }
    }, [purchasables, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (orgId) {
            dispatch(fetchMemberships([orgId], enqueueSnackbar));
        }
    }, [dispatch, orgId, enqueueSnackbar]);

    useEffect(() => {
        if (orgId) {
            let cancelWatch = null;
            dispatch(fetchActivePurchases([orgId], (cancel) => (cancelWatch = cancel)));
            dispatch(loadOrgLocations([orgId]));

            return () => {
                if (cancelWatch) {
                    cancelWatch();
                }
            };
        }
    }, [dispatch, orgId, numOrgIds]);

    const onAddToCart = (purchasable, selectedLocations) => {
        let price = purchasable.pricing[pricingType];
        if (price === undefined || price === null) {
            price = purchasable.pricing["nonMember"];
        }

        const item = {
            autoRenew: false,
            locations: selectedLocations,
            purchasableId: purchasable.id,
            purchasableTitle: purchasable.title,
            price,
            credits: purchasable.credits,
            receipt_template_id: purchasable.receipt_template_id,
            applySalesTaxInStates: purchasable.applySalesTaxInStates,
        };

        // This accounts for the location multiplier
        item.total = cartItemTotal(item);

        // const item = new ShoppingCartItem(resource, optionIndex, selectedLocations || null);
        dispatch(addItemToCart(item, orgId));
    };

    const onClose = () => setSelectedPurchasable(null);

    const getSelectedPricingOption = () => {
        if (selectedPurchasable) {
            return selectedPurchasable.pricing[pricingType] || selectedPurchasable.pricing["nonMember"];
        }
        return null;
    };

    const onPriceOptionClick = (purchasable) => {
        if (purchasable.perLocation) {
            setSelectedPurchasable(purchasable);
        } else {
            onAddToCart(purchasable, null);
        }
    };

    console.log("activePurchasesLoading || purchasablesLoading || !purchasables || !locations", activePurchasesLoading, purchasablesLoading, !purchasables, !locations);

    return (
        <div className="app-page">
            {activePurchasesLoading || purchasablesLoading || !purchasables || !locations ? (
                <Loading size={45} />
            ) : (
                <div className="px-10 py-10">
                    <div className="mb-8">
                        <Card>
                            <CardContent>
                                <div className="font-oswald uppercase text-blue text-2xl mb-4">NICA Membership</div>
                                <div>
                                    NICA Provider Membership provides numerous benefits such as engagement opportunities,
                                    premium Infusion Center Locator features, and discounts on resources and events. We
                                    rely on the support and input of our provider members to inform our efforts to meet
                                    the unique needs of infusion providers.
                                </div>
                                <div className="mt-4 flex justify-center">
                                    {isMember && (
                                        <div className="mr-6">
                                            <Link to="/memberships">
                                                <Button variant="contained" color="primary">
                                                    <Edit className="mr-1" />
                                                    Manage Memberships
                                                </Button>
                                            </Link>
                                        </div>
                                    )}
                                    <div>
                                        <Button
                                            target="_blank"
                                            href={process.env.REACT_APP_MEMBERSHIPS_APP_URL}
                                            variant="contained"
                                            color="primary"
                                        >
                                            <PersonAddOutlined className="mr-1" />
                                            Join or Renew
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="mb-8">
                        <Card>
                            <CardContent>
                                <div className="font-oswald uppercase text-blue text-2xl mb-4">Invest in Excellence</div>
                                <div>
                                    The NICA Standards of Excellence for Ambulatory Infusion Centers are available for
                                    purchase in spiralbound print, view-only digital format, or as a set. NICA Members
                                    save 25% at the time of purchase. A free, downloadable digital copy of the NICA
                                    Standards is available <strong>exclusively to Elite Members.</strong>
                                </div>
                                <div className="mt-4 flex justify-center">
                                    <div>
                                        <Button
                                            target="_blank"
                                            href={process.env.REACT_APP_ACCREDITATION_STANDARDS_URL}
                                            variant="contained"
                                            color="primary"
                                        >
                                            <ShoppingCart className="mr-1" />
                                            Purchase Now
                                        </Button>
                                    </div>
                                    {pricingType === 'elite' && (
                                        <div className="ml-6">
                                            <Button
                                                target="_blank"
                                                href={process.env.REACT_APP_ACCREDITATION_STANDARDS_DOWNLOAD_URL}
                                                variant="contained" color="primary"
                                            >
                                                <CloudDownload className="mr-1" />
                                                Download PDF
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                        {purchasables.map((purchasable, index) => (
                            <MemberResourceItem
                                key={"member-resource-" + index}
                                purchasableId={purchasable.id}
                                index={index}
                                user={claims}
                                onPriceOptionClick={() => onPriceOptionClick(purchasable)}
                                activePurchases={activePurchases}
                            />
                        ))}
                    </div>
                    <ChooseLocationsDialog
                        open={selectedPurchasable !== null}
                        locations={locations}
                        priceOption={getSelectedPricingOption()}
                        user={claims}
                        onClose={onClose}
                        claims={claims}
                        onAddToCart={(locations) => {
                            onAddToCart(selectedPurchasable, locations);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default MemberResources;
