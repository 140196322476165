import React, {useEffect, useState, useMemo} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {addDays, format} from "date-fns";
import {
    Button, IconButton, Tooltip, TableHead, TableRow, TableCell, TableBody, Table, Paper, Radio
} from "@mui/material";
import {Edit} from "@mui/icons-material";

import NICADialog from "../_common/dialog";
import Loading from "../_common/loading";
import useOrgLocationsFilter from "../../hooks/useOrgLocations";
import {fetchMemberships, addLocationToMembership} from "../../redux/memberships";
import {
    memberPricingTypeSelector,
    membershipsLoadingSelector,
    membershipsSelector
} from "../../redux/memberships/selectors";
import {loadOrgLocations} from "../../redux/locations";
import {defaultOrgIdSelector} from "../../redux/organizations/selectors";

import './index.css';

const Memberships = () => {
    const [selectedMembership, setSelectedMembership] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const membershipsLoading = useSelector(membershipsLoadingSelector);
    const memberships = useSelector(membershipsSelector);
    const orgId = useSelector(defaultOrgIdSelector);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const locationList = useOrgLocationsFilter(orgId, false);
    const pricingType = useSelector(memberPricingTypeSelector);

    const nonMemberLocations = useMemo(() => {
        return locationList.filter(location => !location.isMember)
    }, [locationList]);

    const locationMap = useMemo(() => {
        return locationList.reduce((ret, location) => ({
            ...ret,
            [location.id]: location
        }), {});
    }, [locationList]);

    useEffect(() => {
        if (orgId) {
            dispatch(fetchMemberships([orgId], enqueueSnackbar));
        }
    }, [dispatch, orgId, enqueueSnackbar]);

    useEffect(() => {
        if (orgId) {
            dispatch(loadOrgLocations([orgId], enqueueSnackbar));
        }
    }, [dispatch, orgId, enqueueSnackbar]);

    const onAddLocationToMembership = () => {
        dispatch(addLocationToMembership(selectedMembership, selectedLocation, enqueueSnackbar));
        setSelectedMembership(null);
        setSelectedLocation(null);
    }

    return (
        <div className="app-page">
            <div className="memberships-container">
                {membershipsLoading || !memberships ?
                    <Loading size={45} />
                    :
                    <div>
                        {memberships.length ?
                            <Paper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                Membership Type
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                Purchased By
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                Location
                                            </TableCell>
                                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                Expiration Date
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {memberships.map((record, index) =>  (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {record.membershipTier.name}
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        {record.contactInfo.name}
                                                    </div>
                                                    <div>
                                                        {record.contactInfo.companyName}
                                                    </div>
                                                    <div>
                                                        {record.contactInfo.title}
                                                    </div>
                                                    <div>
                                                        {record.contactInfo.phoneNumber}
                                                        {record.contactInfo.phoneExt && ` +${record.contactInfo.phoneExt}`}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {pricingType === "elite" ?
                                                        <Tooltip title={<div className="text-lg">Your ELITE Membership covers all of your locations</div>}>
                                                            <div>
                                                                All ({locationList?.length}) locations covered
                                                            </div>
                                                        </Tooltip> :
                                                        record.locationId ?
                                                            <div className="flex items-center">
                                                                <div className="mr-1">
                                                                    {locationMap[record.locationId]?.name}
                                                                </div>
                                                                <div>
                                                                    <IconButton onClick={() => setSelectedMembership(record)}>
                                                                        <Edit />
                                                                    </IconButton>
                                                                </div>
                                                            </div> :
                                                            <div>
                                                                <Button variant="contained" color="primary" onClick={() => setSelectedMembership(record)}>
                                                                    Add Covered Location
                                                                </Button>
                                                            </div>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {record.autoRenew ? "Renews " : ""}{format(addDays(record.endDate, 1), "MM/dd/yyyy")}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                            :
                            <div>
                                No Memberships.
                            </div>
                        }
                    </div>
                }
            </div>
            <NICADialog
                open={selectedMembership !== null}
                title="Choose Location"
                onClose={() => setSelectedMembership(null)}
                actions={[
                    { label: 'Cancel', onClick: () => setSelectedMembership(null) },
                    { label: 'Select', onClick: onAddLocationToMembership, disabled: selectedLocation === null }
                ]}>
                <div>
                    <div className="text-lg text-center">
                        Don't see your location here?
                    </div>
                    <div className="text-sm text-center mb-2">
                        Click on "Location Manager" and add it there to show up here!
                    </div>
                    {nonMemberLocations.map(location => (
                        <div key={`location-${location.id}`}>
                            <div className="flex cursor-pointer mb-1" onClick={() => setSelectedLocation(location)}>
                                <div>
                                    <Radio checked={location === selectedLocation} />
                                </div>
                                <div className="flex-1">
                                    <div className="font-oswald text-xl text-blue">
                                        {location.name}
                                    </div>
                                    <div className="font-lato text-gray text-sm">
                                        {location.address.line1}, {location.address.city}, {location.address.stateCode} {location.address.zip}
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>

            </NICADialog>
        </div>
    )
};

export default Memberships;
