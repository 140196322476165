import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import { DiscountCodeTypes } from '@devsontap/nica-api/core/models/enums';

const DiscountCodeTypeSelect = ({value, onChange, fullWidth, disabled}) => {
    return (
        <FormControl fullWidth={fullWidth} disabled={disabled} variant="standard">
            <InputLabel id="organizationLabel">Discount Type</InputLabel>
            <Select
                name="type"
                value={value}
                onChange={onChange}
                variant="standard"
                labelId="organizationLabel"
                fullWidth={fullWidth}>
                {DiscountCodeTypes.map((type)=> {
                    return (
                        <MenuItem value={type.value} key={type.value}>{type.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>

    )
};

DiscountCodeTypeSelect.propTypes = {
    value: PropTypes.string.isRequired,
};

DiscountCodeTypeSelect.defaultProps = {
    fullWidth: false,
    disabled: false
};

export default DiscountCodeTypeSelect;
