import React from 'react';
import Button from '@mui/material/Button';

const ThankYou = ({onDoneClick}) => {
    return (
        <div className="flex-1 flex flex-col billing-info-container">
            <div className="oswald billing-info-thank-you-title">
                Thank You!
            </div>
            <div className="lato billing-info-thank-you-description">
                You will receive a confirmation email of your purchase.
            </div>
            <div className="mt-7 flex justify-center items-center">
                <Button variant="contained" color="primary" onClick={onDoneClick} className="billing-info-submit-button">Done</Button>
            </div>
        </div>
    )
};

export default ThankYou;
