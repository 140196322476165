import {useShallowEqualSelector} from "../index";

export const discountCodeSelector = id => ({ discountCodes }) => discountCodes.codes.find(code => code.id === id);

export const useDiscountCodesSelector = () => {
    return useShallowEqualSelector(({ discountCodes: { loading, codes } }) => {
        return {
            loading, codes
        }
    });
};