import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    loading: false,
    paymentMethodsMap: null,
};

const paymentMethodsSlice = createSlice({
    name: "paymentMethods",
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        paymentMethodsSuccess(state, action) {
            state.loading = false;
            state.paymentMethodsMap = action.payload.reduce(
                (ret, paymentMethod) => ({
                    ...ret,
                    [paymentMethod.id]: paymentMethod,
                }),
                {}
            );
        },
        deletePaymentMethodSuccess(state, action) {
            delete state.paymentMethodsMap[action.payload];
        },
    },
});

export const { loading, paymentMethodsSuccess, deletePaymentMethodSuccess } = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;

export const fetchPaymentMethods = (enqueueSnackbar) => (dispatch, getState, { api }) => {
    dispatch(loading(true));
    return api
        .getPaymentMethods()
        .then((paymentMethods) => {
            return dispatch(paymentMethodsSuccess(paymentMethods.data));
        })
        .catch((error) => {
            console.error(error);
            dispatch(loading(false));
            enqueueSnackbar(error.message, { variant: "error" });
        });
};

export const deletePaymentMethod =
    (paymentMethodId, enqueueSnackbar, onDelete = () => {}) =>
    (dispatch, getState, { api }) => {
        return api
            .deletePaymentMethod(paymentMethodId)
            .then(() => {
                onDelete();
                return dispatch(deletePaymentMethodSuccess(paymentMethodId));
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: "error" });
                onDelete();
            });
    };
