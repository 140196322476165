import { createSlice } from "@reduxjs/toolkit";

import {getLocal, saveLocal} from "../../utils/localStorage";

const KEY_SHOPPING_CART = "cart";

const INITIAL_STATE = {
    showCart: false,
    cart: getLocal(KEY_SHOPPING_CART, true) || [],
    error: null,
    discountCodeLoading: false,
    discountCode: null,
    salesTaxRates: null,
};

const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState: INITIAL_STATE,
    reducers: {
        addItem(state, action) {
            const item = action.payload;
            if (!_cartHasItem(state.cart, item)) {
                state.cart.push(item);
                state.showCart = true;
                _saveShoppingCart(state.cart);
            }
        },
        removeItemFromCart(state, action) {
            state.cart.splice(action.payload, 1);
            _saveShoppingCart(state.cart);
            state.showCart = state.cart.length > 0;
        },
        clearCart(state) {
            state.cart = [];
            state.showCart = false;
            _saveShoppingCart(state.cart);
        },
        toggleCart(state) {
            state.showCart = !state.showCart;
        },
        toggleItemAutoRenew(state, action) {
            state.cart[action.payload].autoRenew = !state.cart[action.payload].autoRenew;
        },
        discountCodeLoading(state, action) {
            state.discountCodeLoading = action.payload;
        },
        discountCodeSuccess(state, action) {
            state.discountCodeLoading = false;
            state.discountCode = action.payload;
        },
        salesTaxRatesSuccess(state, action) {
            state.salesTaxRates = action.payload;
        }
    }
});

export const {
    addItem,
    removeItemFromCart,
    clearCart,
    toggleCart,
    toggleItemAutoRenew,
    discountCodeLoading,
    discountCodeSuccess,
    salesTaxRatesSuccess
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;

function _saveShoppingCart(cart) {
    saveLocal(KEY_SHOPPING_CART, cart, true);
}

function _cartHasItem(cart, item) {
    return Boolean(cart.find(cartItem => cartItem.purchasableId === item.purchasableId && cartItem.option.price === item.option.price));
}

export const addItemToCart = (item) => (
    (dispatch) => {
        dispatch(addItem(item));
    }
);

/**
 * TODO: Handle error and what to showzor?
 */
export const checkout = (paymentMethodId, paymentType, cart, total, taxableTotal, salesTaxInPennies, email, orgId, contactInfo, setLoading, setShowThankYou, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        //KBM - add email to contact info
        contactInfo.email = email;
        return api.checkout(orgId, total, taxableTotal, salesTaxInPennies, email, cart, contactInfo, paymentType, paymentMethodId)
            .then(activePurchases => {
                setLoading(false);
                setShowThankYou(true);
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const loadDiscountCode = (slug, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(discountCodeLoading(true));

        const cartItemIds = getState().shoppingCart.cart.map(item => item.purchasableId);
        const userEmail = getState().login.claims.email;

        return api.discountCodes.getBySlug(slug)
            .then(discountCodes => {
                const code = discountCodes[0];
                if (code) {
                    if (!code.restrictByEmails.length || code.restrictByEmails.includes(userEmail)) {
                        if ((!code.restrictByPurchasables.length && !code.restrictByMembershipTypes.length) || code.restrictByPurchasables.filter(purchasableId => cartItemIds.includes(purchasableId)).length > 0) {
                            enqueueSnackbar(`Discount code ${slug} applied!`, {variant: 'success'});
                            return dispatch(discountCodeSuccess(code));
                        }
                    }
                }
                enqueueSnackbar("Not a valid discount code", {variant: 'error'});
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar(e.message, {variant: 'error'});
            })
            .finally(() => {
                dispatch(discountCodeLoading(false));
            })
    }
)

export const fetchSetupIntent = (enqueueSnackbar, onSuccess) => (
    async (dispatch, getState, { api }) => {
        return api.getSetupIntent()
            .then(intent => {
                console.log("intent", intent);
                onSuccess && onSuccess(intent.data);
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar(e.message, {variant: 'error'});
            })
    }
)

export const fetchSalesTaxRates = (enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        return api.getSalesTaxRates()
            .then(rates => {
                // console.log("tax rates", rates);
                dispatch(salesTaxRatesSuccess(rates));
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar(e.message, {variant: 'error'});
            })
    }
)
