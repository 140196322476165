import {useShallowEqualSelector} from "../index";

/**
 * Returns the data necessary to render the Admin Query Report
 */
export const useActivePurchasesSelector = () => {
    return useShallowEqualSelector(({ activePurchases: { loading, resources, error } }) => {
        return {
            loading,
            resources,
            error
        }
    });
};

export const useActivePurchasableSelector = (purchasableId) => {
    return useShallowEqualSelector(({ activePurchases: { purchasablesMap } }) => {
        return purchasablesMap[purchasableId];
    });
};

export const useNumMembershipsSelector = (purchasableId) => {
    return useShallowEqualSelector(({ activePurchases: { purchasablesMap } }) => {
        const indMemberships = purchasablesMap["individual_membership"];
        const locMemberships = purchasablesMap["location_membership"];
        return (indMemberships ? indMemberships.length : 0) + (locMemberships ? locMemberships.length : 0);
    });
};