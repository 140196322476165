import React, { forwardRef } from "react";
import { Select, SelectProps, MenuItem, FormControl, InputLabel } from "@mui/material";

interface Props extends SelectProps {
    enumObj: {
        [key: string]: {
            value: string;
            label: string;
        };
    };
    labelId: string;
}

const EnumTypeSelect: React.FC<Props> = forwardRef(({ enumObj, ...selectProps }, ref) => {
    return (
        <FormControl fullWidth variant={selectProps.variant}>
            <InputLabel id={selectProps.labelId}>{selectProps.label}</InputLabel>
            <Select {...selectProps} inputRef={ref}>
                {Object.values(enumObj).map(({ value, label }) => (
                    <MenuItem key={`menu-item-${value}`} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export default EnumTypeSelect;
