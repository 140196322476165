import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from "@mui/material/TextField";

import CustomFormikField from "../../../_common/formik/customField";

import './index.css';

const DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

const BusinessHours = ({value, businessHoursTemporarilyAffected, setFieldValue}) => {
    const handleBusinessHoursChange = (key, date) => {
        if (date) {
            setFieldValue('businessHours', {
                ...value,
                [key]: `${date.getHours()}:${date.getMinutes()}`
            });
        }
    };

    // Sends a fake change event to Formik whenever a row is toggled. Defaults to 9am open and 5pm close
    const onRowChange = (event) => {
        const {name: day, checked} = event.target;
        const newValues = {...value};
        if (checked) {
            newValues[_getOpenKey(day)] = "9:0";
            newValues[_getCloseKey(day)] = "17:0";
        } else {
            delete newValues[_getOpenKey(day)];
            delete newValues[_getCloseKey(day)];
        }
        setFieldValue('businessHours', newValues);
    };

    return (
        <div className="location-edit-business-hours-container">
            {DAYS.map((day, index) => {
                const rowChecked = _isRowChecked(value, day);

                return (
                    <div className="flex location-edit-business-hours-row" key={"bizhrs-" + index}>
                        <div className="flex flex-1">
                            <div className="flex justify-center items-center">
                                <Checkbox
                                    name={day}
                                    color="primary"
                                    checked={rowChecked}
                                    onChange={onRowChange} />
                            </div>
                            <div className="location-details-business-hours-label flex justify-center items-center">
                                {day}
                            </div>
                        </div>
                        {rowChecked &&
                        <div className="flex items-center">
                            <div>
                                <TimePicker
                                    margin="normal"
                                    variant="standard"
                                    id={"location-details-business-hours-" + day + "-open"}
                                    value={_getOpenDate(value, day)}
                                    onChange={handleBusinessHoursChange.bind(null, _getOpenKey(day))}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </div>
                            <div className="location-details-business-hours-from flex justify-center items-center">
                                to
                            </div>
                            <div>
                                <TimePicker
                                    margin="normal"
                                    variant="standard"
                                    id={"location-details-business-hours-" + day + "-close"}
                                    value={_getCloseDate(value, day)}
                                    onChange={handleBusinessHoursChange.bind(null, _getCloseKey(day))}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </div>
                        </div>
                        }
                    </div>
                )
            })}
            <div className="mt-4">
                <div className="flex">
                    <div className="flex items-center">
                        <CustomFormikField
                            CustomComponent={Checkbox}
                            name="businessHoursTemporarilyAffected"
                            type="checkbox"
                            color="primary" />
                    </div>
                    <div className="location-details-label flex items-center">
                        <strong>Business Hours are temporarily affected</strong>
                    </div>
                </div>
                <div className="mt-2">
                    <CustomFormikField
                        CustomComponent={TextField}
                        variant="standard"
                        name="businessHoursTemporaryMessage"
                        label="Temporary Business Hours Reason"
                        placeholder="COVID-19"
                        helperText="Please tell us why these business hours are temporary."
                        inputProps={{maxLength: 500}}
                        fullWidth={true} />
                </div>
            </div>
        </div>
    )
};

const _getOpenKey = day => `${day.toLowerCase()}Open`;
const _getCloseKey = day => `${day.toLowerCase()}Close`;
const _isRowChecked = (businessHoursValues, day) => !!(businessHoursValues && businessHoursValues[_getOpenKey(day)]);
const _getOpenDate = (businessHoursValues, day) => _getDateForTime(businessHoursValues[_getOpenKey(day)]);
const _getCloseDate = (businessHoursValues, day) => _getDateForTime(businessHoursValues[_getCloseKey(day)]);

const _getDateForTime = (time) => {
    let date = new Date();
    let timeParts = time.split(":");
    let hr = timeParts[0];
    let min = timeParts[1];
    date.setHours(hr);
    date.setMinutes(min);
    return date;
};



export default BusinessHours;
