import {
    ACTIVE_PURCHASES_LOADING,
    ACTIVE_PURCHASES_SUCCESS
} from "./types";

/**
 * Fetches all the active purchases for the supplied OrgId
 */
export const fetchActivePurchases = (orgIds, cancelCallback) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: ACTIVE_PURCHASES_LOADING });
        const cancel = api.watchActivePurchases(orgIds, purchases => {
            return dispatch({ type: ACTIVE_PURCHASES_SUCCESS, payload: purchases });
        });

        cancelCallback(cancel);

        return true;

        // return api.getActivePurchases(orgIds)
        //     .then(purchases => {
        //         return dispatch({ type: ACTIVE_PURCHASES_SUCCESS, payload: purchases });
        //     })
        //     .catch(error => {
        //         console.error(error);
        //         dispatch({ type: ACTIVE_PURCHASES_ERROR, error });
        //     })
    }
);
