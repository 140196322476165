import React, {useState, useEffect} from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import ImportSummary from "./importSummary";
import {getBulkImportLocations, bulkImportLocations} from "../../../redux/locations";

import './index.css';

const BulkUpload = ({ onClose }) => {
    const [loading, setLoading] = useState(true);
    const [importing, setImporting] = useState(false);
    const [results, setResults] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        dispatch(getBulkImportLocations(setLoading, setResults, enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    const onImport = () => {
        setImporting(true);
        dispatch(bulkImportLocations(results.locations, setImporting, onClose, enqueueSnackbar));
    };

    return (
        <div>
            {loading || !results ?
                <div className="flex justify-center items-center">
                    <CircularProgress size={45} />
                </div>
                :
                <div>
                    <ImportSummary results={results} />
                    <div className="mt-4 flex justify-center items-center">
                        {importing ?
                            <CircularProgress size={35} />
                            :
                            <Button variant="contained" color="secondary" onClick={onImport}>
                                Looks Good, Start Import
                            </Button>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export default BulkUpload;
