import _ from 'lodash';

import {useShallowEqualSelector} from "../index";
import {
    ADMIN_RESOURCES_LOADING,
    ADMIN_RESOURCES_SUCCESS,
    ADMIN_RESOURCES_ERROR,
    ADMIN_RESOURCE_UPDATE
} from "./types";

const INITIAL_STATE = {
    loading: true,
    resources: [],
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_RESOURCES_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADMIN_RESOURCES_SUCCESS:
            return {
                ...state,
                loading: false,
                resources: action.payload
            };
        case ADMIN_RESOURCES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ADMIN_RESOURCE_UPDATE:
            const resources = [...state.resources];
            const resource = {
                ...resources[action.payload.index],
                ...action.payload.update
            };
            resources[action.payload.index] = resource;
            return {
                ...state,
                resources
            };
        default:
            return state;
    }
}

/**
 * Returns the data necessary to render the Admin Query Report
 */
export const useAdminResourcesSelector = () => {
    return useShallowEqualSelector(({ adminResources }) => {
        return _.pick(adminResources, ['loading', 'resources', 'error']);
    });
};

export default reducer;