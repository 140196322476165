/*global google*/
/*eslint no-undef: "error"*/

import React, { useState, useRef, useEffect } from 'react';
import TextField from "@mui/material/TextField";

import './index.css';

const PlacesSearch = ({ map, onPlaceSelected }) => {
    const ref = useRef(null);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (map && ref.current) {
            const options = {
                // bounds: defaultBounds,
                // KBM - For some reason, "as" does not seem to work, and removing this is the only way to get American Samoa addresses to show /shrug
                // componentRestrictions: { country: ["us", "pr", "vi", "gu", "np", "ws"] },
                fields: ["address_component", "geometry.location"],
                strictBounds: false,
                types: ["establishment", "geocode"],
            };

            const autocomplete = new google.maps.places.Autocomplete(ref.current, options);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                onPlaceSelected(place);
                setValue('');
            });
        }
    }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <TextField variant="standard"
            placeholder="1234 Place Dr, Austin, TX 78704"
            label="Address Search"
            inputRef={ref}
            value={value}
            onChange={e => setValue(e.target.value)}
            fullWidth />
    )
};

export default PlacesSearch;
