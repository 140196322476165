import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import { medicationsAvailable } from '@devsontap/nica-api/core/utils/attributeIds';

import Loading from "../../../_common/loading";
import AttributeSection from "./attributeSection";
import CustomFormikField from "../../../_common/formik/customField";
import {useAttributeListSelector} from "../../../../redux/attributes";
import {loadAttributes} from "../../../../redux/attributes/actions";

import './index.css';

const Attributes = ({setFieldValue}) => {
    const { loading, attributeIds } = useAttributeListSelector();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!attributeIds) {
            dispatch(loadAttributes());
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (loading ?
        <Loading size={45} />
        :
        attributeIds && attributeIds.map((attributeId, index) => (
            attributeId !== medicationsAvailable && <CustomFormikField
                CustomComponent={AttributeSection}
                name={`attributes.${attributeId}`}
                key={`attribute-section-${attributeId}`}
                attributeId={attributeId}
                errorField="choice"
                setFieldValue={setFieldValue}
                fast />
        ))
    );
};

export default Attributes