import {getLocal, saveLocal} from "../../utils/localStorage";
import {createSlice} from "@reduxjs/toolkit";
import {orgsSuccess as baseOrgsSuccess} from "../organizations";

const KEY_LOGGED_IN = "loggedIn";
const KEY_CLAIMS = "claims";
const KEY_EMAIL = "authEmail";
const KEY_ORG_IDS = "orgIdz";

const INITIAL_STATE = {
    authEmail: getLocal(KEY_EMAIL),
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    claims: getLocal(KEY_CLAIMS, true) || {},
    orgIds: getLocal(KEY_ORG_IDS, true) || [],
    loggingIn: false
};

const loginSlice = createSlice({
    name: 'login',
    initialState: INITIAL_STATE,
    reducers: {
        userAuthed(state, action) {
            console.log("userAuthed", action.payload);
            saveLocal(KEY_CLAIMS, action.payload, true);
            saveLocal(KEY_LOGGED_IN, true, false);

            const orgIds = action.payload.orgIds || [];
            saveLocal(KEY_ORG_IDS, orgIds, true);

            state.loggedIn = true;
            state.claims = action.payload;
            state.orgIds = orgIds;
            state.loggingIn = false;
        },
        userUnauthed(state, action) {
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);

            state.loggedIn = false;
            state.claims = {};
        },
        emailLinkInit(state, action) {
            saveLocal(KEY_EMAIL, action.payload);

            state.authEmail = action.payload;
        },
        orgsSuccess(state, action) {
            const orgIds = _addIdsIfMissing(action.payload.map(org => org.id), state.orgIds);
            saveLocal(KEY_ORG_IDS, orgIds, true);

            state.orgIds = orgIds;
        },
        loggingIn(state, action) {
            state.loggingIn = action.payload;
        },
        savePrimaryOrgId(state, action) {
            state.claims.primaryOrgId = action.payload;
            saveLocal(KEY_CLAIMS, state.claims, true);
        }
    }
});

export const { userUnauthed, emailLinkInit, orgsSuccess, loggingIn, savePrimaryOrgId } = loginSlice.actions;

export default loginSlice.reducer;

function _addIdsIfMissing(newIds, ids) {
    const ret = [...ids];
    newIds.forEach(id => {
        if (!ids.includes(id)) {
            ret.push(id);
        }
    });
    return ret;
}

export const userAuthed = (claims) => (
    (dispatch, getState, { api }) => {
        dispatch(loginSlice.actions.userAuthed(claims));
        if (!claims.orgIds || !claims.orgIds.length) {
            console.log("fetching user orgs", claims.email);
            const cancel = api.watchUserOrganizations(claims.email, orgs => {
                if (orgs && orgs.length) {
                    cancel();
                    document.location.reload();
                }
            });
        }
        return api.getUserOrganizations(claims.email)
            .then(orgs => {
                dispatch(orgsSuccess(orgs));
                dispatch(baseOrgsSuccess(orgs));
            })
    }
);

export const initEmailLinkAuth = (email, setLoading, setEmail, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(emailLinkInit(email));
        return api.sendEmailLink(email)
            .then(() => {
                setLoading(false);
                setEmail(email);
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const signInWithEmailLink = (email, setLoading, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        if (api.isSignInWithEmailLink() && email) {
            dispatch(loggingIn(true));
            return api.loginWithLink(email)
                .catch(error => {
                    // TODO: Need to remember what this was for
                    // dispatch({ type: CLEAR_LOGIN });
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(error.message, {variant: 'error'});
                });
        }
        enqueueSnackbar("Trying to sign in without SignIn Link", {variant: 'error'});
        // TODO: Need to remember what this was for
        // return dispatch({ type: CLEAR_LOGIN });
        return false;
    }
);

export const logout = () => (
    async (dispatch, getState, { api }) => {
        await api.logout()
            .then(() => localStorage.clear());
    }
);

export const impersonateUser = (email, setLoading, onClose, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        return api.getUserToken(email)
            .then(response => {
                console.log("token response", response);
                if (response.data) {
                    onClose();
                    return dispatch(loginWithToken(response.data));
                } else {
                    setLoading(false);
                    enqueueSnackbar("no user found", {variant: 'error'});
                }
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const loginWithToken = (token) => (
    async (dispatch, getState, { api }) => {
        return api.loginWithToken(token);
    }
);

export const createSessionCookie = () => (
    async (dispatch, getState, { api }) => {
        return api.users.createSessionCookie()
            .catch(err => {
                console.error(err);
            })
    }
);

export const getUserInfo = (setLoading) => (
    async (dispatch, getState, { api }) => {
        return api.users.getUserInfo(document.domain)
            .then(response => {
                if (response.token) {
                    dispatch(loginWithToken(response.token));
                }
            })
            .catch(err => {
                if (setLoading) {
                    setLoading(false);
                }
                console.error(err);
            })
    }
);
