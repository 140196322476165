import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from "@mui/material/CircularProgress";
import {useDispatch} from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {subMonths, compareAsc} from "date-fns";

import SpecialtySelect from "../_common/specialtySelect";
import NICADialog from "../_common/dialog";
import StateSelect from "../_common/stateSelect";
import AdminOnly from "../_common/adminOnly";
import {loadAttributes} from "../../redux/attributes/actions";
import {loadLocationsWithEmail, sendBulkMessage} from "../../redux/locations";
import {useLocationsBySpecialtiesSelector, useLocationListSelector} from "../../redux/locations/selectors";

import './index.css';

const AdminBulkMessage = () => {
    const [message, setMessage] = useState('');
    const [stateCode, setStateCode] = useState('TX');
    const [zip, setZip] = useState('');
    const [lastUpdatedDate, setLastUpdatedDate] = useState(subMonths(new Date(), 6));
    const [medications, setMedications] = useState(false);
    const [specialtyIds, setSpecialtyIds] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const locationsBySpecialties = useLocationsBySpecialtiesSelector(specialtyIds, true);
    const [locations, setLocations] = useState(locationsBySpecialties);
    const { loading } = useLocationListSelector();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAttributes());
        dispatch(loadLocationsWithEmail());
    }, [dispatch]);

    useEffect(() => {
        if (locationsBySpecialties) {
            const filteredLocations = locationsBySpecialties
                .filter(location => {
                    return compareAsc(lastUpdatedDate, new Date(location.updatedAt)) > 0;
                })
                .filter(location => {
                    if (stateCode.length > 0) {
                        return location.address.stateCode === stateCode;
                    }
                    return true;
                })
                .filter(location => {
                    if (zip.length > 0) {
                        return location.address.zip.includes(zip);
                    }
                    return true;
                })
                .filter(location => {
                    if (medications) {
                        if (location.medications) {
                            return location.medications.length < 2
                        }
                        return true;
                    }
                    return true;
                });

            setLocations(filteredLocations);
        }
    }, [loading, lastUpdatedDate, stateCode, zip, medications]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendMessage = () => {
        setShowConfirm(false);
        setMessage('');
        setSpecialtyIds([]);
        dispatch(sendBulkMessage(locations.map(location => location.id), message));
    };

    return (
        <AdminOnly>
            <div className="p-7">
                <Card>
                    <CardContent>
                        <div className="flex">
                            <div>
                                <SpecialtySelect onChange={event => setSpecialtyIds(event.target.value)} value={specialtyIds} />
                            </div>
                            <div className="ml-7">
                                <DatePicker
                                    value={lastUpdatedDate}
                                    onChange={date => setLastUpdatedDate(date)}
                                    inputFormat="MM/dd/yyyy"
                                    renderInput={(params) =>
                                        <TextField variant="standard" {...params}/>
                                    }/>
                                {/*<DateTimePicker*/}
                                {/*    disableToolbar*/}
                                {/*    variant="inline"*/}
                                {/*    format="MM/dd/yyyy"*/}
                                {/*    margin="none"*/}
                                {/*    label="Last Updated Before"*/}
                                {/*    value={lastUpdatedDate}*/}
                                {/*    onChange={date => setLastUpdatedDate(date)}*/}
                                {/*    autoOk={true}*/}
                                {/*    KeyboardButtonProps={{*/}
                                {/*        'aria-label': 'change date',*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </div>
                            <div className="ml-7">
                                <StateSelect onStateChanged={event => setStateCode(event.target.value)} value={stateCode} showLabel />
                            </div>
                            <div className="ml-7">
                                <TextField variant="standard"
                                    name="zip"
                                    label="Zip"
                                    value={zip}
                                    onChange={event => setZip(event.target.value)}
                                    inputProps={{maxLength: 5}}
                                    fullWidth />
                            </div>
                            <div className="ml-7 flex items-end">
                                <div className="flex items-center">
                                    <Checkbox
                                        name="medications"
                                        color="primary"
                                        checked={medications}
                                        onChange={event => setMedications(event.target.checked)} />
                                    &lt; 2 Medications
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <div className="mt-10">
                    <TextField variant="standard"
                        id="message"
                        label="Message"
                        rowsMax="10"
                        value={message}
                        onChange={event => setMessage(event.target.value)}
                        onKeyDown={event => {
                            if (event.keyCode === 13) {
                                setShowConfirm(true);
                            }
                        }}
                        fullWidth
                        multiline />
                </div>
                <div className="flex justify-end mt-5">
                    <Button variant="contained" color="secondary" disabled={message.length < 10} onClick={() => setShowConfirm(true)}>
                        <SendIcon />&nbsp;&nbsp;Send
                    </Button>
                </div>
                <div className="flex">
                    <div>
                        Locations:
                    </div>
                    <div className="ml-5">
                        {!loading && locations ?
                            locations.length
                            :
                            <CircularProgress size={25} />
                        }
                    </div>

                </div>
                <NICADialog
                    open={showConfirm}
                    onClose={() => setShowConfirm(false)}
                    title={`Send Message to ${locations && locations.length} Locations`}
                    actions={[
                        {label: 'Cancel', onClick: () => setShowConfirm(false)},
                        {label: 'Confirm', onClick: sendMessage}
                    ]}>
                    <div className="lato blue-text">
                        {message}
                    </div>
                </NICADialog>
            </div>
        </AdminOnly>
    )
};

export default AdminBulkMessage;
