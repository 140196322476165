import React from 'react';
import {useSelector} from "react-redux";
import {purchasesApi} from "@devsontap/nica-redux-commons";

import PurchaseHistoryItem from "./item";
import Loading from "../_common/loading";
import {defaultOrgIdSelector} from "../../redux/organizations/selectors";

const PurchaseHistory = () => {
    const orgId = useSelector(defaultOrgIdSelector);
    const {data: items, isLoading: loading} = purchasesApi.useGetPurchaseHistoryQuery(orgId);

    return (
        <div className="app-page">
            {loading || !items ?
                <Loading size={45}/>
                :
                <div className="p-10">
                    {items.length ?
                        items.map((item, index) => {
                            return (
                                <PurchaseHistoryItem item={item} key={`purchase-history-item-${index}`} />
                            )
                        })
                        :
                        <div>
                            No items to show.
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default PurchaseHistory;
