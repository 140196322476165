import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import NicaApi from "@devsontap/nica-api";
import { deleteSessionCookie } from "@devsontap/nica-api/core/utils/cookies";

function initFirebase() {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
    });
    // KBM - For testing
    // firebase.functions().useEmulator("localhost", 5009);
}

export function isDev() {
    return document.domain !== "admin.infusioncenter.org" && document.domain !== "nica-admin.web.app";
    // return false;
}

export function isLocal() {
    return document.domain === "localhost";
}

initFirebase();
const api = new NicaApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);

/**
 * Firebase Auth callback when state changes
 * @param onAuthChanged
 */
api.onAuthStateChanged = (onAuthChanged) => {
    firebase.auth().onAuthStateChanged(onAuthChanged);
};

api.sendEmailLink = (email) => {
    return firebase.auth().sendSignInLinkToEmail(email, {
        url: `${document.location.origin}/signInWithLink`,
        handleCodeInApp: true,
    });
};

api.isSignInWithEmailLink = () => {
    return firebase.auth().isSignInWithEmailLink(window.location.href);
};

api.loginWithLink = (email) => {
    if (email) {
        return firebase.auth().signInWithEmailLink(email, window.location.href);
    }
    return false;
};

api.loginWithToken = (token) => {
    return firebase.auth().signInWithCustomToken(token);
};

api.logout = () => {
    deleteSessionCookie();
    return firebase.auth().signOut();
};

api.uploadFile = async (file, filepath) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(filepath + "." + extension);

    const snapshot = await fileRef.put(file);
    const downloadUrl = await snapshot.ref.getDownloadURL();
    return downloadUrl;
};

api.getPlaidLinkToken = async () => {
    return firebase.functions().httpsCallable("stripe-getPlaidLinkToken")();
};

api.exchangePlaidPublicToken = async (data) => {
    return firebase.functions().httpsCallable("stripe-exchangePlaidPublicToken")(data);
};

api.getPaymentMethods = async () => {
    return firebase.functions().httpsCallable("stripe-paymentMethods")();
};

api.deletePaymentMethod = async (paymentMethodId) => {
    return firebase.functions().httpsCallable("stripe-deletePaymentMethod")({ paymentMethodId });
};

api.getSetupIntent = async () => {
    return firebase.functions().httpsCallable("stripe-setupIntent")();
};

api.getOrderedPurchasables = async () => {
    const purchasables = await api.purchasables.getAll();
    // const order = purchasables.map((p) => p.id);
    const orderRef = await firebase.database().ref("purchasablesSortOrder").once("value");

    return {
        purchasables,
        order: orderRef.val() || purchasables.map((p) => p.id),
    };
};

api.savePurchasablesSortOrder = async (purchasablesOrder) => {
    return firebase.database().ref("purchasablesSortOrder").set(purchasablesOrder);
};

api.setPrimaryOrgId = async (userId, orgId) => {
    return firebase.database().ref(`primaryOrg/${userId}`).set(orgId);
}

// NOTE: Moved this in here to avoid updating nica-api
api.checkout = (orgId, total, taxableTotal, salesTaxInPennies, user, cart, contactInfo, paymentType, paymentMethodId) => {
    return firebase.functions().httpsCallable("stripe-processPurchase")({
        orgId,
        total,
        taxableTotal,
        salesTaxInPennies,
        user,
        cart,
        contactInfo,
        paymentType,
        paymentMethodId,
        userAgent: window.navigator.userAgent,
    });
}

export default api;
