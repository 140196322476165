import {
    PURCHASE_HISTORY_LOADING,
    PURCHASE_HISTORY_SUCCESS,
    PURCHASE_HISTORY_ERROR,
    PURCHASE_HISTORY_REMOVE_SUBSCRIPTION
} from "./types";

const INITIAL_STATE = {
    loading: true,
    items: [],
    itemMap: {},
    itemIds: null,
    error: null,
    lastUpdate: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PURCHASE_HISTORY_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case PURCHASE_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                itemMap: action.payload.reduce((ret, purchase) => {
                    ret[purchase.id] = purchase;
                    return ret;
                }, {}),
                itemIds: action.payload.map(purchase => purchase.id)
            };
        case PURCHASE_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case PURCHASE_HISTORY_REMOVE_SUBSCRIPTION:
            const {subscriptionId, purchaseId} = action.payload;
            const purchase = {...state.itemMap[purchaseId]};
            const subscriptions = [...purchase.stripe.subscriptions];
            const index = subscriptions.indexOf(subscriptionId);
            subscriptions.splice(index, 1);
            purchase.stripe.subscriptions = subscriptions;

            return {
                ...state,
                itemMap: {
                    ...state.itemMap,
                    [purchaseId]: purchase
                },
                lastUpdate: new Date().getTime()
            };
        default:
            return state;
    }
};

export default reducer;
