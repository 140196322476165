import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FileSaver from 'file-saver';
import SearchIcon from '@mui/icons-material/Search';

import Loading from "../_common/loading";
import LocationItem from "./item";
import LocationDetails from "./detailsNew";
import LocationReport from './report';
import BulkUpload from "./bulkUpload";
import FullscreenDialog from "../_common/dialog/fullScreen";
import AdminOnly from "../_common/adminOnly";
import useReactRouter from "../../hooks/useReactRouter";
import NICADialog from "../_common/dialog";
import {loadAllLocations, loadUserLocations} from "../../redux/locations";
import {loadAttributes} from "../../redux/attributes/actions";
import {useLocationListSelector} from "../../redux/locations/selectors";
import {useLoginSelector} from "../../redux/login/selectors";
import {useAttributeListSelector} from "../../redux/attributes";
import attributeIds from "../../redux/attributes/attributeIds";
import useOrgLocationsFilter from "../../hooks/useOrgLocations";
import {defaultOrgIdSelector} from "../../redux/organizations/selectors";

import './index.css';

const PAGE_SIZE = 10;

const LocationManager = () => {
    const [numRecords, setNumRecords] = useState(PAGE_SIZE);
    const [showUpload, setShowUpload] = useState(false);
    const [search, setSearch] = useState('');
    const { claims: { admin, email } } = useLoginSelector();
    const orgId = useSelector(defaultOrgIdSelector);
    const dispatch = useDispatch();
    const { loading } = useLocationListSelector();
    const { history, match: { params: { locationId, locationAction } } } = useReactRouter();
    const { attributeList } = useAttributeListSelector();
    const locationList = useOrgLocationsFilter(orgId, admin);
    const numLocations = locationList?.length;

    useEffect(() => {
        dispatch(loadAttributes());
    }, [dispatch]);

    useEffect(() => {
        if (orgId) {
            console.log("loadUserLocations", orgId, admin);
            dispatch(loadUserLocations([orgId], email, admin));
        }
    }, [dispatch, admin, orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredIds = useMemo(() => {
        if (locationList) {
            return locationList.filter(location => {
                if (search && search.length) {
                    const lowerFilter = search.toLowerCase();
                    const locationString = JSON.stringify(location).toLowerCase();
                    return locationString.indexOf(lowerFilter) > -1;
                }
                return true;
            }).map(location => location.id);
        }
        return null;
    }, [search, loading, numLocations]); // eslint-disable-line react-hooks/exhaustive-deps

    const onShowMoreClick = () => setNumRecords(numRecords + PAGE_SIZE);
    const onClose = () => history.push('/locations');

    return (
        <div className="app-page">
            {loading ?
                <Loading size={45} />
                :
                <div className="p-10">
                    <div className="locations-fab-container">
                        <Link to={`/locations/add`}>
                            <Fab color="secondary" aria-label="Add" onClick={() => {}}>
                                <AddIcon htmlColor="#ffffff" />
                            </Fab>
                        </Link>
                    </div>
                    <div className="location-manager-search-container flex">
                        <AdminOnly noRedirect>
                            <div className="flex justify-end">
                                <div className="mr-7">
                                    <Button variant="contained" color="primary" onClick={() => dispatch(loadAllLocations())}>
                                        Load All Locations
                                    </Button>
                                </div>
                                <div className="mr-7">
                                    <Button variant="contained" color="primary" onClick={() => _exportLocations(attributeList, locationList)}>
                                        Export Locations
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" onClick={() => setShowUpload(true)}>
                                        Bulk Upload
                                    </Button>
                                </div>
                            </div>
                        </AdminOnly>
                    </div>
                    <div className="location-manager-search-container">
                        <TextField
                            name="search"
                            value={search}
                            onChange={event => setSearch(event.target.value)}
                            label="Search"
                            placeholder="East Side Infusions"
                            variant="standard"
                            InputProps={{
                                startAdornment: <SearchIcon color="primary" />
                            }}
                            fullWidth={true} />
                    </div>
                    <div>
                        {filteredIds && filteredIds.length ?
                            filteredIds.slice(0, numRecords).map((locationId, index) => (
                                <LocationItem locationId={locationId} key={`location-item-${locationId}`} />
                            ))
                            :
                            <div className="flex justify-center items-center">
                                No locations to show.
                            </div>
                        }
                    </div>
                    {filteredIds && filteredIds.length - 1 > numRecords &&
                        <div className="location-manager-load-more-container">
                            <Button variant="contained" color="primary" onClick={onShowMoreClick} fullWidth={true}>
                                Load More Locations
                            </Button>
                        </div>
                    }

                </div>
            }
            <NICADialog
                onClose={() => setShowUpload(false)}
                open={showUpload}
                title="Bulk Upload"
                actions={[{ label: "Done", onClick: () => setShowUpload(false)}]}
                maxWidth="lg">
                <BulkUpload onClose={() => setShowUpload(false)} />
            </NICADialog>
            <FullscreenDialog onClose={onClose} open={!!locationId && !locationAction}>
                <LocationDetails locationId={locationId} orgId={orgId} />
            </FullscreenDialog>
            <FullscreenDialog onClose={onClose} open={!!locationId && locationAction === "report"}>
                <LocationReport locationId={locationId} />
            </FullscreenDialog>
        </div>
    )
};

function _exportLocations(attributes, locations) {
    let headers = ["Name", "Street Address", "Street Address 2", "City", "State", "Postal Code", "Phone Number", "Fax Number", "Website URL", "Company Type", "Practice Specialty", "Accept Outside Referrals?", "Hospital Based?", "Claimed"];
    let lines = [headers.join("\t")];
    locations.forEach(location => {
        let parts = [];

        parts.push(location.name);

        let addressKeys = ["line1", "line2", "city", "stateCode", "zip"];
        addressKeys.forEach(key => {
            parts.push(location.address[key]);
        });

        let keys = ["phoneNumber", "faxNumber", "url"];
        keys.forEach(key => {
            parts.push(location[key]);
        });

        let practiceTypeAttribute = location.attributes[attributeIds.practiceType];
        if (practiceTypeAttribute && practiceTypeAttribute.choice && practiceTypeAttribute.choice.length) {
            parts.push(attributes[5].options[practiceTypeAttribute.choice[0]].name);
        } else {
            parts.push("");
        }

        let practiceSpecialtyAttribute = location.attributes[attributeIds.practiceSpecialty];
        if (practiceSpecialtyAttribute && practiceSpecialtyAttribute.choice && practiceSpecialtyAttribute.choice.length) {
            let specialties = [];
            location.attributes[attributeIds.practiceSpecialty].choice.forEach((choice) => {
                specialties.push(attributes[2].options[choice].name);
            });
            parts.push(specialties.join("; "));
        } else {
            parts.push("");
        }

        let outsidePatientsAttribute = location.attributes[attributeIds.acceptsOutsidePatients];
        if (outsidePatientsAttribute && outsidePatientsAttribute.choice && outsidePatientsAttribute.choice.length) {
            if (outsidePatientsAttribute.choice[0] === "149a4669") { // KBM - wtf, why is this here?
                parts.push("No");
            } else {
                parts.push("Yes");
            }
        } else {
            parts.push("");
        }

        let hospitalBasedAttribute = location.attributes[attributeIds.hospitalBasedAttribute];
        if (hospitalBasedAttribute && hospitalBasedAttribute.choice && hospitalBasedAttribute.choice.length) {
            if (hospitalBasedAttribute.choice[0] === attributeIds.hospitalBased) {
                parts.push("Yes");
            } else {
                parts.push("No");
            }
        } else {
            parts.push("");
        }

        parts.push(!!location.orgId);

        lines.push(parts.join("\t"));
    });
    const blob = new Blob([lines.join("\n")], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, "export.tsv");
}

export default LocationManager;
