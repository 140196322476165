import React from 'react';
import Button from "@mui/material/Button";

import downloadIcon from '../../../../img/ic_file_download_white_24px.svg';

const MemberResourceDownloadInfo = ({purchasable, activePurchase}) => {

    const onDownloadClick = () => window.open(purchasable.downloadUrl);
    const numReportsLeft = activePurchase.totalCredits;

    return (purchasable ?
        <div className="mt-6">
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className="admin-member-resource-download-button"
                    onClick={onDownloadClick}>
                    <img src={downloadIcon} alt="download"/>Download
                </Button>
            </div>
            <div className="admin-member-resource-info-expires font-lato flex mt-2">
                {`${numReportsLeft} Report(s) Remaining`}
            </div>
        </div>
        :
        null
    )
};

export default MemberResourceDownloadInfo;
