import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {loadPurchasables} from "../redux/purchasables";
import {useSnackbar} from "notistack";

import {
    purchasablesOrderSelector,
    purchasablesSelector
} from "../redux/purchasables/selectors";

export default function useSortedPurchasables() {
    const purchasables = useSelector(purchasablesSelector);
    const purchasablesOrder = useSelector(purchasablesOrderSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!purchasables) {
            dispatch(loadPurchasables(enqueueSnackbar));
        }
    }, [dispatch, enqueueSnackbar, purchasables]);

    return useMemo(() => {
        if (purchasablesOrder?.length && purchasables?.length) {
            console.log("order", purchasablesOrder, "purchasables", purchasables);
            return purchasablesOrder.map((item: any) => purchasables?.find(purchasable => purchasable.id === item.id));
        }
        return [];
    }, [purchasablesOrder, purchasables]);
}
