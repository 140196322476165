import {createSlice} from "@reduxjs/toolkit";

import {getLocal, saveLocal} from "../../utils/localStorage";
import {savePrimaryOrgId} from "../login";

const KEY_SELECTED_ORG_ID = "selectedOrgId";

const INITIAL_STATE = {
    orgsLoading: false,
    orgMap: {},
    selectedOrgId: getLocal(KEY_SELECTED_ORG_ID, false) || null,
};

const orgsSlice = createSlice({
    name: 'organizations',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.orgsLoading = action.payload;
        },
        orgsSuccess(state, action) {
            state.orgsLoading = false;
            state.orgMap = action.payload.reduce((ret, org) => {
                ret[org.id] = org;
                return ret;
            }, {});
        },
        orgSuccess(state, action) {
            state.orgMap[action.payload.id] = action.payload;
        },
        setSelectedOrgId(state, action) {
            state.selectedOrgId = action.payload;
            saveLocal(KEY_SELECTED_ORG_ID, action.payload, false);
        }
    }
});

export const { loading, orgsSuccess, orgSuccess, setSelectedOrgId } = orgsSlice.actions;

export default orgsSlice.reducer;

export const loadOrganizations = (orgIds, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.getOrganizations(orgIds)
            .then(orgs => {
                return dispatch(orgsSuccess(orgs));
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const saveOrganization = (organization, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.saveOrganization(organization)
            .then(org => {
                return dispatch(orgSuccess(org));
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const loadOrganizationContacts = (orgId, setLoading, setContacts, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.getOrganization(orgId)
            .then(org => {
                setLoading(false);
                setContacts(org.emails);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const setPrimaryOrg = (orgId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const userId = getState().login.claims.user_id;
        return api.setPrimaryOrgId(userId, orgId)
            .then(() => {
                dispatch(savePrimaryOrgId(orgId));
                enqueueSnackbar("Primary Organization Updated", { variant: 'success' });
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar(e.message, {variant: 'error'});
            });
    }
)
