import { createSelector } from "@reduxjs/toolkit";

export const purchasablesLoadingSelector = (state) => state.purchasables.loading;
export const purchasableSavingSelector = (state) => state.purchasables.purchasableSaving;
const purchasablesMapSelector = (state) => state.purchasables.purchasablesMap;
export const purchasablesOrderSelector = (state) => state.purchasables.purchasablesOrder;

export const purchasablesSelector = createSelector(purchasablesMapSelector, (map) => {
    return Object.values(map);
});

export const purchasableSelector = (id) => (state) => {
    if (id === "add") return defaultPurchasable;

    return state.purchasables.purchasablesMap[id];
};

export const orderedPurchasablesSelector = createSelector(purchasablesMapSelector, purchasablesOrderSelector, (map, order) => {
    // return the purchasables in the order specified by purchasablesOrder
    // return order.map((id) => map[id]);
    return Object.values(map);
});

const defaultPurchasable = {
    title: "",
    description: "",
    credits: 0,
    membershipRequired: false,
    type: "SPONSORED_SEARCH_RESULTS",
};
