import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "notistack";
import {Formik, Form} from "formik";
import {object, string} from "yup";

import CustomFormikField from "../_common/formik/customField";
import {impersonateUser} from "../../redux/login/";

import './index.css';

const AdminUserImpersonate = ({onClose}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={object().shape({
                email: string().email("Please enter a valid email").required()
            })}
            onSubmit={({ email }) => {
                setLoading(true);
                dispatch(impersonateUser(email, setLoading, onClose, enqueueSnackbar));
            }}>
            <Form>
                <div className="flex">
                    <div className="flex-1">
                        <CustomFormikField
                            CustomComponent={TextField}
                            name="email"
                            label="User's Email"
                            placeholder="john.smith@gmail.com"
                            type="email"
                            fullWidth />
                    </div>
                    <div className="flex items-end ml-4">
                        {loading ?
                            <CircularProgress size={35} className="ml-2" />
                            :
                            <Button variant="text" color="primary" type="submit">
                                Submit
                            </Button>
                        }
                    </div>
                </div>
            </Form>
        </Formik>
    )
};

export default AdminUserImpersonate;
