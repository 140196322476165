export function parsePoint(point) {
    if (point) {
        let match = null;
        if (point.value) {
            match = point.value.match(/POINT\(([^ ]+) ([^)]+)\)/);
        } else {
            match = point.match(/POINT\(([^ ]+) ([^)]+)\)/);
        }
        return {
            lat: match[2],
            lng: match[1]
        };
    }

    return {
        lat: 0,
        lng: 0
    };
}