import React from 'react';
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MemberResourceInfo from "./info";
import {formatMoneyFromPennies} from "../../../utils/money";
import {purchasableSelector} from "../../../redux/purchasables/selectors";
import {memberPricingTypeSelector} from "../../../redux/memberships/selectors";

import './index.css';

const MemberResourceItem = ({purchasableId, index, onPriceOptionClick}) => {
    const purchasable = useSelector(purchasableSelector(purchasableId));
    const pricingType = useSelector(memberPricingTypeSelector);

    const isMember = pricingType === "supporting" || pricingType === "elite";
    const showSaveMore = pricingType === "supporting" && purchasable.pricing[pricingType] > purchasable.pricing["elite"];
    const showSavings = isMember && purchasable.pricing[pricingType] < purchasable.pricing.nonMember;
    const savings = showSavings ? purchasable.pricing.nonMember - purchasable.pricing[pricingType] : 0;
    const price = purchasable.pricing[pricingType] !== undefined ? purchasable.pricing[pricingType] : purchasable.pricing["nonMember"];

    return (purchasable ?
            <Accordion key={`purchasable-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="font-oswald text-xl text-blue">
                        {purchasable.title}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="flex w-full">
                        <div className="flex-1">
                            <div>
                                {purchasable.description}
                            </div>
                            <div>
                                <MemberResourceInfo purchasable={purchasable} />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-right text-2xl font-oswald text-blue">
                                {purchasable.membershipRequired && pricingType === "nonMember" ?
                                    "Members Only" :
                                    formatMoneyFromPennies(price)
                                }
                            </div>
                            {showSaveMore &&
                                <div className="flex justify-end">
                                    <Tooltip title={
                                        <div className="text-xl font-lato">
                                            Upgrade your SUPPORTING Membership to ELITE and save more!
                                        </div>
                                    }>
                                        <div className="rounded-full bg-green uppercase font-medium text-white py-1 px-2 mt-1 text-white font-medium text-sm font-oswald cursor-pointer">
                                            Save {formatMoneyFromPennies(purchasable.pricing[pricingType] - purchasable.pricing["elite"])}
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                            {showSavings &&
                                <div className="flex justify-end">
                                    <Tooltip title={
                                        <div className="text-xl font-lato">
                                            You saved with your {pricingType.toUpperCase()} Membership!
                                        </div>
                                    }>
                                        <div className="text-green italic font-medium mt-1 text-sm font-oswald cursor-pointer">
                                            You saved {formatMoneyFromPennies(savings)}!
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                            <div className="mt-6">
                                <Button variant="contained" color="primary" onClick={onPriceOptionClick} disabled={purchasable.membershipRequired && pricingType === "nonMember"}>
                                    Add to Cart
                                </Button>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        :
        null
    )
};

export default MemberResourceItem;
