import React from 'react';

import LocatorResult from "../locatorResult";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";

const ImportSummary = ({results}) => {
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                            # Locations
                        </TableCell>
                        <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                            Unrecognized Specialties
                        </TableCell>
                        <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                            Unrecognized Practice Types
                        </TableCell>
                        <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                            Failed Geocodes
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {results.locations ? results.locations.length : 0}
                        </TableCell>
                        <TableCell>
                            {results.specialtiesNotFound && results.specialtiesNotFound.length ? results.specialtiesNotFound.join(", ") : "None."}
                        </TableCell>
                        <TableCell>
                            {results.typesNotFound && results.typesNotFound.length ? results.typesNotFound.join(", ") : "None."}
                        </TableCell>
                        <TableCell>
                            {results.failedGeocodes && results.failedGeocodes.length ? results.failedGeocodes.map(location => location.name).join(", ") : "None."}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className="bulk-upload-section-title mt-5">
                Preview
            </div>
            <div>
                {results.locations && results.locations.length ?
                    <div>
                        <LocatorResult
                            result={results.locations[0]} />
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default ImportSummary;
