import React from 'react';

import Attributes from "../../details/attributes";

import './index.css';

const PracticeDetails = ({ setFieldValue }) => {
    return (
        <div className="pb-3 w-full">
            <Attributes setFieldValue={setFieldValue} />
        </div>
    )
};

export default PracticeDetails;
