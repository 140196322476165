import { createSelector } from "@reduxjs/toolkit";
import { useShallowEqualSelector } from "..";

export const paymentMethodsMapSelector = (state) => state.paymentMethods.paymentMethodsMap;
export const paymentMethodsLoadingSelector = (state) => state.paymentMethods.loading;
export const paymentMethodsSelector = () =>
    createSelector(paymentMethodsMapSelector, (paymentMethodsMap) => {
        return paymentMethodsMap ? Object.values(paymentMethodsMap) : null;
    });

export const usePaymentMethodsSelector = (id) => {
    return useShallowEqualSelector(({ paymentMethods: { paymentMethodsMap } }) => {
        return paymentMethodsMap[id];
    });
};
