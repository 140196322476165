import { useMemo } from 'react';
import { useSelector } from "react-redux";

import { locationListSelector } from "../redux/locations/selectors";

/**
 * Filters the locations by OrgId. Will also include non-active locations if the current user is an Admin
 * @param orgId The organization id to filter by
 * @returns {Location[]} filtered list of locations.
 */
function useOrgLocationsFilter(orgId, admin) {
    const locationList = useSelector(locationListSelector);

    return useMemo(() => {
        return locationList.filter(location => admin || location.orgId === orgId)
            .sort((l1, l2) => {
                if (l1.active && !l2.active) {
                    return 1;
                } else if (!l1.active && l2.active) {
                    return -1;
                }
                return l1.name.localeCompare(l2.name)
            });
    }, [locationList, orgId, admin]);
}

export default useOrgLocationsFilter;
