import {min} from 'date-fns';

import {
    ACTIVE_PURCHASES_LOADING,
    ACTIVE_PURCHASES_SUCCESS,
    ACTIVE_PURCHASES_ERROR
} from "./types";

const INITIAL_STATE = {
    loading: true,
    resources: [],
    purchasablesMap: {},
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTIVE_PURCHASES_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ACTIVE_PURCHASES_SUCCESS:
            return {
                ...state,
                loading: false,
                resources: action.payload,
                purchasablesMap: action.payload.reduce((ret, activePurchasable) => {
                    const currentItem = ret[activePurchasable.purchasableId] || { totalCredits: 0, locations: {}, purchaseDate: new Date() } ;
                    currentItem.totalCredits += activePurchasable.creditsRemaining;
                    currentItem.purchaseDate = min([currentItem.purchaseDate, activePurchasable.updatedAt]);

                    if (activePurchasable.location) {
                        const location = activePurchasable.location;
                        if (currentItem.locations[location.id]) {
                            currentItem.locations[location.id].total += activePurchasable.creditsRemaining;
                            currentItem.locations[location.id].purchaseDate = min([currentItem.locations[location.id].purchaseDate, activePurchasable.updatedAt])
                        } else {
                            currentItem.locations[location.id] = { total: activePurchasable.creditsRemaining, location, purchaseDate: activePurchasable.updatedAt };
                        }
                    }

                    // if (activePurchasable.locations) {
                    //     activePurchasable.locations.forEach(location => {
                    //         if (currentItem.locations[location.id]) {
                    //             currentItem.locations[location.id].total += activePurchasable.creditsRemaining;
                    //             currentItem.locations[location.id].purchaseDate = min([currentItem.locations[location.id].purchaseDate, activePurchasable.updatedAt])
                    //         } else {
                    //             currentItem.locations[location.id] = { total: activePurchasable.creditsRemaining, location, purchaseDate: activePurchasable.updatedAt };
                    //         }
                    //     })
                    // }

                    ret[activePurchasable.purchasableId] = currentItem;

                    return ret;
                }, {})
            };
        case ACTIVE_PURCHASES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;