import { useMemo } from "react";
import {useSelector} from "react-redux";
import {salesTaxRatesSelector} from "../redux/shoppingCart/selectors";
// @ts-ignore
import {PaymentMethodType} from "@devsontap/nica-api/core/models/enums";

export interface CartItem {
    autoRenew: boolean;
    locations: any[];
    purchasableId: string;
    purchasableTitle: string;
    option?: {
        price: number;
    };
    optionTitle?: string;
    price: number;
    credits: number;
    total: number;
    applySalesTaxInStates?: string[];
}

export interface CartItemWithFees extends CartItem {
    discount: number;
    salesTax: number;
    serviceFee: number;
}

export type DiscountCode = {
    restrictByPurchasables?: string[];
    type: "FIXED" | "PERCENT" // Pull this from API
    discountAmount: number;
}

export default function useCartWithFees(cart: CartItem[], paymentType: any, stateCode: string = "", discountCode?: DiscountCode) {
    const salesTaxRates = useSelector(salesTaxRatesSelector);

    return useMemo(() => {
        return cart.map(cartItem => {
            let discount = 0;
            let serviceFee = 0;
            let salesTax = 0;

            if (discountCode) {
                discount = _discount(discountCode, cartItem);
            }

            // Sales tax gets applied after discount
            if (cartItem.applySalesTaxInStates?.includes(stateCode)) {
                salesTax = Math.round((cartItem.total - discount) * salesTaxRates[stateCode]);
            }

            // Service fee gets applied after discount and sales tax
            if (paymentType === PaymentMethodType.CREDIT.value) {
                serviceFee = Math.round((cartItem.total - discount + salesTax) * 0.03);
            }

            return {
                ...cartItem,
                discount,
                salesTax,
                serviceFee,
            } as CartItemWithFees;
        });
    }, [cart, paymentType, stateCode, discountCode, salesTaxRates]);
}

function _discount(discountCode: DiscountCode, cartItem: CartItem) {
    let discountInPennies = 0;

    if (discountCode) {
        if (discountCode.restrictByPurchasables && discountCode.restrictByPurchasables.includes(cartItem.purchasableId)) {
            discountInPennies += discountCode.type === "FIXED" ?
                discountCode.discountAmount :
                (discountCode.discountAmount / 100.0) * cartItem.total;
        } else {
            discountInPennies += discountCode.type === "FIXED" ?
                discountCode.discountAmount :
                (discountCode.discountAmount / 100.0) * cartItem.total;
        }
    }

    return Math.round(discountInPennies);
}
