import React from 'react';

import memberStar from "../../../../img/ic-member.svg";
import distanceIcon from "../../../../img/ic-distance-large.svg";
import unverifiedIcon from '../../../../img/ic-unverified-location-small.svg';

import './index.css';

const mileRatio = 0.621371;

const LocatorResult = ({result}) => {

    const formatMiles = () => {
        const miles = mileRatio * result.distance;
        return miles.toFixed() + " miles";
    };

    return (
        <div className="locator-result-container">
            <div className="locator-result-main">
                {result.isPreferred ?
                    <div className="locator-result-preferred-member">
                        <img className="locator-result-preferred-member-image" src={memberStar} alt="preferred member star"/> Sponsored
                    </div>
                    :
                    null
                }
                <div className="locator-result-name">
                    {result.name}
                </div>
                <div className="locator-result-distance">
                    <img className="distanceIcon" src={distanceIcon} alt="distance icon" />{formatMiles()}
                </div>
                <div className="locator-result-address">
                    <div>{result.address.line1}</div>
                    <div>{result.address.city}, {result.address.stateCode}</div>
                </div>
                {!result.orgId ?
                    <div>
                        <div className="locator-result-unverified">
                            <img src={unverifiedIcon} style={{marginRight: "5px"}} alt="unclaimed icon" /> Unclaimed
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {result.temporarily_closed ?
                <div className="locator-result-temporarily-closed">
                    Temporarily Closed
                </div>
                :
                null
            }
        </div>
    )
};

export default LocatorResult;