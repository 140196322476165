import React from 'react';

import CustomFormikField from "../../../_common/formik/customField";
import TextField from "@mui/material/TextField";
import BusinessHours from "../../details/businessHours";
import Checkbox from "@mui/material/Checkbox";

import './index.css';

const AdditionalInfo = ({ setFieldValue, handleChange, temporarilyClosed }) => {
    return (
        <div className="pb-3 w-full">
            { /********** BUSINESS HOURS ***************/ }
            <div className="mb-12">
                <div className="font-lato text-lightBlue">
                    Business Hours
                </div>
                <CustomFormikField
                    name="businessHours"
                    CustomComponent={BusinessHours}
                    setFieldValue={setFieldValue}
                    fast/>
            </div>
            { /********** LANGUAGES SPOKEN *************/ }
            <div className="mb-12">
                <div className="font-lato text-lightBlue">
                    Languages Spoken
                </div>
                <div>
                    <CustomFormikField
                        CustomComponent={TextField}
                        variant="standard"
                        name="languagesSpoken"
                        placeholder="Spanish"
                        label="Languages Spoken"
                        fullWidth={true}
                        inputProps={{maxLength: 500}} />
                </div>
            </div>
            { /********** # CHAIRS *************/ }
            <div className="mb-12">
                <div className="font-lato text-lightBlue">
                    How Many Infusion Chairs Do You Have At This Location?
                </div>
                <div>
                    <CustomFormikField
                        CustomComponent={TextField}
                        variant="standard"
                        name="numberOfChairs"
                        label="# Chairs"
                        placeholder="3"
                        fullWidth={true}
                        type="number"
                        inputProps={{maxLength: 4}} />
                </div>
            </div>
            { /********** SOCIAL MEDIA URLS *************/ }
            <div className="mb-12">
                <div className="font-lato text-lightBlue">
                    Social Media URLs
                </div>
                <div>
                    <CustomFormikField
                        CustomComponent={TextField}
                        variant="standard"
                        name="facebookPageId"
                        label="Facebook ID"
                        placeholder="283746362"
                        fullWidth={true}
                        helperText={<a href="https://www.facebook.com/help/1503421039731588" target="_blank" rel="noopener noreferrer">How do I find my Facebook Page ID?</a>}
                        inputProps={{maxLength: 100}} />
                </div>
                <div className="mt-5">
                    <CustomFormikField
                        CustomComponent={TextField}
                        variant="standard"
                        name="twitterHandle"
                        label="Twitter Handle"
                        placeholder="@your_company"
                        fullWidth={true}
                        helperText="@example"
                        inputProps={{maxLength: 100}} />
                </div>
            </div>
            { /********** TEMPORARILY CLOSED *************/ }
            <div>
                <div className="font-lato text-lightBlue">
                    Temporarily Closed
                </div>
                <div className="flex">
                    <div className="flex items-center">
                        <Checkbox
                            name="temporarily_closed"
                            onChange={handleChange}
                            color="primary"
                            checked={Boolean(temporarilyClosed)} />
                    </div>
                    <div className="location-details-label flex items-center">
                        This location is temporarily closed
                    </div>
                </div>
                <div className="mt-5">
                    <CustomFormikField
                        CustomComponent={TextField}
                        variant="standard"
                        name="temporarily_closed_reason"
                        label="Temporarily Closed Reason"
                        placeholder="Flooded"
                        fullWidth={true}
                        helperText={"Please tell us why this location is temporarily closed and when it might re-open."}
                        inputProps={{maxLength: 500}} />
                </div>
            </div>
        </div>
    )
};

export default AdditionalInfo;
