import React, {useState} from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import LocationIcon from "@mui/icons-material/LocationOn";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentsIcon from "@mui/icons-material/Payment";
import HistoryIcon from "@mui/icons-material/History";
import EmailIcon from "@mui/icons-material/Email";
import OrgIcon from "@mui/icons-material/Business";
import MembershipsIcon from "@mui/icons-material/CardMembership";
import DiscountIcon from "@mui/icons-material/LocalOffer";

import AdminOnly from "../_common/adminOnly";
import { logout } from "../../redux/login/";
import {useLoginSelector} from "../../redux/login/selectors";
import {defaultOrgIdSelector} from "../../redux/organizations/selectors";
import OrgSelect from "../_common/orgSelect";
import {setSelectedOrgId} from "../../redux/organizations";

import logo from "../../img/nica-logo.svg";

import "./index.css";
import {isCartEmptySelector} from "../../redux/shoppingCart/selectors";

const items = [
    { label: "Location Manager", path: "/locations", Icon: LocationIcon },
    { label: "Member Resources", path: "/member-resources", Icon: ShoppingCartIcon },
    { label: "Memberships", path: "/memberships", Icon: MembershipsIcon },
    { label: "Purchase History", path: "/purchase-history", Icon: HistoryIcon },
    { label: "Payment Methods", path: "/payment-methods", Icon: PaymentsIcon },
    { label: "Organizations", path: "/organizations", Icon: OrgIcon },
    { label: "Admin Resources", path: "/admin-resources", Icon: FileIcon, adminOnly: true },
    { label: "Admin Bulk Message", path: "/admin-bulk-message", Icon: EmailIcon, adminOnly: true },
    { label: "Admin Discount Codes", path: "/admin-discount-codes", Icon: DiscountIcon, adminOnly: true },
];

const Nav = () => {
    const [orgId, setOrgId] = useState(useSelector(defaultOrgIdSelector));
    const dispatch = useDispatch();
    const {orgIds} = useLoginSelector();
    const isCartEmpty = useSelector(isCartEmptySelector);

    const onOrgChange = (event) => {
        dispatch(setSelectedOrgId(event.target.value));
        setOrgId(event.target.value);
    };

    return (
        <div className="nav-container">
            <div className="nav-header">
                <div className="flex justify-end">
                    <Button variant="text" onClick={() => dispatch(logout())} color="secondary">
                        Log Out
                    </Button>
                </div>
                <div>
                    <img src={logo} className="nav-header-logo" alt="nica logo" />
                </div>
                <div className="nav-header-title oswald">Admin Panel</div>
            </div>
            {orgIds && orgIds.length > 1 &&
                <div className="px-4 pt-4 pb-1">
                    <OrgSelect value={orgId} onChange={onOrgChange} disabled={!isCartEmpty} fullWidth />
                </div>
            }
            <div className="admin-nav-card-items">
                <List component="nav">
                    {items.map(({ path, label, Icon, adminOnly }, index) => {
                        if (adminOnly) {
                            return (
                                <AdminOnly key={`nav-item-${label}`} noRedirect={true}>
                                    <NavLink
                                        to={path}
                                        className="nav-item-text"
                                        activeClassName="nav-item-text-selected"
                                    >
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Switch>
                                                    <Route path={path} render={() => <Icon htmlColor="#1c5c7f" />} />
                                                    <Route render={() => <Icon htmlColor="#A0A0A0" />} />
                                                </Switch>
                                            </ListItemIcon>
                                            <div className="nav-item-label flex items-center">{label}</div>
                                        </ListItem>
                                    </NavLink>
                                </AdminOnly>
                            );
                        }
                        return (
                            <NavLink
                                to={path}
                                className="nav-item-text"
                                activeClassName="nav-item-text-selected"
                                key={`nav-item-${label}`}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <Switch>
                                            <Route path={path} render={() => <Icon htmlColor="#1c5c7f" />} />
                                            <Route render={() => <Icon htmlColor="#A0A0A0" />} />
                                        </Switch>
                                    </ListItemIcon>
                                    <div className="nav-item-label flex items-center">{label}</div>
                                </ListItem>
                            </NavLink>
                        );
                    })}
                </List>
            </div>
        </div>
    );
};

export default Nav;
