import React, {useState, useMemo} from 'react';
import {useDispatch} from "react-redux";
import {Card, CircularProgress, Divider, Button, IconButton} from "@mui/material";
import {Warning as WarningIcon, ContentCopy} from '@mui/icons-material';

import numeral from 'numeral';
import {format, addMonths} from 'date-fns';

import NICADialog from "../../_common/dialog";
import {cancelSubscription} from "../../../redux/purchaseHistory/actions";

import './index.css';
import {formatMoneyFromPennies} from "../../../utils/money";
import {enqueueSnackbar} from "notistack";

const PurchaseHistoryItem = ({ item }) => {
    const [showConfirmForIndex, setShowConfirmForIndex] = useState(-1);
    const [loadingIndex, setLoadingIndex] = useState(-1);
    const dispatch = useDispatch();
    const subs = item.stripe && item.stripe.subscriptions ? item.stripe.subscriptions.length : 0;

    const cartSubscriptions = useMemo(() => {
        if (item && item.cart && item.stripe) {
            const result = [];
            const subCnt = 0;
            item.cart.forEach(cartItem => {
                if (cartItem.autoRenew && item.stripe.subscriptions.length > subCnt) {
                    result.push(subCnt);
                } else {
                    result.push(-1);
                }
            });
            return result;
        }
        return [];
    }, [subs]);  // eslint-disable-line react-hooks/exhaustive-deps

    const cartItems = item.cart;

    const price = numeral(item.total / 100.0).format('$0,0.00');

    const onCopyPasswordClick = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_ACCREDITATION_STANDARDS_DIGITAL_PW)
            .then(() => {
                enqueueSnackbar("Password copied", {variant: 'success'});
            })
    }

    return (
        <div className="admin-subscription-container flex-1">
            <Card className="admin-subscription-card p-7 mb-5">
                <div className="flex">
                    <div className="flex-1 font-oswald text-xl uppercase">
                        {`${format(item.createdAt, 'MM/dd/yyyy')}`}
                    </div>
                    <div className="font-lato text-green2 text-lg mr-4">
                        {price}
                    </div>
                </div>
                {cartItems.map((cartItem, index) => {
                    return (
                        <div key={"cart-item-" + index}>
                            <div className="cart-item-container">
                                <div className="flex">
                                    <div className="flex-1">
                                        <div className="cart-item-title oswald">
                                            {cartItem.purchasableTitle}
                                        </div>
                                        {cartItem.option &&
                                            <div className="cart-item-subtitle lato">
                                                {cartItem.option.title}
                                            </div>
                                        }
                                        {item.isAccreditationStandardsDigitalPurchase() &&
                                            <div className="mt-2">
                                                <div>
                                                    <Button variant="text" color="secondary" href={process.env.REACT_APP_ACCREDITATION_STANDARDS_DIGITAL_URL} target="_blank">
                                                        Access the NICA Standards
                                                    </Button>
                                                </div>
                                                <div className="text-sm ml-2 flex items-center">
                                                    <div>
                                                        When prompted, enter your password: <strong>{process.env.REACT_APP_ACCREDITATION_STANDARDS_DIGITAL_PW}</strong>
                                                    </div>
                                                    <div className="ml-1">
                                                        <IconButton onClick={onCopyPasswordClick}>
                                                            <ContentCopy fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="cart-item-total lato">
                                        {formatMoneyFromPennies(cartItem.total)}
                                    </div>
                                </div>
                                {cartItem.locations ?
                                    <div className="cart-item-locations oswald">
                                        {cartItem.locations.map(location => location.name).join(" • ")}
                                    </div>
                                    :
                                    null
                                }
                                {cartSubscriptions[index] > -1 ?
                                    <div className="mt-4 oswald cart-item-subtitle">
                                        {loadingIndex === index ?
                                            <CircularProgress size={35}/>
                                            :
                                            <div className="flex">
                                                <div className="flex justify-center items-center mr-4">
                                                    {_getRenewalDate(cartItem, item.createdAt)}
                                                </div>
                                                <div>
                                                    <Button variant="text" onClick={() => setShowConfirmForIndex(index)} color="secondary">Cancel Subscription</Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {index < cartItems.length - 1 && <Divider />}
                        </div>
                    )
                })}
                <div className="flex justify-between text-sm text-purpleGray font-lato mt-4">
                    <div>Purchased by &lt;{item.user}&gt;</div>
                    {(item.paymentType === "CHECK" && !item.paymentReceived) &&
                        <div className="pending-payment">
                            <WarningIcon className="mr-2" />
                            <span>Pending Check Payment</span>
                        </div>
                    }
                </div>
            </Card>
            <NICADialog
                open={showConfirmForIndex !== -1}
                onClose={() => setShowConfirmForIndex(-1)}
                title="Confirm Cancellation"
                actions={[
                    {label: "Cancel", onClick: () => setShowConfirmForIndex(-1)},
                    {label: "Confirm", onClick: () => {
                        const subscriptionId = item.stripe.subscriptions[cartSubscriptions[showConfirmForIndex]];
                        dispatch(cancelSubscription(subscriptionId, item.id, setLoadingIndex));
                        setLoadingIndex(showConfirmForIndex);
                        setShowConfirmForIndex(-1);
                    }},
                ]}>
                Are you sure you want to cancel this Subscription?
            </NICADialog>
        </div>
    )
};

function _getRenewalDate(cartItem, purchaseDate) {
    let credits = cartItem.option?.credits || cartItem.credits;
    let renewalDate = addMonths(purchaseDate, credits);
    return `Renews ${format(renewalDate, 'MM/dd/yyyy')}`;
}

export default PurchaseHistoryItem;
