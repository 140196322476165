import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { loadMembershipTypes } from "../../../../redux/memberships";
import { membershipTypesSelector } from "../../../../redux/memberships/selectors";

const MembershipTypeSelect = ({value, onChange, fullWidth, disabled}) => {
    const membershipTypes = useSelector(membershipTypesSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!membershipTypes?.length) {
            dispatch(loadMembershipTypes(enqueueSnackbar))
        }
    }, [membershipTypes, dispatch, enqueueSnackbar]);

    return (
        <FormControl fullWidth={fullWidth} disabled={disabled} variant="standard">
            <InputLabel id="membershipTypeLabel">Restrict By Membership Types</InputLabel>
            <Select
                name="addMembershipType"
                value={value}
                onChange={onChange}
                labelId="membershipTypeLabel"
                variant="standard"
                fullWidth={fullWidth}>
                {membershipTypes && membershipTypes.map((type)=> {
                    return (
                        <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
};

MembershipTypeSelect.propTypes = {
    value: PropTypes.string.isRequired,
};

MembershipTypeSelect.defaultProps = {
    fullWidth: false,
    disabled: false
};

export default MembershipTypeSelect;
