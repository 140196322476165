import { useShallowEqualSelector } from "../index";
import { createSelector } from '@reduxjs/toolkit';
import _ from "lodash";
import attributeIds from "@devsontap/nica-api/core/utils/attributeIds";

const locationMapSelector = state => state.locations.locationMap;

/**
 * Returns the data necessary to render the locationManager list
 */
export const useLocationListSelector = () => {
    return useShallowEqualSelector(({ locations }) => {
        const locationList = locations.locationIds ? locations.locationIds.map(id => locations.locationMap[id]) : null;
        return {
            ...(_.pick(locations, ["loading", "locationIds", "error", "filteredIds"])),
            numLocations: locationList ? locationList.length : 0,
            locationList
        };
    });
};

/**
 * Return a single Location record
 * @param locationId    id of the location to return
 */
export const useLocationSelector = (locationId) => {
    return useShallowEqualSelector(({ locations }) => {
        return locations.locationMap[locationId] ? locations.locationMap[locationId] : null;
    });
};

/**
 * Return the data necessary to render the location Details
 * @param locationId    id of the location to return
 */
export const useLocationDetailsSelector = (locationId) => {
    return useShallowEqualSelector(({ locations: { locationMap, locationLoading, locationError } }) => {
        let location = locationMap[locationId];

        // Hack to make sure we get a 'blank' customer to start with when we're adding a customer
        if (locationId && locationId.toLowerCase() === "add") {
            location = {}
        }

        return {
            location,
            locationLoading,
            locationError
        }
    });
};

export const useLocationsBySpecialtiesSelector = (specialtyIds = []) => {
    return useShallowEqualSelector(({ locations: { locationIds, locationMap } }) => {
        return locationIds && locationIds
            .map(locationId => {
                const location = locationMap[locationId];
                const specialties = location.attributes && location.attributes[attributeIds.practiceSpecialty];

                const medications = attributeIds.medicationGroups.map(groupId => location.attributes && location.attributes[groupId] ? location.attributes[groupId].choice : [])
                    .reduce((ret, item) => {
                        return ret.concat(item);
                    }, []);

                return {
                    lat: location.latitude,
                    lng: location.longitude,
                    claimed: !!(location.orgId),
                    email: location.emailAddress,
                    specialties,
                    medications,
                    ...location
                }
            })
            .filter(location => {
                let result = true;
                specialtyIds.forEach(specialtyId => {
                    if (location.specialties && location.specialties.choice && location.specialties.choice.indexOf(specialtyId) === -1) {
                        result = false;
                    }
                });
                return result;
            })
    });
};

export const useLocationsByOrgSelector = (orgIds) => (
    useShallowEqualSelector(({ locations: { locationIds, locationMap } }) => {
        return locationIds && locationIds
            .map(locationId => locationMap[locationId])
            .filter(location => orgIds.includes(location.orgId));
    })
);

export const useLocationReportSelector = (locationId) => (
    useShallowEqualSelector(({ locations: { reportMap, locationReportLoading } }) => {
        return {
            report: (reportMap && reportMap[locationId]) || null,
            locationReportLoading
        };
    })
);

export const locationListSelector = createSelector(
    (locationMapSelector),
    (locationMap) => Object.values(locationMap)
);
