import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton, Card, CardContent, CircularProgress } from "@mui/material";
import { usePaymentMethodsSelector } from "../../../redux/paymentMethods/selectors";
import { Delete, Payment, AccountBalance } from "@mui/icons-material";

import { deletePaymentMethod } from "../../../redux/paymentMethods";
import NICADialog from "../../_common/dialog";

interface Props {
    paymentMethodId: string;
}

const PaymentMethodItem: React.FC<Props> = ({ paymentMethodId }) => {
    // TODO: Add a type for PaymentMethod -- or is that in Stripe's SDK?
    const paymentMethod: any = usePaymentMethodsSelector(paymentMethodId);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    if (Boolean(paymentMethod)) {
        return (
            <div className="mb-4">
                <Card>
                    <CardContent>
                        <div className="flex items-center p-2">
                            <div className="mr-4">
                                {paymentMethod.type === "card" ? <Payment /> : <AccountBalance />}
                            </div>
                            <div className="flex items-center flex-1">
                                {paymentMethod.type === "card" && (
                                    <div className="space-y-2">
                                        <div className="text-lg font-semibold">
                                            xxxx-xxxx-xxxx-{paymentMethod.card.last4}
                                        </div>
                                        <div className="text-sm italic">
                                            Exp. {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
                                        </div>
                                    </div>
                                )}
                                {paymentMethod.type === "us_bank_account" && (
                                    <div className="text-lg font-semibold">
                                        {paymentMethod.us_bank_account.bank_name} -{" "}
                                        {paymentMethod.us_bank_account.last4}
                                        <div className="text-lg font-semibold">
                                            xxxx-xxxx-xxxx-{paymentMethod.us_bank_account.last4}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="ml-4">
                                {isDeleting ? (
                                    <div className="p-2">
                                        <CircularProgress size={30} />
                                    </div>
                                ) : (
                                    <IconButton onClick={() => setConfirmDeleteOpen(true)}>
                                        <Delete />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <NICADialog
                    open={confirmDeleteOpen}
                    onClose={() => setConfirmDeleteOpen(false)}
                    title="Delete Payment Method"
                    actions={[
                        {
                            label: "Cancel",
                            onClick: () => setConfirmDeleteOpen(false),
                        },
                        {
                            label: "Delete",
                            onClick: () => {
                                setConfirmDeleteOpen(false);
                                setIsDeleting(true);
                                dispatch(
                                    deletePaymentMethod(paymentMethodId, enqueueSnackbar, () => setIsDeleting(false))
                                );
                            },
                        },
                    ]}
                >
                    Are you sure you want to delete this payment method?
                </NICADialog>
            </div>
        );
    }

    return null;
};

export default PaymentMethodItem;
