import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Card, CardContent, CardActions, CardHeader, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import NICADialog from "../../_common/dialog";

import { deleteDiscountCode } from "../../../redux/discountCodes";
import { formatMoneyFromPennies } from "../../../utils/money";
import Chip from "@mui/material/Chip";

const DiscountCodeItem = ({ code, setEditId }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Card className="mb-8">
                <CardHeader title={code.slug} />
                <CardContent>
                    <div className="mb-1">
                        <strong>{code.type === "FIXED" ?
                            formatMoneyFromPennies(code.discountAmount) :
                            `${code.discountAmount}%`
                        }</strong> Discount
                    </div>
                    <div>
                        <strong>{code.credits}</strong> Credits Remaining
                    </div>
                    {code.restrictByEmails?.length ?
                        <div className="mt-4">
                            <div>
                                <strong>Restricted To Users:</strong>
                            </div>
                            <div className="mt-1">
                                {code.restrictByEmails.map((email, index) => (
                                    <Chip
                                        key={email}
                                        color="default"
                                        label={email}
                                        className="mr-2 mb-1"  />
                                ))}
                            </div>
                        </div> :
                        null
                    }
                    {code.restrictByPurchasables?.length ?
                        <div className="mt-4">
                            <div>
                                <strong>Restricted To Purchasables:</strong>
                            </div>
                            <div className="mt-1">
                                {code.restrictByPurchasables.map((purchasable, index) => (
                                    <Chip
                                        key={purchasable}
                                        color="default"
                                        label={purchasable}
                                        className="mr-2 mb-1"  />
                                ))}
                            </div>
                        </div> :
                        null
                    }
                    {code.restrictByMembershipTypes?.length ?
                        <div className="mt-4">
                            <div>
                                <strong>Restricted To Membership Types:</strong>
                            </div>
                            <div className="mt-1">
                                {code.restrictByMembershipTypes.map((type, index) => (
                                    <Chip
                                        key={type}
                                        color="default"
                                        label={type}
                                        className="mr-2 mb-1"  />
                                ))}
                            </div>
                        </div> :
                        null
                    }
                </CardContent>
                <CardActions>
                    <div className="flex justify-end w-full">
                        <div className="mr-3">
                            <Button variant="text" onClick={() => setConfirmOpen(true)}>
                                <TrashIcon className="mr-1" /> Delete
                            </Button>
                        </div>
                        <div>
                            <Button variant="text" onClick={() => setEditId(code.id)}>
                                <EditIcon className="mr-1" /> Edit
                            </Button>
                        </div>
                    </div>
                </CardActions>
            </Card>
            <NICADialog
                onClose={() => setConfirmOpen(false)}
                open={confirmOpen}
                title={`Delete Code`}
                actions={[
                    {label: 'Cancel', onClick: () => setConfirmOpen(false)},
                    {label: 'Confirm', onClick: () => {
                        setConfirmOpen(false);
                        dispatch(deleteDiscountCode(code.id, enqueueSnackbar));
                    }}
                ]}>
                Are you sure you want to delete <strong>{code.slug}</strong>?
            </NICADialog>
        </>
    );
};

export default DiscountCodeItem;
