/*global google*/
/*eslint no-undef: "error"*/

import {useEffect, useState} from 'react';

export default function useGoogleMaps(lat, lng, zoom, mapConfig, elementId) {
    return useGoogleMapInternal(`${process.env.REACT_APP_FIREBASE_API_KEY}&libraries=places`, lat, lng, zoom, mapConfig, elementId);
}

const useGoogleMapInternal = (apiKey, lat, lng, zoom, mapConfig, elementId, initFunc = () => {}) => {
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (!window.initMap) {
            window.initMap = initMap;
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
            script.async = true;
            document.body.appendChild(script);
        } else {
            initMap();
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (map) {
            map.setCenter(new google.maps.LatLng(lat, lng));
            map.setZoom(zoom);
        }
    }, [lat, lng, zoom]);  // eslint-disable-line react-hooks/exhaustive-deps

    const initMap = () => {
        let config = {center: {lat, lng}, zoom};
        if (mapConfig) {
            config = {
                ...config,
                ...mapConfig
            };
        }
        const googleMap = new google.maps.Map(document.getElementById(elementId), config);
        initFunc(googleMap);
        setMap(googleMap);
    };

    return map;
};
