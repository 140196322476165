import { combineReducers } from "redux";
import { useSelector, shallowEqual } from "react-redux";
import { purchasesApi } from "@devsontap/nica-redux-commons";

import activePurchases from "./activePurchases";
import adminResources from "./adminResources";
import attributes from "./attributes";
import locations from "./locations";
import login from "./login";
import queries from "./queries";
import purchaseHistory from "./purchaseHistory";
import shoppingCart from "./shoppingCart";
import orgs from "./organizations";
import purchasables from "./purchasables";
import membershipReport from "./membershipReport";
import memberships from "./memberships";
import discountCodes from "./discountCodes";
import paymentMethods from "./paymentMethods";

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    activePurchases,
    adminResources,
    attributes,
    locations,
    login,
    queries,
    purchaseHistory,
    shoppingCart,
    orgs,
    purchasables,
    membershipReport,
    memberships,
    discountCodes,
    paymentMethods,
    [purchasesApi.reducerPath]: purchasesApi.reducer,
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};

export default RootReducer;
