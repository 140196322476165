import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import IconButton from "@mui/material/IconButton";
import { ReactSortable } from "react-sortablejs";

import DropZone from "../../../_common/dropZone";
import {uploadFile} from "../../../../redux/locations";

import remove_btn from '../../../../img/btn-remove-photo.svg';

import './index.css';

const ImageUpload = ({photos, handleChange}) => {
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();

    const handlePhotosChange = (newPhotos) => {
        handleChange({ target: { name: "photos", value: newPhotos }})
    };

    const onUpload = (files) => {
        setUploading(true);
        dispatch(uploadFile(files[0], setUploading, photos, handlePhotosChange));
    };

    const onDeleteClick = (index) => {
        const newPhotos = [...photos];
        newPhotos.splice(index, 1);
        handlePhotosChange(newPhotos);
    };

    // const shouldCancelStart = (event) => {
    //     return Boolean(event.target.className.match(/MuiIconButton-label|MuiIconButton-root/));
    // }

    if (photos) {
        return (
            <div>
                <DropZone
                    outline={true}
                    accepts=".jpg,.jpeg,.png,.gif"
                    loading={uploading}
                    onDrop={onUpload} />
                <div className="flex">
                    <ReactSortable list={photos} setList={handlePhotosChange} className="flex">
                        {photos.map((item, itemIndex) => (
                            <div key={item} className="relative flex">
                                <div className="absolute top-0 right-0">
                                    <IconButton tooltip="Remove" onClick={() => { onDeleteClick(itemIndex); }}>
                                        <img src={remove_btn} alt="remove button" />
                                    </IconButton>
                                </div>
                                <img className="location-details-image-preview" src={item} alt="location edit preview" />
                            </div>
                        ))}
                    </ReactSortable>
                </div>
            </div>
        );
    }
    return <div />
};

export default ImageUpload;
