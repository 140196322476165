import React from 'react';
import {addMonths, format} from 'date-fns';

const MemberResourcePerLocationInfo = ({purchasable, purchasableInfo}) => {
    const isSponsoredLocations = purchasable.id === "sponsored_search_results";
    const titleText = isSponsoredLocations ? "Currently Sponsored Locations:" : "Locations:";

    return (purchasable ?
        <div className="pt-6">
            <div className="text-blue font-lg font-oswald uppercase">
                {titleText}
            </div>
            <div className="mt-1">
                {Object.keys(purchasableInfo.locations).map((locationKey, index) => {
                    const locationInfo = purchasableInfo.locations[locationKey];
                    const expirationDate = addMonths(locationInfo.purchaseDate, locationInfo.total);
                    const location = locationInfo.location;
                    return (
                        <div key={location.id} className="flex">
                            <div className="admin-member-resource-info-location-name font-lato mr-3">
                                - {location.name}
                            </div>
                            <div className="admin-member-resource-info-expires lato">
                                {`(Expires ${format(expirationDate, 'MM/dd/yyyy')})`}
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
        :
        null
    )
};

export default MemberResourcePerLocationInfo;
