import React from 'react';
import {useField} from "formik";

import AttributeOptions from "../attributeOptions";

import './index.css';
import Button from "@mui/material/Button";

const AttributeSubSection = ({attribute, attributeId, parentId, value, onChange, setFieldValue}) => {
    const {options, type, name, sortOptions} = attribute;
    const [field] = useField(`attributes.${parentId}`);

    const onSelectAll = () => {
        setFieldValue(`attributes.${attributeId}`, {...value, choice: Object.keys(options)});

        let choice = Object.keys(options);
        if (field.value && field.value.choice) {
            choice = choice.concat(field.value.choice);
        }

        setFieldValue(`attributes.${parentId}`, {...value, choice});
    };

    const onUnselectAll = () => {
        setFieldValue(`attributes.${attributeId}`, {...value, choice: []});

        // Remove from parent attribute too
        if (field.value && field.value.choice) {
            const choice = [...field.value.choice];

            // Remove each of these options from the parent choice array
            Object.keys(options).forEach(key => {
                const index = choice.indexOf(key);
                if (index > -1) {
                    choice.splice(index, 1);
                }
            });

            setFieldValue(`attributes.${parentId}`, {...value, choice});
        }
    };

    return (
        <div className="mb-6">
            <div className="location-details-section-subheader flex">
                <div className="flex justify-center items-center font-lato text-lightBlue ">
                    {name}
                </div>
                <div className="ml-5 mr-5">
                    <Button variant="text" color="primary" onClick={onSelectAll}>Select All</Button>
                </div>
                <div>
                    <Button variant="text" color="primary" onClick={onUnselectAll}>Unselect All</Button>
                </div>
            </div>
            <div className="location-details-attribute-options-container">
                <AttributeOptions
                    attributeName={name}
                    parent={field.value}
                    {...{value, onChange, attributeId, parentId, type, options, sortOptions, setFieldValue}} />
            </div>
        </div>
    )
};

export default AttributeSubSection
