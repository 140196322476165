import React, { useRef, forwardRef, useEffect } from "react";
import TextField from "@mui/material/TextField";

import { formatMoneyFromPennies } from "../../../utils/money";

const TenderEntryTextField = forwardRef(({ value, onChange, setValue, ...otherProps }, ref) => {
    const inputRef = useRef(null);

    const handleFormattedChange = (event) => {
        let value = null;
        if (`${event.target.value}`.length > 0) {
            const pennies = parseInt(event.target.value.replace(/[^0-9]/g, ""), 10);
            value = pennies;

            if (inputRef.current) {
                inputRef.current.value = formatMoneyFromPennies(value);
            }
        }

        onChange({
            ...event,
            target: {
                ...event.target,
                value
            }
        });

        setValue && setValue(event.target.name, value);
    };

    useEffect(() => {
        if (typeof value !== 'undefined' && value !== null) {
            inputRef.current.value = formatMoneyFromPennies(value);
        }
    }, [inputRef, value]);

    return (
        <div>
            <TextField
                variant="standard"
                inputRef={inputRef}
                onChange={handleFormattedChange}
                {...otherProps}
            />
        </div>

    );
});

export default TenderEntryTextField;
