import {createSelector} from "@reduxjs/toolkit";

export const membershipsLoadingSelector = state => state.memberships.loading;
export const membershipTypesLoadingSelector = state => state.memberships.membershipTypesLoading;
export const membershipTypesSelector = state => state.memberships.membershipTypes;
const membershipsMapSelector = state => state.memberships.membershipsMap;

export const membershipsSelector = createSelector(
    membershipsMapSelector,
    (map) => {
        return Object.values(map);
    }
);

export const isMemberSelector = createSelector(
    membershipsSelector,
    (memberships) => {
        return memberships?.length > 0;
    }
);

export const memberPricingTypeSelector = createSelector(
    membershipsSelector,
    (memberships) => {
        return memberships?.reduce((ret, membership) => {
            if (ret !== "elite") {
                return membership.membershipTier.id;
            }
            return ret;
        }, "nonMember");
    }
);
