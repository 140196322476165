import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";

import {useLoginSelector} from "../../../redux/login/selectors";
import {userUnauthed, userAuthed, createSessionCookie} from "../../../redux/login/";

/**
 * This component is intended to use ONCE in the Application
 * @param children  The component to render when the user is Authed
 * @param unAuthed  The component to render when the user is Unauthed
 */
const AuthProvider = ({ children, unAuthed }) => {
    const { loggedIn } = useLoginSelector();
    const dispatch = useDispatch();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdTokenResult(true);

                // KBM - Create a session cookie that can be used for login in other domains
                dispatch(createSessionCookie(token));

                dispatch(userAuthed(token.claims));
            } else {
                dispatch(userUnauthed());
            }
        });
    }, [dispatch]);

    return loggedIn ? children : unAuthed;
};

export default AuthProvider;
