import React from 'react';
import Button from "@mui/material/Button";

import AttributeSubSection from "../attributeSubSection";
import AttributeOptions from "../attributeOptions";
import {useAttributeSelector} from "../../../../../redux/attributes";
import CustomFormikField from "../../../../_common/formik/customField";

import './index.css';

const AttributeSection = ({attributeId, value, onChange, setFieldValue, showLabel = true}) => {
    const attribute = useAttributeSelector(attributeId);
    const {options, groups, type, name, description, sortOptions} = attribute;

    const onSelectAll = () => {
        setFieldValue(`attributes.${attributeId}`, {...value, choice: Object.keys(options)});
    };

    const onUnselectAll = () => {
        setFieldValue(`attributes.${attributeId}`, {...value, choice: []});
    };

    return (
        <div className="location-details-attribute-section mb-8">
            <div className="location-details-section-header flex">
                {showLabel &&
                    <div className="flex justify-center items-center font-lato text-lightBlue">
                        {name}
                    </div>
                }
                {type === "many" &&
                    <>
                        <div className="ml-5 mr-5">
                            <Button variant="text" color="primary" onClick={onSelectAll}>Select All</Button>
                        </div>
                        <div>
                            <Button variant="text" color="primary" onClick={onUnselectAll}>Unselect All</Button>
                        </div>
                    </>
                }
            </div>
            <div className="location-details-attribute-options-container">
                {groups ?
                    Object.keys(groups).map((groupKey, index) => {
                        return (
                            <CustomFormikField
                                CustomComponent={AttributeSubSection}
                                name={`attributes.${groupKey}`}
                                key={`attribute-subsection-${groupKey}`}
                                parentId={attributeId}
                                attribute={groups[groupKey]}
                                attributeId={groupKey}
                                errorField="choice"
                                setFieldValue={setFieldValue} />
                        )
                    })
                    :
                    <AttributeOptions
                        attributeName={name}
                        {...{value, onChange, attributeId, type, options, sortOptions, setFieldValue}} />
                }
            </div>
            {description &&
                <div className="location-details-attribute-description">
                    {description}
                </div>
            }
        </div>
    )
};

export default AttributeSection
