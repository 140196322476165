import { createSlice } from "@reduxjs/toolkit";
import { PurchasableType } from "@devsontap/nica-api/core/models/enums";

const INITIAL_STATE = {
    loading: false,
    purchasableSaving: false,
    purchasablesMap: {},
    purchasablesOrder: [], // ids
    resourceUploading: false,
};

const purchasablesSlice = createSlice({
    name: "purchasables",
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        purchasableSaving(state, action) {
            state.purchasableSaving = action.payload;
        },
        purchasablesSuccess(state, action) {
            state.loading = false;
            state.purchasableSaving = false;
            action.payload.forEach((purchasable) => {
                state.purchasablesMap[purchasable.id] = purchasable;
            });
        },
        purchasablesOrderSuccess(state, action) {
            state.purchasablesOrder = action.payload;
        },
    },
});

export const { loading, purchasableSaving, purchasablesSuccess, purchasablesOrderSuccess } = purchasablesSlice.actions;

export default purchasablesSlice.reducer;

export const loadPurchasables = (enqueueSnackbar) => (dispatch, getState, { api }) => {
    dispatch(loading(true));
    return api
        .getOrderedPurchasables()
        .then((data) => {
            dispatch(purchasablesOrderSuccess(data.order));
            return dispatch(purchasablesSuccess(data.purchasables));
        })
        .catch((error) => {
            dispatch(loading(false));
            enqueueSnackbar(error.message, { variant: "error" });
        });
};

export const savePurchasablesOrder = (purchasablesOrder, enqueueSnackbar) => (dispatch, getState, { api }) => {
    dispatch(loading(true));
    return api
        .savePurchasablesSortOrder(purchasablesOrder)
        .then(() => {
            dispatch(loading(false));
            dispatch(purchasablesOrderSuccess(purchasablesOrder));
        })
        .catch((error) => {
            dispatch(loading(false));
            enqueueSnackbar(error.message, { variant: "error" });
        });
};

export const savePurchasable = (purchasable, enqueueSnackbar, onSuccess) => async (dispatch, getState, { api }) => {
    try {
        dispatch(purchasableSaving(true));
        let downloadUrl = purchasable.downloadUrl;

        // If there's a file, upload it and optionally send notify event
        if (purchasable.file) {
            downloadUrl = await api.uploadFile(purchasable.file, `purchasables/${purchasable.file.name}`);
            if (purchasable.notify) {
                await api.createAdminResourceNotifyEvent(purchasable);
            }
        }

        // Now save the purchasable
        const update = await api.purchasables.save({
            ...purchasable,
            downloadUrl,
            perLocation: purchasable.type === PurchasableType.SPONSORED_SEARCH_RESULTS.value
        });

        // If we have a new purchasable then we need to add it to the end of the sortOrder
        if (!purchasable.id) {
            const sortOrder = getState().purchasables.purchasablesOrder;
            dispatch(savePurchasablesOrder([...sortOrder, { id: update.id }], enqueueSnackbar));
        }

        // Dispatch result
        dispatch(purchasablesSuccess([update]));
        onSuccess && onSuccess();
    } catch (error) {
        dispatch(purchasableSaving(false));
        enqueueSnackbar(error.message, { variant: "error" });
    }
};
