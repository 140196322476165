import {
    MEMBERSHIP_REPORT_LOADING,
    MEMBERSHIP_REPORT_SUCCESS,
    MEMBERSHIP_REPORT_ERROR
} from "./types";

const INITIAL_STATE = {
    reportLoading: false,
    report: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MEMBERSHIP_REPORT_LOADING:
            return {
                ...state,
                reportLoading: true
            };
        case MEMBERSHIP_REPORT_SUCCESS:
            return {
                ...state,
                reportLoading: false,
                report: action.payload
            };
        case MEMBERSHIP_REPORT_ERROR:
            return {
                ...state,
                reportLoading: false
            };
        default:
            return state;
    }
};

export default reducer;

