import React from 'react';

import AdminOnly from "../../../_common/adminOnly";
import StreetViewOverride from "../../details/streetViewImage";
import ImageUpload from "../../details/imageUpload";

import './index.css';

const Images = ({ streetViewImage, photos, handleChange }) => {
    return (
        <div className="pb-3 w-full">
            <AdminOnly noRedirect={true}>
                <div className="location-details-section">
                    <div className="font-lato text-lightBlue mb-2">
                        Google Street View Override
                    </div>
                    <StreetViewOverride streetViewImage={streetViewImage} handleChange={handleChange} />
                </div>
            </AdminOnly>
            <div className="location-details-section">
                <div className="font-lato text-lightBlue mb-2 mt-4">
                    Images
                </div>
                <ImageUpload photos={photos} handleChange={handleChange} />
            </div>
        </div>
    )
};

export default Images;
