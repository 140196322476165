import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { Formik, Form } from 'formik';
import {SCHEMA as LOCATION_SCHEMA} from "@devsontap/nica-api/core/models/location"
import _ from 'lodash';
import { useSnackbar } from "notistack";
import { Accordion, AccordionSummary, AccordionDetails, AppBar, Toolbar } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';

import LocationInfo from "./info";
import Loading from "../../_common/loading";
import {useLocationDetailsSelector} from "../../../redux/locations/selectors";
import {useLoginSelector} from "../../../redux/login/selectors";
import {useAttributeInflatorSelector, useAttributeListSelector} from "../../../redux/attributes";
import {saveLocation, fetchLocation} from "../../../redux/locations";
import useReactRouter from "../../../hooks/useReactRouter";
import Images from "./images";
import MedicationsAvailable from "./medications";
import PracticeDetails from "./practiceDetails";
import AdditionalInfo from "./additional";

import closeButton from "../../../img/btn-cancel.svg";

import './index.css';

const LocationDetails = ({ locationId, orgId }) => {
    const [saving, setSaving] = useState(false);
    const { location } = useLocationDetailsSelector(locationId);
    const { history } = useReactRouter();
    const { claims: { email, uid, displayName, admin } } = useLoginSelector();
    const attributes = useAttributeInflatorSelector();
    const { loading: attributesLoading } = useAttributeListSelector();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const submitRef = useRef(null);

    const onClose = () => history.push('/locations');

    useEffect(() => {
        if (!location) {
            dispatch(fetchLocation(locationId, enqueueSnackbar, () => {
                onClose();
            }));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        attributesLoading || !location ?
            <Loading size={45} />
            :
            <div className="bg-appPage min-h-full overflow-y-scroll">
                <AppBar position="sticky" color="primary">
                    <Toolbar>
                        <div className="flex w-full">
                            <div>
                                <IconButton onClick={onClose} >
                                    <img src={closeButton} alt="close button" />
                                </IconButton>
                            </div>
                            <div className="text-2xl text-[#fffff] font-bold flex items-center ml-6">
                                {locationId === "add" ? "Add" : "Edit"} Location
                            </div>
                            <div className="flex-1 flex justify-end items-center">
                                {saving ?
                                    <CircularProgress color="secondary" size={30} /> :
                                    <Button variant="text" color="secondary" onClick={() => submitRef.current?.click()}>
                                        <SaveIcon className="mr-1" htmlColor="#ffffff" /> <span className="text-[#ffffff]">Save</span>
                                    </Button>
                                }
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="mb-10">
                    <Formik
                        initialValues={_getDefaultValues(location, orgId, uid, email, displayName, attributes)}
                        onSubmit={(values) => {
                            setSaving(true);
                            dispatch(saveLocation(values, setSaving, onClose, false, enqueueSnackbar));
                        }}
                        validationSchema={LOCATION_SCHEMA}
                        validateOnChange={false}>
                        {({values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue, submitCount, isValid}) => {
                            return (
                                <Form>
                                    <input type="Submit" className="hidden" ref={submitRef} />
                                    <div className="pt-10">
                                        <div className="flex justify-center">
                                            <div className="max-w-[750px] flex-1">
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary className="font-oswald text-2xl" expandIcon={<ExpandMoreIcon />}>
                                                        <span className="text-darkBlue">Location Information</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="w-full">
                                                            <LocationInfo values={values} errors={errors} setFieldValue={setFieldValue} />
                                                            <div className="flex justify-end items-center">
                                                                {!saving ?
                                                                    <Button variant="text" color="secondary" type="Submit">
                                                                        <SaveIcon className="mr-1" /> Save
                                                                    </Button> :
                                                                    <CircularProgress size={35} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                { (admin || values.isMember) &&
                                                    <Accordion>
                                                        <AccordionSummary className="font-oswald text-2xl" expandIcon={<ExpandMoreIcon />}>
                                                            <span className="text-darkBlue">Images</span>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="w-full">
                                                                <Images photos={values.photos} streetViewImage={values.google_street_view_image} handleChange={handleChange} />
                                                                <div className="flex justify-end items-center">
                                                                    {!saving ?
                                                                        <Button variant="text" color="secondary" type="Submit">
                                                                            <SaveIcon className="mr-1" /> Save
                                                                        </Button> :
                                                                        <CircularProgress size={35} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                }
                                                <Accordion>
                                                    <AccordionSummary className="font-oswald text-2xl" expandIcon={<ExpandMoreIcon />}>
                                                        <span className="text-darkBlue">Medications Available</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="w-full">
                                                            <MedicationsAvailable setFieldValue={setFieldValue} />
                                                            <div className="flex justify-end items-center">
                                                                {!saving ?
                                                                    <Button variant="text" color="secondary" type="Submit">
                                                                        <SaveIcon className="mr-1" /> Save
                                                                    </Button> :
                                                                    <CircularProgress size={35} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary className="font-oswald text-2xl" expandIcon={<ExpandMoreIcon />}>
                                                        <span className="text-darkBlue">Practice Details</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="w-full">
                                                            <PracticeDetails setFieldValue={setFieldValue} />
                                                            <div className="flex justify-end items-center">
                                                                {!saving ?
                                                                    <Button variant="text" color="secondary" type="Submit">
                                                                        <SaveIcon className="mr-1" /> Save
                                                                    </Button> :
                                                                    <CircularProgress size={35} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary className="font-oswald text-2xl" expandIcon={<ExpandMoreIcon />}>
                                                        <span className="text-darkBlue">Additional Information</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="w-full">
                                                            <AdditionalInfo setFieldValue={setFieldValue} handleChange={handleChange} temporarilyClosed={values.temporarily_closed} />
                                                            <div className="flex justify-end items-center">
                                                                {!saving ?
                                                                    <Button variant="text" color="secondary" type="Submit">
                                                                        <SaveIcon className="mr-1" /> Save
                                                                    </Button> :
                                                                    <CircularProgress size={35} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>

    )
};

function _getDefaultValues(location, orgId, uid, userEmail, displayName, attributes) {
    return {
        active: false,
        name: "",
        address: {
            line1: "",
            line2: "",
            city: "",
            stateCode: "",
            zip: ""
        },
        phoneNumber: "",
        numberOfChairs: 0,
        description: "",
        emailAddress: "",
        faxNumber: "",
        url: "",
        isPreferred: false,
        orgId: location && location.id ? location.orgId : orgId,
        addedBy: {
            uid,
            userEmail,
            displayName
        },
        businessHours: {},
        facebookPageId: "",
        twitterHandle: "",
        languagesSpoken: "",
        temporarily_closed: false,
        temporarily_closed_reason: "",
        photos: [],
        latitude: -1,
        longitude: -1,
        businessHoursTemporarilyAffected: false,
        businessHoursTemporaryMessage: '',
        referralFormUrl: '',
        ...location,
        attributes: _getDefaultAttributeValues(location, attributes),
    }
}

function _getDefaultAttributeValues(location, attributes) {
    const result = attributes.reduce((ret, attribute) => {
        ret[attribute.key] = _.merge({
            required: Boolean(attribute.required),
            name: attribute.name,
            choice: []
        }, location && location.attributes && location.attributes[attribute.key] ? location.attributes[attribute.key] : {});
        if (attribute.groups) {
            Object.keys(attribute.groups).forEach(groupKey => {
                ret[groupKey] = _.merge({
                    required: Boolean(attribute.groups[groupKey].required),
                    name: attribute.groups[groupKey].name,
                    choice: []
                }, location && location.attributes && location.attributes[groupKey] ? location.attributes[groupKey] : {})
            })
        }
        return ret;
    }, location && location.attributes ? {...location.attributes} : {});

    return result;
}

export default LocationDetails;
