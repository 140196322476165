import React, {useState, useEffect} from 'react';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import {getSessionCookie} from '@devsontap/nica-api/core/utils/cookies';
import {useDispatch} from "react-redux";

import SignIn from "./signin";
import LoginVerification from "./verify";
import {getUserInfo} from "../../redux/login/";

import logo from '../../img/nica-logo.svg';

import './index.css';
import Loading from "../_common/loading";

const Login = () => {
    const sessionCookie = getSessionCookie();
    const [loading, setLoading] = useState(Boolean(sessionCookie));
    const dispatch = useDispatch();

    // Call getUserInfo which will try and exchange our cookie for an auth token for login
    useEffect(() => {
        if (sessionCookie) {
            setLoading(true);
            dispatch(getUserInfo(setLoading));
        }
    }, [sessionCookie, dispatch]);

    return (
        <div className="login-container flex flex-col">
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-left flex w-full">
                        <img src={logo} alt="nica logo" className="login-nica-logo" />
                    </div>
                </Toolbar>
            </AppBar>
            <div className="login-content flex flex-col justify-center items-center flex-1">
                <div className="login-background" />
                <Card className="login-card">
                    <CardContent>
                        {loading ?
                            <Loading size={45} /> :
                            <Router>
                                <Switch>
                                    <Route path="/signInWithLink" component={LoginVerification} />
                                    <Route>
                                        <SignIn />
                                    </Route>
                                </Switch>
                            </Router>
                        }
                    </CardContent>
                </Card>
            </div>
        </div>
    )
};

export default Login;
