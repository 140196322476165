import React, {useEffect, useState, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import EditDiscountCode from "./edit";
import DiscountCodeItem from "./item";
import Loading from "../_common/loading";
import NICADialog from "../_common/dialog";
import {loadDiscountCodes} from "../../redux/discountCodes";
import {useDiscountCodesSelector} from "../../redux/discountCodes/selectors";

import './index.css';

const AdminDiscountCodes = () => {
    const [editId, setEditId] = useState(null);
    const [saving, setSaving] = useState(false);
    const {loading, codes} = useDiscountCodesSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const submitRef = useRef(null);

    useEffect(() => {
        dispatch(loadDiscountCodes(enqueueSnackbar))
    }, [dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="app-page">
            <div className="memberships-container">
                {loading || !codes ?
                    <Loading size={45} />
                    :
                    <div>
                        <div className="fixed right-12 top-9 z-[2101]">
                            <Fab color="secondary" aria-label="Add" onClick={() => setEditId(-1)}>
                                <AddIcon htmlColor="#ffffff" />
                            </Fab>
                        </div>
                        {codes.length ?
                            <div className="grid gap-4 grid-cols-3">
                                {codes.map((code, index) => (
                                    <div key={`discount-code-${code.id}`}>
                                        <DiscountCodeItem code={code} setEditId={setEditId} />
                                    </div>
                                ))}
                            </div> :
                            <div>
                                No Discount Codes.
                            </div>
                        }
                    </div>
                }
            </div>
            <NICADialog
                onClose={() => setEditId(null)}
                open={Boolean(editId)}
                title={`${editId === -1 ? "Add" : "Edit"} Discount Code`}
                actions={[
                    {label: 'Cancel', onClick: () => setEditId(null)},
                    {label: 'Save', onClick: () => submitRef.current?.click(), loading: saving}
                ]}>
                <EditDiscountCode onClose={() => setEditId(null)} codeId={editId} submitRef={submitRef} setSaving={setSaving} />
            </NICADialog>
        </div>
    )
};

export default AdminDiscountCodes;
