import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import numeral from 'numeral';

import Loading from "../../_common/loading";

// import './index.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChooseLocationsDialog = ({open, onClose, locations, onAddToCart, priceOption, claims}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const onSelectAllClick = () => setSelectedItems(locations.map((location, index) => index));

    const onLocationSelected = (index) => {
        const indexOf = selectedItems.indexOf(index);
        if (indexOf === -1) {
            setSelectedItems([...selectedItems, index]);
        } else {
            const items = [...selectedItems];
            items.splice(indexOf, 1);
            setSelectedItems(items);
        }
    };

    const calculateTotal = () => {
        if (priceOption) {
            return selectedItems.length * priceOption;
        }
        return 0;
    };

    const handleClose = () => {
        setSelectedItems([]);
        onClose();
    };

    const onAddClick = () => {
        onAddToCart(selectedItems.map(index => locations[index]));
        handleClose();
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}>
            <DialogTitle id="alert-dialog-slide-title">
                <div className="flex admin-member-resource-dialog-title-container">
                    <div className="flex-1">
                        <Button color="primary" onClick={onSelectAllClick}>Select All</Button>
                    </div>
                    <div className="flex-1 oswald admin-member-resource-dialog-title">
                        Choose Locations
                    </div>
                    <div className="flex-1 lato admin-member-resource-dialog-locations-selected flex items-center justify-end">
                        {`${selectedItems.length} Location(s) Selected`}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                {locations ?
                    locations.length ?
                        locations.map((location, index) => {
                            return (
                                <div key={"location-" + index}>
                                    <div className="flex" onClick={() => onLocationSelected(index)}>
                                        <div className="admin-member-resource-dialog-location-container flex-1">
                                            <div className="admin-member-resource-dialog-location-name oswald">
                                                {location.name}
                                            </div>
                                            <div className="admin-member-resource-dialog-location-address lato">
                                                {_getLocationAddress(location)}
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <Checkbox
                                                checked={selectedItems.includes(index)}
                                                onChange={() => onLocationSelected(index)}
                                                value="checked" />
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            )
                        })
                        :
                        <div className="admin-member-resource-dialog-location-name oswald">
                            No locations. Add one in the Location Manager.
                        </div>
                    :
                    <Loading size={50} />
                }
            </DialogContent>
            <DialogActions>
                <div>
                    <div className="text-right">
                        <div className="admin-member-resource-dialog-total oswald">
                            Total
                        </div>
                        <div className="admin-member-resource-dialog-total-amount lato">
                            {numeral(calculateTotal() / 100.0).format('$0,0.00')}
                        </div>
                    </div>
                    <div className="flex">
                        <Button variant="text" onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="text" onClick={onAddClick} color="primary" disabled={!selectedItems.length}>
                            Add To Cart
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
};

function _getLocationAddress(location) {
    const {address} = location;
    if (address) {
        const {line1, city, stateCode, zip} = address;
        return `${line1} ${city}, ${stateCode} ${zip}`;
    }
    return '';
}

export default ChooseLocationsDialog;
