import React, { useEffect } from "react";
import { AppBar, Toolbar, Button, IconButton, CircularProgress } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    purchasableSelector,
    purchasableSavingSelector,
    purchasablesLoadingSelector,
} from "../../../redux/purchasables/selectors";
import { loadPurchasables } from "../../../redux/purchasables";
import { useSnackbar } from "notistack";
import ItemForm from "./itemForm";
import closeButton from "../../../img/btn-cancel.svg";

const AdminResourcesItem = ({ resourceId }) => {
    const saving = useSelector(purchasableSavingSelector);
    const purchasablesLoading = useSelector(purchasablesLoadingSelector);
    const dispatch = useDispatch();
    const purchasable = useSelector(purchasableSelector(resourceId));
    const submitRef = React.useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    useEffect(() => {
        if (!purchasable) {
            dispatch(loadPurchasables(enqueueSnackbar));
        }
    }, [purchasable, dispatch, enqueueSnackbar]);

    const onClose = () => history.push("/admin-resources");

    return (
        <div>
            <AppBar position="sticky" color="primary">
                <Toolbar>
                    <div className="flex w-full items-center">
                        <div>
                            <IconButton onClick={onClose}>
                                <img src={closeButton} alt="close button" />
                            </IconButton>
                        </div>
                        <div className="text-2xl text-[#fffff] font-bold flex items-center ml-6">Edit Resource</div>
                        <div className="flex-1 flex justify-end items-center">
                            {saving ? (
                                <CircularProgress color="secondary" size={30} />
                            ) : (
                                <Button variant="text" color="secondary" onClick={() => submitRef.current?.click()}>
                                    <SaveIcon className="mr-1" htmlColor="#ffffff" />{" "}
                                    <span className="text-[#ffffff]">Save</span>
                                </Button>
                            )}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="mb-10">
                {purchasablesLoading || !purchasable ? (
                    <div className="flex justify-center mt-6">
                        <CircularProgress color="secondary" size={45} />
                    </div>
                ) : (
                    <ItemForm ref={submitRef} purchasable={purchasable} />
                )}
            </div>
        </div>
    );
};

export default AdminResourcesItem;
