import React from 'react';
import {useDispatch} from "react-redux";
import numeral from 'numeral';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

import {removeItemFromCart, toggleItemAutoRenew} from "../../../redux/shoppingCart/";
import {cartItemTotal} from "../../../utils/shoppingCart";

import './index.css';

const ShoppingCartItem = ({item, index, onAutoRenewChecked}) => {
    const dispatch = useDispatch();
    const onRemoveClick = () => dispatch(removeItemFromCart(index));
    const toggleAutoRenew = () => dispatch(toggleItemAutoRenew(index));

    return (
        <div className="cart-item-container">
            <div className="flex">
                <div className="flex-1">
                    <div className="cart-item-title oswald">
                        {item.purchasableTitle}
                    </div>
                    {/*<div className="cart-item-subtitle lato">*/}
                    {/*    {item.option.title}*/}
                    {/*</div>*/}
                </div>
                <div className="cart-item-total lato">
                    {numeral(cartItemTotal(item) / 100.0).format('$0,0.00')}
                </div>
            </div>
            {item.locations &&
                <div className="cart-item-locations oswald">
                    {item.locations.map(location => location.name).join(" • ")}
                </div>
            }
            <div className="cart-item-auto-renew-container flex">
                <div>
                    <div className="cart-item-auto-renew-title lato">
                        Auto-renew Purchase?
                    </div>
                    <div className="cart-item-auto-renew-description lato">
                        We will charge the same Card used today every time this purchase expires.
                    </div>
                </div>
                <div>
                    <Switch
                        checked={item.autoRenew}
                        onChange={toggleAutoRenew}
                        value="checked" />
                </div>
            </div>
            {!item.unremovable &&
                <div className="cart-item-remove-btn-container">
                    <Button variant="text" color="secondary" onClick={onRemoveClick}>Remove From Cart</Button>
                </div>
            }
        </div>
    )
};

export default ShoppingCartItem;
