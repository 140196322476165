import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import OrganizationItem from "./item";
import Loading from "../_common/loading";
import NICADialog from "../_common/dialog";
import {loadOrganizations} from "../../redux/organizations";
import {useOrgsSelector} from "../../redux/organizations/selectors";
import {primaryOrgIdSelector, useLoginSelector} from "../../redux/login/selectors";

import EditOrganization from "./edit";

const Organizations = () => {
    const [editOrg, setEditOrg] = useState(null);
    const { orgIds } = useLoginSelector();
    const { orgsLoading } = useOrgsSelector();
    const dispatch = useDispatch();
    const saveRef = useRef(null);
    const primaryOrgId = useSelector(primaryOrgIdSelector);

    useEffect(() => {
        dispatch(loadOrganizations(orgIds));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="app-page p-10">
            {orgsLoading ?
                <Loading size={45} />
                :
                orgIds.map((orgId, index) => (
                    <OrganizationItem key={orgId} orgId={orgId} onEdit={() => setEditOrg(orgId)} primary={orgId === primaryOrgId} />
                ))
            }
            <NICADialog
                open={Boolean(editOrg)}
                onClose={() => setEditOrg(null)}
                title="Edit Organization"
                actions={[
                    { label: 'Cancel', onClick: () => setEditOrg(null) },
                    { label: 'Save', onClick: () => saveRef.current.click() }
                ]}>
                <EditOrganization orgId={editOrg} saveRef={saveRef} onClose={() => setEditOrg(null)} />
            </NICADialog>
        </div>
    )
};

export default Organizations;
