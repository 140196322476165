import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import WarningIcon from '@mui/icons-material/Warning';

import {logError} from "../../../../redux/errors/actions";
import {useLoginSelector} from "../../../../redux/login/selectors";

import logo from "../../../../img/nica-logo.svg";

import './index.css';

const ErrorView = ({error, errorInfo}) => {
    const [errorRecord, setErrorRecord] = useState(null);
    const dispatch = useDispatch();
    const {claims} = useLoginSelector();

    useEffect(() => {
        // KBM - only report errors when we're not running locally
        if (document.domain !== "localhost") {
            dispatch(logError(error.message, errorInfo, claims, record => setErrorRecord(record)));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-left flex w-full">
                        <div>
                            <img src={logo} className="nav-header-logo" alt="nica logo" />
                        </div>
                        <div className="home-toolbar-title flex items-center flex-1 ml-6">
                            Sorry, something went wrong.
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="text-center error-view-container">
                <div className="flex justify-center items-center mt-7">
                    <WarningIcon htmlColor="#FF0000" fontSize="large" />
                </div>
                <div className="error-view-title mt-2">
                    We have received a notification of this error, and you may use the code below to discuss the issue with NICA staff.
                </div>
                <div className="error-view-subtitle mt-7">
                    Your error tracking code is: <strong>{errorRecord ? errorRecord.key : "(waiting...)"}</strong>
                </div>
            </div>
        </div>
    )
};

export default ErrorView;
