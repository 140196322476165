import React, { forwardRef, useMemo, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Card,
    CardContent,
    IconButton,
    TextField,
    Switch,
    Link,
    InputLabel,
    Select,
    FormControl,
    OutlinedInput,
    MenuItem,
    Checkbox,
    ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { format, fromUnixTime } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { SCHEMA } from "@devsontap/nica-api/core/models/purchasable";
import { PurchasableType } from "@devsontap/nica-api/core/models/enums";
import { useSnackbar } from "notistack";

import DropZone from "../../_common/dropZone";
import EnumTypeSelect from "../../_common/enumSelect";
import TenderEntryTextField from "../../_common/textfields/tenderEntry";
import { savePurchasable } from "../../../redux/purchasables";
import { stateObjects } from "../../../utils/states";
import { salesTaxRatesSelector } from "../../../redux/shoppingCart/selectors";
import { fetchSalesTaxRates } from "../../../redux/shoppingCart";

const ItemForm = forwardRef(({ purchasable }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const salesTaxRates = useSelector(salesTaxRatesSelector);
    const {
        register,
        control,
        setValue,
        handleSubmit,
        watch,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ...purchasable,
            pricing: {
                ...purchasable?.pricing,
            },
        },
        resolver: yupResolver(SCHEMA),
    });

    useEffect(() => {
        if (!salesTaxRates) {
            dispatch(fetchSalesTaxRates(enqueueSnackbar));
        }
    }, [salesTaxRates, dispatch, enqueueSnackbar]);


    const supportedStates = useMemo(() => {
        if (salesTaxRates) {
            return stateObjects.filter(state => salesTaxRates[state.stateCode] !== undefined );
        }
        return stateObjects;
    }, [salesTaxRates]);

    const type = watch("type");
    const file = watch("file");
    const salesTaxRequired = watch("salesTaxRequired");

    const onSubmit = (data) => {
        dispatch(savePurchasable(data, enqueueSnackbar, onClose));
    };

    const onFileCancel = () => {
        setValue("file", null);
    };

    const onClose = () => history.push("/admin-resources");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input ref={ref} type="submit" className="hidden" />
            <div className="pt-10">
                <div className="flex justify-center">
                    <div className="max-w-[750px] flex-1">
                        <Card>
                            <CardContent className="space-y-6">
                                <TextField
                                    {...register("title")}
                                    label="Title"
                                    variant="standard"
                                    className="w-full"
                                    error={!!errors.title}
                                    helperText={errors.title?.message}
                                />
                                <TextField
                                    {...register("description")}
                                    label="Description"
                                    variant="standard"
                                    className="w-full"
                                    error={!!errors.description}
                                    helperText={errors.description?.message}
                                />

                                <TextField
                                    type="number"
                                    {...register("credits")}
                                    label="# Credits"
                                    variant="standard"
                                    error={!!errors.credits}
                                    helperText={errors.credits?.message}
                                    className="w-full"
                                />

                                <TenderEntryTextField
                                    {...register("pricing.nonMember")}
                                    label="Base Price"
                                    placeholder="$0.00"
                                    error={!!errors.pricing?.nonMember}
                                    helperText={errors.pricing?.nonMember?.message}
                                    setValue={setValue}
                                    value={getValues("pricing.nonMember")}
                                    fullWidth
                                />

                                <TenderEntryTextField
                                    {...register("pricing.supporting")}
                                    label="Supporting Member Price"
                                    placeholder="$0.00"
                                    error={!!errors.pricing?.supporting}
                                    helperText={errors.pricing?.supporting?.message}
                                    setValue={setValue}
                                    value={getValues("pricing.supporting")}
                                    fullWidth
                                />

                                <TenderEntryTextField
                                    {...register("pricing.elite")}
                                    label="Elite Member Price"
                                    placeholder="$0.00"
                                    error={!!errors.pricing?.elite}
                                    helperText={errors.pricing?.elite?.message}
                                    setValue={setValue}
                                    value={getValues("pricing.elite")}
                                    fullWidth
                                />

                                <div className="flex items-center">
                                    <Controller
                                        type="checkbox"
                                        name="membershipRequired"
                                        defaultValue={false}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex-1">
                                                <InputLabel>Membership Required</InputLabel>
                                                <Switch
                                                    // {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="flex items-center">
                                    <Controller
                                        type="checkbox"
                                        name="salesTaxRequired"
                                        defaultValue={false}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex-1">
                                                <InputLabel>Sales Tax Required</InputLabel>
                                                <Switch
                                                    // {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                {salesTaxRequired && (
                                    <Controller
                                        type="text"
                                        name="applySalesTaxInStates"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <FormControl sx={{ width: "100%" }}>
                                                <InputLabel id="state-checkbox-label">Choose States</InputLabel>
                                                <Select
                                                    labelId="state-checkbox-label"
                                                    id="state-checkbox"
                                                    multiple
                                                    {...field}
                                                    defaultValue={[]}
                                                    input={<OutlinedInput label="Choose States" />}
                                                    renderValue={(selected) => selected.join(", ")}
                                                    // MenuProps={MenuProps}
                                                >
                                                    {supportedStates.map(({ stateCode, label }) => (
                                                        <MenuItem key={stateCode} value={stateCode}>
                                                            <Checkbox
                                                                checked={
                                                                    (getValues("applySalesTaxInStates") ?? []).indexOf(
                                                                        stateCode
                                                                    ) > -1
                                                                }
                                                            />
                                                            <ListItemText primary={label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                )}

                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <EnumTypeSelect
                                            {...field}
                                            variant="standard"
                                            label="Type"
                                            enumObj={PurchasableType}
                                        />
                                    )}
                                />
                                {type === "DOWNLOAD" && (
                                    <>
                                        {file ? (
                                            <div className="space-y-1">
                                                <div className="uppercase text-xs italic text-gray-300">
                                                    Pending Upload
                                                </div>
                                                <div className="flex items-center p-2 border border-gray-500 rounded-sm hover:shadow">
                                                    <div className="flex-1">{file.name}</div>
                                                    <div className="">
                                                        <IconButton onClick={onFileCancel}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                <div className="flex items-center pt-4">
                                                    <Controller
                                                        type="checkbox"
                                                        name="notifyUsers"
                                                        defaultValue={false}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <div className="flex-1">
                                                                <InputLabel>Notify Users</InputLabel>
                                                                <Switch
                                                                    // {...field}
                                                                    checked={field.value}
                                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                                />
                                                                <div>Notify users who have paid for this resource</div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <DropZone
                                                    outline={true}
                                                    multiple={false}
                                                    accepts=".xlsx,.xls,.pdf"
                                                    loading={false}
                                                    onDrop={(files) => setValue("file", files[0])}
                                                />
                                                {purchasable?.downloadUrl && purchasable?.updatedAt && (
                                                    <div className="mt-1">
                                                        <span className="text-sm text-gray-400 mr-4">
                                                            Last Updated on{" "}
                                                            {format(
                                                                fromUnixTime(purchasable?.updatedAt / 1000),
                                                                "MM/dd/yyyy hh:mm a"
                                                            )}
                                                        </span>
                                                        <Link
                                                            underline="hover"
                                                            rel="noreferrer"
                                                            href={purchasable?.downloadUrl}
                                                            target="_blank"
                                                        >
                                                            VIEW RESOURCE
                                                        </Link>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </form>
    );
});

export default ItemForm;
