import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { loadPurchasables } from "../../../../redux/purchasables";
import { purchasablesSelector } from "../../../../redux/purchasables/selectors";

const PurchasableSelect = ({value, onChange, fullWidth, disabled}) => {
    const purchasables = useSelector(purchasablesSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!purchasables?.length) {
            dispatch(loadPurchasables(enqueueSnackbar))
        }
    }, [purchasables, dispatch, enqueueSnackbar]);

    return (
        <FormControl fullWidth={fullWidth} disabled={disabled} variant="standard">
            <InputLabel id="organizationLabel">Restrict By Purchasable</InputLabel>
            <Select
                name="addPurchasable"
                value={value}
                onChange={onChange}
                labelId="organizationLabel"
                variant="standard"
                fullWidth={fullWidth}>
                {purchasables && purchasables.map((purchasable)=> {
                    return (
                        <MenuItem value={purchasable.id} key={purchasable.id}>{purchasable.title}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
};

PurchasableSelect.propTypes = {
    value: PropTypes.string.isRequired,
};

PurchasableSelect.defaultProps = {
    fullWidth: false,
    disabled: false
};

export default PurchasableSelect;
