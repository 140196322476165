import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {Button, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import NICADialog from "../_common/dialog";
import StripePaymentElement from "../_common/stripePaymentElement";
import PaymentMethodItem from "./item";
import Loading from "../_common/loading";
import { fetchPaymentMethods } from "../../redux/paymentMethods";
import { paymentMethodsLoadingSelector, paymentMethodsSelector } from "../../redux/paymentMethods/selectors";

const PaymentMethods: React.FC = () => {
    const [showAdd, setShowAdd] = useState(false);
    const submitRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const [addingPayment, setAddingPayment] = useState(false);
    const paymentMethods = useSelector(paymentMethodsSelector());
    const { enqueueSnackbar } = useSnackbar();
    const loading = useSelector(paymentMethodsLoadingSelector);

    useEffect(() => {
        dispatch(fetchPaymentMethods(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    const onComplete = () => {
        // hide the NICADialog
        // set loading to false
        // dispatch fetchPaymentMethods
        setShowAdd(false);
        setAddingPayment(false);
        dispatch(fetchPaymentMethods(enqueueSnackbar));
    };

    return (
        <div>
            {!loading &&
                <div className="relative w-full">
                    <div className="absolute right-4 -top-6 z-[1201]">
                        <Fab onClick={() => setShowAdd(true)} color="secondary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
            }
            <div className="pt-16 px-12">
                {loading && (
                    <div className="flex items-center justify-center">
                        <Loading size={45} />
                    </div>
                )}
                {!loading && paymentMethods?.length === 0 &&
                    <div className="text-center">
                        <div className="text-lg font-bold">
                            No Saved Payment Methods.
                        </div>
                        <div className="mt-2">
                            <Button variant="text" color="secondary" onClick={() => setShowAdd(true)}>
                                <AddIcon className="mr-1" />Add Payment Method
                            </Button>
                        </div>
                    </div>
                }
                {paymentMethods?.map((paymentMethod) => (
                    <PaymentMethodItem key={paymentMethod.id} paymentMethodId={paymentMethod.id} />
                ))}
            </div>
            <NICADialog
                open={showAdd}
                title="Add Payment Method"
                onClose={() => setShowAdd(false)}
                actions={[
                    { label: "Cancel", onClick: () => setShowAdd(false) },
                    {
                        label: "Submit",
                        loading: addingPayment,
                        onClick: () => {
                            setAddingPayment(true);
                            submitRef?.current?.click();
                        },
                    },
                ]}
            >
                <StripePaymentElement onComplete={onComplete} submitRef={submitRef} />
            </NICADialog>
        </div>
    );
};

export default PaymentMethods;
