import React, {useState, useRef} from 'react';
import {useDispatch} from "react-redux";
import Card from '@mui/material/Card';
import Button from "@mui/material/Button";
import Switch from '@mui/material/Switch';
import CircularProgress from "@mui/material/CircularProgress";
import UserIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UnlinkIcon from '@mui/icons-material/LinkOff';
import CopyIcon from '@mui/icons-material/ControlPointDuplicate';
import {useSnackbar} from "notistack";

import EditLocationUsers from "./users";
import AdminOnly from "../../_common/adminOnly";
import NICADialog from "../../_common/dialog";
import {useLocationSelector} from "../../../redux/locations/selectors";
import useReactRouter from "../../../hooks/useReactRouter";
import {deleteLocation, saveLocation, unclaimLocation, duplicateLocation} from "../../../redux/locations";

import reportIcon from '../../../img/insert-chart.svg';

import './index.css';

const LocationItem = ({ locationId }) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUnclaimDialog, setShowUnclaimDialog] = useState(false);
    const [showUsersDialog, setShowUsersDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const location = useLocationSelector(locationId);
    const {history} = useReactRouter();
    const dispatch = useDispatch();
    const saveRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const editLocation = () => history.push(`/locations/${locationId}`);
    const viewReport = () => history.push(`/locations/${locationId}/report`);

    const onDeleteLocation = () => {
        setShowDeleteDialog(false);
        setDeleting(true);
        dispatch(deleteLocation(locationId));
    };

    const onUnclaimLocation = () => {
        setShowUnclaimDialog(false);
        dispatch(unclaimLocation(location));
    };

    const onDuplicateClick = () => {
        setDuplicating(true);
        dispatch(duplicateLocation(location, setDuplicating, history, enqueueSnackbar));
    };

    const toggleActive = () => {
        dispatch(saveLocation({
            ...location,
            active: !location.active
        }, () => {}, null, false, enqueueSnackbar));
    };

    return (
        <>
            <Card className="location-item-card">
                <div>
                    {!location.active ?
                        <div className="location-item-pending">
                            Pending Approval
                        </div>
                        :
                        null
                    }
                    <div className="location-item-top-row">
                        <div className="location-item-top-row-info">
                            <div className="location-item-name">
                                {location.name}
                            </div>
                            <div className="location-item-address">
                                <div>
                                    {location.address.line1}
                                </div>
                                <div>
                                    {location.address.city}, {location.address.stateCode} {location.address.zip}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="location-item-hr" />
                    <div className="location-item-actions">
                        <div className="flex justify-center items-center">
                            <Button variant="text" color="secondary" onClick={editLocation}>
                                <EditIcon className="mr-1" color="secondary" /> Edit
                            </Button>
                            {deleting ?
                                <CircularProgress size={30} />
                                :
                                <Button variant="text" color="secondary" onClick={() => setShowDeleteDialog(true)}>
                                    <DeleteIcon className="mr-1" color="secondary" /> Remove
                                </Button>
                            }
                            <AdminOnly noRedirect={true}>
                                {location.orgId ?
                                    <Button variant="text" color="secondary" onClick={() => setShowUnclaimDialog(true)}>
                                        <UnlinkIcon className="mr-1" color="secondary" /> Unclaim
                                    </Button>
                                    :
                                    null
                                }
                            </AdminOnly>
                        </div>
                        <div>
                            <Button variant="text" color="secondary" onClick={viewReport}>
                                <img src={reportIcon} alt="Show Location Report" className="mr-1"/>Location Report
                            </Button>
                        </div>
                        <div>
                            <Button variant="text" color="secondary" onClick={() => setShowUsersDialog(true)}>
                                <UserIcon className="mr-1" color="secondary"/>Users
                            </Button>
                        </div>
                        <div>
                            {duplicating ?
                                <CircularProgress size={30} /> :
                                <Button variant="text" color="secondary" onClick={onDuplicateClick}>
                                    <CopyIcon className="mr-1" color="secondary"/>Copy
                                </Button>
                            }
                        </div>
                        <div className="flex justify-end flex-1 mr-2">
                            <AdminOnly noRedirect={true}>
                                <div className="flex">
                                    <div className="location-item-active-label flex justify-center items-center">Active Listing</div>
                                    <div>
                                        <Switch
                                            checked={location.active}
                                            color="secondary"
                                            value="whatever"
                                            onChange={toggleActive} />
                                    </div>
                                </div>
                            </AdminOnly>
                        </div>
                    </div>
                </div>
            </Card>
            <NICADialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                actions={[
                    { label: 'Cancel', onClick: () => setShowDeleteDialog(false)},
                    { label: 'Delete', onClick: onDeleteLocation}
                    ]}
                title="Confirm Delete">
                Are you sure you want to delete {location.name}?
            </NICADialog>
            <NICADialog
                open={showUnclaimDialog}
                onClose={() => setShowUnclaimDialog(false)}
                actions={[
                    { label: 'Cancel', onClick: () => setShowUnclaimDialog(false)},
                    { label: 'Unclaim', onClick: onUnclaimLocation}
                    ]}
                title="Confirm Unclaim">
                You are about to unlink the Organization tied to this Location. Are you sure you want to proceed?
            </NICADialog>
            <NICADialog
                open={showUsersDialog}
                onClose={() => setShowUsersDialog(false)}
                actions={[
                    { label: 'Cancel', onClick: () => setShowUsersDialog(false)},
                    { label: 'Save', onClick: () => saveRef.current.click()}
                ]}
                title="Manage Users">
                <EditLocationUsers locationId={locationId} saveRef={saveRef} onClose={() => setShowUsersDialog(false)} />
            </NICADialog>
        </>
    )
};

export default LocationItem;
