import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import {isLocal} from "./api";
import * as serviceWorker from './serviceWorker';

import './index.css';

if (!isLocal()) {
    Sentry.init({dsn: "https://38860ea8319a43bb81afa5becd3ed4be@o392990.ingest.sentry.io/5241431"});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
