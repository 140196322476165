import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    loading: false,
    codes: null,
};

const discountCodesSlice = createSlice({
    name: 'discountCodes',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        codesSuccess(state, action) {
            state.loading = false;
            state.codes = action.payload;
        },
        codeSuccess(state, action) {
            const index = state.codes.map(code => code.id).indexOf(action.payload.id);
            if (index > -1) {
                state.codes[index] = action.payload;
            } else {
                state.codes.push(action.payload);
            }
        },
        deleteCode(state, action) {
            const index = state.codes.map(code => code.id).indexOf(action.payload);
            if (index > -1) {
                state.codes.splice(index, 1);
            }
        }
    }
});

export const { loading, codesSuccess, codeSuccess, deleteCode } = discountCodesSlice.actions;

export default discountCodesSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadDiscountCodes = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        console.log("api", api);
        return api.discountCodes.getAll()
            .then(codes => {
                console.log("Discount Codes", codes);
                return dispatch(codesSuccess(codes));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);

export const saveDiscountCode = (code, setSaving, enqueueSnackbar, onComplete) => (
    async (dispatch, getState, { api }) => {
        try {
            // Check if there's an existing discount code with same slug first
            const existing = await api.discountCodes.getBySlug(code.slug);
            console.log("existing", existing);

            if (existing?.length && existing[0].id !== code.id) {
                if (setSaving) setSaving(false);
                return enqueueSnackbar("Sorry, that slug already exists", {variant: 'error'});
            }

            // Go ahead and save the discount code if all good
            const result = await api.discountCodes.save(code);
            dispatch(codeSuccess(result));

            if (onComplete) onComplete();
            return true;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.message, {variant: 'error'});
            dispatch(loading(false));
            if (setSaving) setSaving(false);
        }
    }
);

export const deleteDiscountCode = (id, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.discountCodes.delete(id)
            .then(() => {
                console.log("Deleted", id);
                return dispatch(deleteCode(id));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);
