import React from 'react';

import AttributeOption from "../attributeOption";

import './index.css';

const AttributeOptions = ({attributeId, parentId, parent, attributeName, type, options, sortOptions, onChange, value, setFieldValue}) => {
    return (
        <div className="flex flex-wrap">
            {Object.keys(options)
                .map(optionId => ({key: optionId, ...options[optionId]}))
                .sort((option1, option2) => sortOptions ? option1.name.localeCompare(option2.name) : -1)
                .map(option => (
                    <AttributeOption
                        key={`attribute-option-${option.key}`}
                        {...{type, attributeId, parentId, parent, attributeName, optionId: option.key, value, onChange, setFieldValue}}
                        option={option} />
                ))}
        </div>
    );
};

export default AttributeOptions