import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import {loadOrganizations} from "../../../redux/organizations";
import {orgsSelector, orgsLoadingSelector} from "../../../redux/organizations/selectors";
import {useLoginSelector} from "../../../redux/login/selectors";

const OrgSelect = ({value, onChange, fullWidth, disabled}) => {
    const orgs = useSelector(orgsSelector);
    const orgsLoading = useSelector(orgsLoadingSelector);
    const {orgIds} = useLoginSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!orgs || !orgs.length) {
            dispatch(loadOrganizations(orgIds, enqueueSnackbar));
        }
    }, [dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        orgsLoading || !orgs ?
            <div className="flex justify-center">
                <CircularProgress size={30} />
            </div>
            :
            <FormControl fullWidth={fullWidth} disabled={disabled}>
                <InputLabel id="organizationLabel">Organization</InputLabel>
                <Select
                    name="organization"
                    value={value}
                    onChange={onChange}
                    labelId="organizationLabel"
                    label="Organization"
                    fullWidth={fullWidth}>
                    {orgs.map((org)=> {
                        return (
                            <MenuItem value={org.id} key={org.id}>{org.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
    )
};

OrgSelect.propTypes = {
    value: PropTypes.string.isRequired,
};

OrgSelect.defaultProps = {
    fullWidth: false,
    disabled: false
};

export default OrgSelect;
