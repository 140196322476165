import React from 'react';
import { medicationsAvailable } from '@devsontap/nica-api/core/utils/attributeIds';

import CustomFormikField from "../../../_common/formik/customField";
import AttributeSection from "../../details/attributes/attributeSection";

import './index.css';

const MedicationsAvailable = ({ setFieldValue }) => {
    return (
        <div className="pb-3 w-full">
            <CustomFormikField
                CustomComponent={AttributeSection}
                name={`attributes.${medicationsAvailable}`}
                key={`attribute-section-${medicationsAvailable}`}
                attributeId={medicationsAvailable}
                errorField="choice"
                setFieldValue={setFieldValue}
                showLabel={false}
                fast />
        </div>
    )
};

export default MedicationsAvailable;
