import React from 'react';
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import numeral from 'numeral';

import ShoppingCartItem from "../item";

const Details = ({cart, onCheckoutClick, cartTotal, claims}) => {
    return (
        <div className="flex flex-col flex-1">
            <div className="shopping-cart-card-items flex-1">
                {cart && cart.length ?
                    cart.map((cartItem, index) => {
                        return (
                            <div key={"cart-item-" + index}>
                                <ShoppingCartItem
                                    item={cartItem}
                                    index={index} />
                                {index < cart.length - 1 ?
                                    <Divider />
                                    :
                                    null
                                }
                            </div>
                        )
                    })
                    :
                    null
                }
            </div>
            <div className="shopping-cart-card-bottom-container">
                <Divider />
                <div className="shopping-cart-card-bottom-title oswald">
                    <div className="flex shopping-cart-card-bottom">
                        <div className="flex-1 shopping-cart-card-bottom-title oswald">
                            Total
                        </div>
                        <div className="shopping-cart-card-bottom-total lato">
                            {numeral(cartTotal / 100.0).format('$0,0.00')}
                        </div>
                    </div>
                </div>
                <div className="shopping-cart-checkout-button-container flex justify-center items-center">
                    <Button color="primary" variant="contained" className="shopping-cart-checkout-button" onClick={onCheckoutClick}>Checkout</Button>
                </div>
            </div>
        </div>
    )
};

export default Details;
