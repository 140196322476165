export const stateObjects = [
  { stateCode: "AL", label: "Alabama" },
  { stateCode: "AK", label: "Alaska" },
  { stateCode: "AZ", label: "Arizona" },
  { stateCode: "AR", label: "Arkansas" },
  { stateCode: "CA", label: "California" },
  { stateCode: "CO", label: "Colorado" },
  { stateCode: "CT", label: "Connecticut" },
  { stateCode: "DE", label: "Delaware" },
  { stateCode: "FL", label: "Florida" },
  { stateCode: "GA", label: "Georgia" },
  { stateCode: "HI", label: "Hawaii" },
  { stateCode: "ID", label: "Idaho" },
  { stateCode: "IL", label: "Illinois" },
  { stateCode: "IN", label: "Indiana" },
  { stateCode: "IA", label: "Iowa" },
  { stateCode: "KS", label: "Kansas" },
  { stateCode: "KY", label: "Kentucky" },
  { stateCode: "LA", label: "Louisiana" },
  { stateCode: "ME", label: "Maine" },
  { stateCode: "MD", label: "Maryland" },
  { stateCode: "MA", label: "Massachusetts" },
  { stateCode: "MI", label: "Michigan" },
  { stateCode: "MN", label: "Minnesota" },
  { stateCode: "MS", label: "Mississippi" },
  { stateCode: "MO", label: "Missouri" },
  { stateCode: "MT", label: "Montana" },
  { stateCode: "NE", label: "Nebraska" },
  { stateCode: "NV", label: "Nevada" },
  { stateCode: "NH", label: "New Hampshire" },
  { stateCode: "NJ", label: "New Jersey" },
  { stateCode: "NM", label: "New Mexico" },
  { stateCode: "NY", label: "New York" },
  { stateCode: "NC", label: "North Carolina" },
  { stateCode: "ND", label: "North Dakota" },
  { stateCode: "OH", label: "Ohio" },
  { stateCode: "OK", label: "Oklahoma" },
  { stateCode: "OR", label: "Oregon" },
  { stateCode: "PA", label: "Pennsylvania" },
  { stateCode: "RI", label: "Rhode Island" },
  { stateCode: "SC", label: "South Carolina" },
  { stateCode: "SD", label: "South Dakota" },
  { stateCode: "TN", label: "Tennessee" },
  { stateCode: "TX", label: "Texas" },
  { stateCode: "UT", label: "Utah" },
  { stateCode: "VT", label: "Vermont" },
  { stateCode: "VA", label: "Virginia" },
  { stateCode: "WA", label: "Washington" },
  { stateCode: "WV", label: "West Virginia" },
  { stateCode: "WI", label: "Wisconsin" },
  { stateCode: "WY", label: "Wyoming" },
];
