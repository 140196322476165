import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";

import { stateObjects } from "../../../utils/states";

const StateSelect = ({name, value, onChange, showLabel, error, restrictToStates}) => {
    const states = useMemo(() => {
        if (restrictToStates) {
            return stateObjects.filter(state => restrictToStates.includes(state.stateCode));
        }
        return stateObjects;
    }, [restrictToStates])

    return (
        <FormControl fullWidth error={error} variant="standard">
            {showLabel &&
                <InputLabel id="state">State</InputLabel>
            }
            <Select
                name={name}
                variant="standard"
                value={value}
                onChange={onChange}
                labelId="state"
                placeholder="Select a state"
                fullWidth>
                {states.map(state => {
                    return (
                        <MenuItem value={state.stateCode} key={state.stateCode}>{state.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
};

StateSelect.propTypes = {
    value: PropTypes.string.isRequired,
};

StateSelect.defaultProps = {
    name: "stateCode"
}

export default StateSelect;
