/*global google*/
/*eslint no-undef: "error"*/

import React, { useState, useEffect } from 'react';
import { useSnackbar } from "notistack";
import cx from 'classnames';

import CustomFormikField from "../../../_common/formik/customField";
import TextField from "@mui/material/TextField";
import PhoneNumberTextField from "../../../_common/textfields/phoneNumber";
import PlacesSearch from "../../../_common/placesSearch";
import useGoogleMaps from "../../../../hooks/useGoogleMap";

import missingLocationImg from '../../../../img/wrong_location_black_48dp.svg';

import './index.css';

const DEFAULT_LAT = 30.3079823;
const DEFAULT_LNG = -97.8938278;

const LocationInfo = ({ values, setFieldValue, errors }) => {
    const [marker, setMarker] = useState(null);
    const map = useGoogleMaps(DEFAULT_LAT, DEFAULT_LNG, 13, {}, "map");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (map && values.latitude !== -1 && values.longitude !== -1) {
            map.panTo(new google.maps.LatLng(values.latitude, values.longitude));

            if (!marker) {
                const newMarker = new google.maps.Marker({
                    position: new google.maps.LatLng(values.latitude, values.longitude),
                    map,
                });

                // TODO: Let admins move them?
                // newMarker.addListener('dragend', event => {
                //     setLocation({ latitude: event.latLng.lat(), longitude: event.latLng.lng() })
                // });

                setMarker(newMarker);
            } else {
                marker.setPosition(new google.maps.LatLng(values.latitude, values.longitude));
            }

        }
    }, [map, values.latitude, values.longitude, marker]);

    return (
        <div className="pb-3">
            <div>
                <CustomFormikField
                    CustomComponent={TextField}
                    variant="standard"
                    name="name"
                    label="Location Name"
                    fullWidth={true}
                    inputProps={{maxLength: 2500}} />
            </div>
            <div className="mt-5">
                <CustomFormikField
                    CustomComponent={TextField}
                    variant="standard"
                    name="description"
                    label="Description"
                    fullWidth={true}
                    helperText={"Describe the infusion center to potential patients and users of the locator. This will be publically displayed in the locator listing. Please do not put any contact information in this area to limit spam."}
                    inputProps={{maxLength: 2500}} />
            </div>

            <div className="mt-5 flex">
                <div className="flex justify-center flex-1">
                    <div className="flex-1 flex flex-col">
                        <div className="font-lato text-lightBlue">
                            Location Address
                        </div>
                        {values.address.line1 ?
                            <div>
                                <div className="mt-4 font-medium mb-1">
                                    {values.address?.line1}
                                </div>
                                <div className="mb-3 pr-8">
                                    <CustomFormikField
                                        CustomComponent={TextField}
                                        variant="standard"
                                        name="address.line2"
                                        label="Address Line 2"
                                        placeholder="Suite 102"
                                        fullWidth={true}
                                        inputProps={{maxLength: 200}}
                                    />
                                </div>
                                <div>
                                    {values.address?.city}, {values.address.stateCode} {values.address.zip}
                                </div>
                            </div> :
                            <div className={cx("mt-4 flex flex-col justify-center items-center pr-6 pt-6", {
                                "error": errors?.address ,
                                "text-gray": !errors?.address
                            })}>
                                <div>
                                    <img src={missingLocationImg} alt="misisng location" />
                                </div>
                                <div className="text-center px-2 mt-2">
                                    Search for an Address to the right in order to set this Location's address.
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex-1">
                        <div className="mb-4">
                            <PlacesSearch map={map} onPlaceSelected={place => {
                                const streetNumber = _getComponent(place, "street_number");
                                const streetName = _getComponent(place, "route");
                                const premise = _getComponent(place, "premise");
                                const subpremise = _getComponent(place, "subpremise");
                                const establishment = _getComponent(place, "establishment");
                                const city = _getComponent(place, "locality") || _getComponent(place, "sublocality") || _getComponent(place, "administrative_area_level_3");
                                const state = _getComponent(place, "administrative_area_level_1");
                                const zip = _getComponent(place, "postal_code");
                                const country = _getComponent(place, "country");
                                if ((streetName || premise || subpremise || establishment) && city && state && zip) {
                                    const address = {
                                        line1: `${streetNumber?.short_name || ""} ${streetName?.short_name || premise?.short_name || subpremise?.short_name || establishment?.short_name}`.trimStart(),
                                        line2: '',
                                        city: city?.short_name,
                                        stateCode: (state?.short_name?.length === 2 ? state?.short_name : country.short_name),
                                        zip: zip?.short_name
                                    };
                                    setFieldValue('address', address, false);
                                    setFieldValue('latitude', place.geometry.location.lat(), false);
                                    setFieldValue('longitude', place.geometry.location.lng(), false);
                                } else {
                                    enqueueSnackbar("Please choose a valid Address", {variant: "error"});
                                }
                            }} />
                        </div>
                        <div id="map" className="flex-1 h-[200px]" />
                    </div>
                </div>
            </div>
            <div className="flex mt-5">
                <div className="flex-1 mr-5">
                    <CustomFormikField
                        variant="standard"
                        CustomComponent={PhoneNumberTextField}
                        name="phoneNumber"
                        label="Phone"
                        type="phone"
                        placeholder="(555) 555-5555"
                        fullWidth={true}
                        inputProps={{maxLength: 14}}
                    />
                </div>
                <div className="flex-1">
                    <CustomFormikField
                        variant="standard"
                        CustomComponent={PhoneNumberTextField}
                        name="faxNumber"
                        label="Fax"
                        type="phone"
                        placeholder="(555) 555-5555"
                        fullWidth={true}
                        inputProps={{maxLength: 14}}
                    />
                </div>
            </div>
            <div className="mt-5">
                <CustomFormikField
                    variant="standard"
                    CustomComponent={TextField}
                    name="emailAddress"
                    label="Email"
                    type="email"
                    placeholder="someone@gmail.com"
                    fullWidth={true}
                    helperText={"Locator users won't be able to contact the location through the locator tool contact form unless a location email address is provided."}
                    inputProps={{maxLength: 200}}
                />
            </div>
            <div className="mt-5">
                <CustomFormikField
                    CustomComponent={TextField}
                    variant="standard"
                    name="url"
                    label="Website"
                    placeholder="https://yoursite.com"
                    fullWidth={true}
                    // helperText={touched.url && errors.url ? `${errors.url}, e.g. "https://yoursite.com"` : "https://yoursite.com"}
                    inputProps={{maxLength: 200}}
                />
            </div>
            <div className="mt-5">
                <CustomFormikField
                    CustomComponent={TextField}
                    variant="standard"
                    name="referralFormUrl"
                    label="Referral Form Url"
                    placeholder="https://yoursite.com/referral_form.pdf"
                    fullWidth={true}
                    // helperText={touched.url && errors.url ? `${errors.url}, e.g. "https://yoursite.com"` : "https://yoursite.com"}
                    inputProps={{maxLength: 200}}
                />
            </div>
        </div>
    )
};

function _getComponent(place, componentName) {
    return place.address_components.find(component => component.types.includes(componentName));
}

export default LocationInfo;
