import React from 'react';
import {addMonths, format} from 'date-fns';

import MemberResourcePerLocationInfo from "../perLocationInfo";
import MemberResourceDownloadInfo from "../downloadInfo";
import {useActivePurchasableSelector} from "../../../../redux/activePurchases/selectors";

const MemberResourceInfo = ({ purchasable }) => {
     const purchasableInfo = useActivePurchasableSelector(purchasable.id);

    if (purchasableInfo) {
        if (purchasable.perLocation) {
            return (
                <MemberResourcePerLocationInfo
                    purchasable={purchasable}
                    purchasableInfo={purchasableInfo} />
            );
        } else if (purchasable.downloadUrl) {
            return (
                <MemberResourceDownloadInfo
                    purchasable={purchasable}
                    activePurchase={purchasableInfo} />
            );
        } else {
            return (
                <div className="p-7">
                    <div className="admin-member-resource-info-title oswald">
                        Current {`${purchasable.title}(s):`}
                    </div>
                    <div className="admin-member-resource-info-expires lato flex justify-center items-centerBoth">
                        {`- Expires ${format(addMonths(purchasableInfo.purchaseDate, purchasableInfo.totalCredits), 'MM/dd/yyyy')}`}
                    </div>
                </div>
            )
        }

    }

    return null;
};

export default MemberResourceInfo;
