import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import UserIcon from "@mui/icons-material/AccountCircle";

import Nav from "../nav";
import LocationManager from "../locationManager";
import MemberResources from "../memberResources";
import Memberships from "../memberships";
import PurchaseHistory from "../purchaseHistory";
import AdminResources from "../adminResources";
import ShoppingCart from "../shoppingCart";
import AdminBulkMessage from "../adminBulkMessage";
import AdminDiscountCodes from "../adminDiscountCodes";
import Organizations from "../organizations";
import AdminUserImpersonate from "../adminUserImpersonate";
import AdminOnly from "../_common/adminOnly";
import NICADialog from "../_common/dialog";
import useStopImpersonatingSnackbar from "../../hooks/useStopImpersonatingSnackbar";

import "./index.css";
import PaymentMethods from "../paymentMethods";

const Home = () => {
    const [showAdminUserImpersonate, setShowAdminUserImpersonate] = useState(false);
    const openLocationFinder = () => window.open(process.env.REACT_APP_LOCATION_FINDER_URL);
    useStopImpersonatingSnackbar();

    return (
        <Router>
            <div>
                <div className="home-nav-container">
                    <Nav />
                </div>
                <div className="home-content-container relative">
                    <AppBar position="sticky" color="primary">
                        <Toolbar disableGutters={true}>
                            <div className="home-toolbar-title-container text-left flex w-full">
                                <div className="home-toolbar-title flex items-center flex-1">
                                    <Switch>
                                        <Route path="/locations" render={() => "Location Manager"} />
                                        <Route path="/member-resources" render={() => "Member Resources"} />
                                        <Route path="/memberships" render={() => "Memberships"} />
                                        <Route path="/purchase-history" render={() => "Purchase History"} />
                                        <Route path="/payment-methods" render={() => "Payment Methods"} />
                                        <Route path="/organizations" render={() => "Organizations"} />
                                        <Route path="/admin-resources" render={() => "Admin Resources"} />
                                        <Route path="/admin-bulk-message" render={() => "Admin Bulk Message"} />
                                        <Route path="/admin-discount-codes" render={() => "Admin Discount Codes"} />
                                        <Route render={() => "Location Manager"} />
                                    </Switch>
                                </div>
                                <AdminOnly noRedirect={true}>
                                    <div className="mr-2">
                                        <Button
                                            color="secondary"
                                            variant="text"
                                            onClick={() => setShowAdminUserImpersonate(true)}
                                        >
                                            <UserIcon className="mr-1" />
                                            <span className="text-white">Impersonate User</span>
                                        </Button>
                                    </div>
                                </AdminOnly>
                                <div className="mr-4">
                                    <Button color="secondary" variant="text" onClick={openLocationFinder}>
                                        <span className="text-white">Location Finder</span>
                                    </Button>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className="flex-1">
                        <Switch>
                            <Route path="/locations/:locationId?/:locationAction?" component={LocationManager} />
                            <Route path="/member-resources" component={MemberResources} />
                            <Route path="/memberships" component={Memberships} />
                            <Route path="/purchase-history" component={PurchaseHistory} />
                            <Route path="/payment-methods" component={PaymentMethods} />
                            <Route path="/organizations" component={Organizations} />
                            <Route path="/admin-resources/:resourceId?" component={AdminResources} />
                            <Route path="/admin-bulk-message" component={AdminBulkMessage} />
                            <Route path="/admin-discount-codes" component={AdminDiscountCodes} />
                            <Route path="/" render={() => <Redirect to="/locations" />} />
                        </Switch>
                    </div>
                </div>
                <ShoppingCart />
            </div>
            <NICADialog
                open={showAdminUserImpersonate}
                onClose={() => setShowAdminUserImpersonate(false)}
                title="Impersonate User"
                actions={[{ label: "Done", onClick: () => setShowAdminUserImpersonate(false) }]}
            >
                <AdminUserImpersonate onClose={() => setShowAdminUserImpersonate(false)} />
            </NICADialog>
        </Router>
    );
};

export default Home;
