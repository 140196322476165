import React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import UserIcon from '@mui/icons-material/PersonAdd';
import CheckCircle from "@mui/icons-material/CheckCircle";
import Check from "@mui/icons-material/Check";
import {useDispatch} from "react-redux";
import {enqueueSnackbar} from "notistack";

import {useOrgSelector} from "../../../redux/organizations/selectors";
import {setPrimaryOrg} from "../../../redux/organizations";

const OrganizationItem = ({ orgId, onEdit, primary }) => {
    const org = useOrgSelector(orgId);
    const dispatch = useDispatch();

    const onSetPrimaryClick = () => {
        dispatch(setPrimaryOrg(orgId, enqueueSnackbar));
    }

    if (Boolean(org)) {
        return (
            <div className="mb-4">
                <Card>
                    <CardContent>
                        <div className="flex">
                            <div className="flex items-center">
                                <BusinessIcon className="mr-2" />
                            </div>
                            <div className="flex items-center flex-1">
                                <div>
                                    {org.name}
                                </div>
                                {primary && <div className="rounded bg-lightBlue text-white p-1 uppercase text-sm font-bold ml-4"><CheckCircle fontSize="small" className="mr-1" />Primary</div>}
                            </div>
                            {!primary &&
                                <div className="flex items-center mr-4">
                                    <Button variant="text" color="primary" onClick={onSetPrimaryClick}>
                                        <Check className="mr-1" /> Set Primary
                                    </Button>
                                </div>
                            }
                            <div className="flex items-center mr-4">
                                <Button variant="text" color="primary" onClick={onEdit}>
                                    <EditIcon className="mr-1" /> Edit
                                </Button>
                            </div>
                            <div className="flex items-center">
                                <Button variant="text" color="primary" onClick={onEdit}>
                                    <UserIcon className="mr-1" /> Invite Users
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return null;
};

export default OrganizationItem;
