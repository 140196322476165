import React, {useState, useRef, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PaymentMethodType} from "@devsontap/nica-api/core/models/enums";
import numeral from 'numeral';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useSnackbar} from "notistack";
import Divider from "@mui/material/Divider";
import { useForm } from "react-hook-form";

import {checkout, loadDiscountCode} from "../../../redux/shoppingCart/";
import Dialog from '../../_common/dialog';
import {
    discountCodeLoadingSelector,
    discountCodeSelector,
} from "../../../redux/shoppingCart/selectors";
import StateSelect from "../../_common/stateSelect";
import PaymentMethodList from "../../_common/paymentMethodList";
import {paymentMethodsSelector} from "../../../redux/paymentMethods/selectors";
import useCartWithFees from "../../../hooks/useCartWithFees";

import './index.css';

const BillingInfo = ({cart, cartTotal, onBackClick, setShowThankYou, email, cartOrg}) => {
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [isAgreedToPayByCheck, setIsAgreedToPayByCheck] = useState(false);
    const [autoRenewWarningDialogOpen, setAutoRenewWarningDialogOpen] = useState(false);
    const [hasSeenAutoRenewWarningDialog, setHasSeenAutoRenewWarningDialog] = useState(false);
    const dispatch = useDispatch();
    const submitRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();
    const discountCodeLoading = useSelector(discountCodeLoadingSelector);
    const discountCode = useSelector(discountCodeSelector);
    const discountCodeForm = useForm();

    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const paymentMethods = useSelector(paymentMethodsSelector());

    const stateCode = watch("stateCode");

    const paymentType = useMemo(() => {
        if (paymentMethod === PaymentMethodType.CHECK.value) {
            return paymentMethod;
        }

        const method = paymentMethods?.find(method => method.id === paymentMethod);

        if (method?.card) {
            return PaymentMethodType.CREDIT.value;
        }
        return PaymentMethodType.ACH.value;
    }, [paymentMethod, paymentMethods]);

    const cartWithFees = useCartWithFees(cart, paymentType, stateCode, discountCode);

    const {discountInPennies, processingFees, salesTaxInPennies, taxableTotal} = useMemo(() => {
        const discountInPennies = cartWithFees.reduce((ret, cartItem) => ret + cartItem.discount, 0);
        const processingFees = cartWithFees.reduce((ret, cartItem) => ret + cartItem.serviceFee, 0);
        const salesTaxInPennies = cartWithFees.reduce((ret, cartItem) => ret + cartItem.salesTax, 0);
        const taxableTotal = cartWithFees.reduce((ret, cartItem) => ret + (cartItem.salesTax ? cartItem.total : 0), 0);
        return { discountInPennies, processingFees, salesTaxInPennies, taxableTotal };
    }, [cartWithFees]);

    const hasAutoRenewItems = useMemo(() => {
        return cart.filter(cartItem => cartItem.autoRenew).length > 0;
    }, [cart]);

    const hasTaxableItems = useMemo(() => {
        let result = false;
        cart.forEach(cartItem => {
            if (cartItem.applySalesTaxInStates?.length) {
                result = true;
            }
        });
        return result;
    }, [cart]);

    const totalWithFees = cartTotal - discountInPennies + processingFees + salesTaxInPennies;

    const toggleAutoRenewWarningDialog = () => {
        setAutoRenewWarningDialogOpen(!autoRenewWarningDialogOpen);
    }

    const onAddDiscountCode = (slug) => {
        if (slug.length > 0) {
            dispatch(loadDiscountCode(slug, enqueueSnackbar));
        }
    }

    const onSubmit = (values) => {
        setLoading(true);
        return dispatch(checkout(paymentMethod, paymentType, cartWithFees, totalWithFees, taxableTotal, salesTaxInPennies, email, cartOrg, values, setLoading, setShowThankYou, enqueueSnackbar));
    }

    return (
        <div className="flex-1 flex flex-col">
            <div className="flex-1 shopping-cart-card-items">
                <div className="lato billing-info-title">
                    Contact Info
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="submit" ref={submitRef} style={{display: "none"}} />
                        <Card className="billing-info-card">
                            <div className="flex">
                                <div className="flex-1">
                                    <TextField
                                        {...register("firstName", { required: "Please enter First Name" })}
                                        label="First Name"
                                        placeholder="John"
                                        variant="standard"
                                        error={Boolean(errors.firstName)}
                                        helperText={errors.firstName?.message}
                                        fullWidth />
                                </div>
                                <div className="flex-1 ml-5">
                                    <TextField
                                        {...register("lastName", { required: "Please enter Last Name" })}
                                        label="Last Name"
                                        placeholder="Smith"
                                        variant="standard"
                                        error={Boolean(errors.lastName)}
                                        helperText={errors.lastName?.message}
                                        fullWidth={true} />
                                </div>
                            </div>
                            <div className="mt-2">
                                <TextField
                                    {...register("title", { required: "Please enter Title Name" })}
                                    label="Title"
                                    placeholder="Nurse"
                                    variant="standard"
                                    error={Boolean(errors.title)}
                                    helperText={errors.title?.message}
                                    fullWidth={true} />
                            </div>
                            {hasTaxableItems &&
                                <div className="mt-2">
                                    <StateSelect
                                        {...register("stateCode", {required: "Please select a State"})}
                                        label="State"
                                        placeholder="State"
                                        variant="standard"
                                        error={errors.stateCode?.message}
                                        showLabel
                                        fullWidth/>
                                </div>
                            }
                        </Card>
                    </form>
                </div>
                {cartTotal > 0 &&
                <>
                    <div className="mt-7">
                        <div className="font-lato billing-info-title mt-7">
                            Discount Code
                        </div>
                        <div className="pt-3 md:pb-2 flex">
                            <div className="flex-1">
                                {/* @ts-ignore */}
                                <TextField
                                    {...discountCodeForm.register('discountCode')}
                                    variant="outlined"
                                    label="Enter Discount Code"
                                    placeholder="3kfi3ssd-nica"
                                    fullWidth />
                            </div>
                            <div className="flex items-center justify-center w-20">
                                {discountCodeLoading ?
                                    <CircularProgress size={35} /> :
                                    <Button variant="text" color="primary" onClick={() => {
                                        onAddDiscountCode(discountCodeForm.getValues('discountCode'));
                                        discountCodeForm.setValue('discountCode', '');
                                    }}>
                                        + Add
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lato billing-info-title mt-7">
                        Payment Method
                    </div>
                    <PaymentMethodList
                        selectedPaymentMethodId={paymentMethod}
                        onChange={paymentMethodId => {
                            setPaymentMethod(paymentMethodId);
                            if (paymentMethodId === PaymentMethodType.CHECK.value && hasAutoRenewItems && !hasSeenAutoRenewWarningDialog) {
                                toggleAutoRenewWarningDialog();
                                setHasSeenAutoRenewWarningDialog(true);
                            }
                        }}  />
                    <div className="mt-4">
                        <Typography>
                            * When paying by Credit Card, a 3% convenience fee will be added to your total.
                        </Typography>
                    </div>
                    {paymentType === PaymentMethodType.CHECK.value &&
                        <div className="mt-4">
                            <Card className="billing-info-card">
                                <Typography>
                                    Pay By Check Terms
                                </Typography>
                                <Typography className="text-base font-light">
                                    I understand that these purchases will not be active until NICA receives payment.
                                    Returned checks will incur a $30 fee.
                                    <br /><br />
                                    Please send a check or money order to...
                                    <br /><br />
                                    National Infusion Center Association<br />
                                    3307 Northland Dr #160<br />
                                    Austin, TX 78731<br />
                                </Typography>
                                <div className="mt-2">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAgreedToPayByCheck}
                                                onChange={(event) => {
                                                    setIsAgreedToPayByCheck(event.target.checked)
                                                }}
                                                name="isAgreedToPayByCheck"
                                            />
                                        }
                                        label="I agree"
                                    />
                                </div>
                            </Card>
                        </div>
                    }
                </>
                }
            </div>
            <div className="shopping-cart-card-bottom-container">
                <Divider />
                <div className="shopping-cart-card-bottom-title oswald">
                    <div className="shopping-cart-card-bottom">
                        {(paymentType === PaymentMethodType.CREDIT.value || discountInPennies > 0 || salesTaxInPennies > 0) &&
                            <div className="shopping-cart-fees mb-2">
                                <div className="flex mb-1">
                                    <div className="flex-1">
                                        Subtotal
                                    </div>
                                    <div>
                                        {numeral(cartTotal / 100.0).format('$0,0.00')}
                                    </div>
                                </div>
                                {discountInPennies > 0 &&
                                    <div className="flex mb-1 text-gray">
                                        <div className="flex-1">
                                            Discount ({discountCode.slug})
                                        </div>
                                        <div>
                                            {numeral(-discountInPennies / 100.0).format('$0,0.00')}
                                        </div>
                                    </div>
                                }
                                {salesTaxInPennies > 0 &&
                                    <div className="flex mb-1">
                                        <div className="flex-1">
                                            Sales Tax
                                        </div>
                                        <div>
                                            {numeral(salesTaxInPennies / 100.0).format('$0,0.00')}
                                        </div>
                                    </div>
                                }
                                {paymentType === PaymentMethodType.CREDIT.value && processingFees > 0 &&
                                    <div className="flex">
                                        <div className="flex-1">
                                            3% Convenience Fee
                                        </div>
                                        <div>
                                            {numeral(processingFees / 100.0).format('$0,0.00')}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className="flex">
                            <div className="flex-1 shopping-cart-card-bottom-title oswald">
                                Total
                            </div>
                            <div className="shopping-cart-card-bottom-total lato">
                                {numeral(totalWithFees / 100.0).format('$0,0.00')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shopping-cart-checkout-button-container flex justify-center items-center">
                    {loading ?
                        <CircularProgress size={35} />
                        :
                        <div className="flex">
                            <div className="billing-info-cancel-button-container">
                                <Button variant="text" color="primary" onClick={onBackClick} className="billing-info-cancel-button">Back</Button>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={
                                    // (paymentType === PaymentMethodType.ACH.value && !bankToken) ||
                                    totalWithFees > 0 &&
                                    (!paymentMethod ||
                                    (paymentType === PaymentMethodType.CHECK.value && !isAgreedToPayByCheck))
                                }
                                onClick={() => { submitRef.current.click() }}
                                className="billing-info-submit-button">
                                Submit
                            </Button>
                        </div>
                    }
                </div>
            </div>
            <Dialog
                title="Auto-Renew Notice"
                open={autoRenewWarningDialogOpen}
                onClose={() => {}}
                actions={[{
                    label: 'OK',
                    onClick: toggleAutoRenewWarningDialog
                }]}
                >
                <Typography>
                    You have items in your cart set to Auto-renew.  This option will be
                    ignored if you choose to pay by check. If you would like to Auto-renew
                    any items in your cart, please select another payment method.
                </Typography>
            </Dialog>
            {/*<div className="flex-1 flex">*/}
            {/*    <div className="flex-1 shopping-cart-card-bottom-title oswald">*/}
            {/*        Total*/}
            {/*    </div>*/}
            {/*    <div className="shopping-cart-card-bottom-total lato">*/}
            {/*        {numeral(cartTotal / 100.0).format('$0,0.00')}*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="mt-7 flex justify-center items-center">*/}
            {/*    {loading ?*/}
            {/*        <CircularProgress size={35} />*/}
            {/*        :*/}
            {/*        <div className="flex">*/}
            {/*            <div className="billing-info-cancel-button-container">*/}
            {/*                <Button variant="text" color="primary" onClick={onBackClick} className="billing-info-cancel-button">Back</Button>*/}
            {/*            </div>*/}
            {/*            <Button variant="contained" color="primary" onClick={() => submitRef.current.click()} className="billing-info-submit-button">Submit</Button>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    )
};

export default BillingInfo;
