import React, {useEffect, useState} from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import LoginForm from "../form";
import {signInWithEmailLink} from "../../../redux/login/";
import {useLoginSelector} from "../../../redux/login/selectors";

import './index.css';

/**
 * Performs the Email Link Verification with Firebase. If we don't have an email stored in localStorage, then we need
 * to collect it here
 */
const LoginVerification = () => {
    const [loading, setLoading] = useState(false);
    const [showConfirmEmail, setShowConfirmEmail] = useState(false);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {authEmail} = useLoginSelector();

    // Attempt to Auto-verify the user when this component loads
    useEffect(() => {
        if (authEmail) {
            signInWithEmail(authEmail);
        } else {
            setShowConfirmEmail(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const signInWithEmail = email => {
        dispatch(signInWithEmailLink(email, setLoading, enqueueSnackbar))
    };

    return (
        <div>
            <div className="lato login-title">
                {!showConfirmEmail ?
                    "Please wait..."
                    :
                    "Confirm email"
                }
            </div>
            {!showConfirmEmail ?
                <div className="login-verify-spinner">
                    <CircularProgress size={45}/>
                </div>
                :
                <LoginForm
                    loading={loading}
                    loginButtonLabel="Confirm Email"
                    helperText="Confirm your email to complete sign in."
                    onSubmit={values => {
                        setLoading(true);
                        signInWithEmail(values.email);
                    }} />
            }
        </div>
    )
};

export default LoginVerification;
