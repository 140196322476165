import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import {useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/PersonAdd';
import {string} from 'yup';
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import {useOrgSelector} from "../../../redux/organizations/selectors";
import {useLoginSelector} from "../../../redux/login/selectors";
import {saveOrganization} from "../../../redux/organizations";

import './index.css';

const EditOrganization = ({ orgId, saveRef, onClose }) => {
    const [addEmail, setAddEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const organization = useOrgSelector(orgId);
    const dispatch = useDispatch();
    const { claims: { email } } = useLoginSelector();
    const { enqueueSnackbar } = useSnackbar();

    const onAddClick = async (emails, setFieldValue) => {
        try {
            await string()
                .email('Please enter a valid email')
                .required('Please enter a valid email')
                .validate(addEmail);

            if (!emails.includes(addEmail)) {
                setFieldValue(`emails[${emails.length}]`, addEmail);
            } else {
                enqueueSnackbar('Email already added', {variant: 'error'});
            }

            setAddEmail('');
            setEmailError(null);
        } catch (err) {
            setEmailError(err.message);
        }
    };

    return (
        <div>
            <Formik
                initialValues={{
                    ...organization
                }}
                onSubmit={(values) => {
                    dispatch(saveOrganization(values));
                    onClose();
                }}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <input type="submit" ref={saveRef} style={{display: "none"}} />
                        <div>
                            <CustomFormikField
                                name="name"
                                label="Organization Name"
                                placeholder="Infusion Center XYZ"
                                CustomComponent={TextField}
                                variant="standard"
                                inputProps={{autoComplete: "off"}}
                                fullWidth />
                        </div>
                        <div className="mt-14">
                            <div className="edit-org-label">
                                Users
                            </div>
                            <div className="flex mt-1">
                                <div className="flex-1">
                                    <TextField variant="standard"
                                        name="email"
                                        type="email"
                                        label="User Email"
                                        placeholder="sue.smith@gmail.com"
                                        value={addEmail}
                                        onChange={event => setAddEmail(event.target.value)}
                                        onKeyDown={event => {
                                            if (event.keyCode === 13) {
                                                onAddClick(values.emails, setFieldValue);
                                                event.preventDefault();
                                            }
                                        }}
                                        inputProps={{autoComplete: "off"}}
                                        error={Boolean(emailError)}
                                        helperText={emailError ? emailError : null}
                                        fullWidth />
                                </div>
                                <div className="ml-2 flex items-end">
                                    <Button variant="text" color="primary" onClick={() => onAddClick(values.emails, setFieldValue)}>
                                        <AddIcon className="mr-1"/> Add User
                                    </Button>
                                </div>
                            </div>
                            <div className="mt-2">
                                {values.emails.map((userEmail, index) => (
                                    <Chip
                                        key={userEmail}
                                        color="default"
                                        label={userEmail}
                                        className="mr-4 mb-2"
                                        onDelete={userEmail !== email ? () => {
                                            const newEmails = [...values.emails];
                                            newEmails.splice(index, 1);
                                            setFieldValue('emails', newEmails, false);
                                        } : null}  />
                                ))}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

export default EditOrganization;
