import {
    PURCHASE_HISTORY_LOADING,
    PURCHASE_HISTORY_SUCCESS,
    PURCHASE_HISTORY_ERROR,
    PURCHASE_HISTORY_REMOVE_SUBSCRIPTION
} from "./types";

/**
 * Fetches all the purchases for the supplied OrgId
 */
export const fetchPurchaseHistory = (orgId, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: PURCHASE_HISTORY_LOADING });
        return api.fetchPurchaseHistory(orgId)
            .then(history => {
                return dispatch({ type: PURCHASE_HISTORY_SUCCESS, payload: history });
            })
            .catch(error => {
                console.error(error);
                dispatch({ type: PURCHASE_HISTORY_ERROR, error });
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const cancelSubscription = (subscriptionId, purchaseId, setLoadingIndex) => (
    (dispatch, getState, { api }) => {
        return api.cancelSubscription(subscriptionId, purchaseId)
            .then(result => {
                dispatch({type: PURCHASE_HISTORY_REMOVE_SUBSCRIPTION, payload: {subscriptionId, purchaseId}});
                setLoadingIndex(-1);
            })
            .catch(error => {
                // TODO: wat?
                setLoadingIndex(-1);
            })
    }
);
