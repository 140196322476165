import { createSlice } from '@reduxjs/toolkit';
import {guid} from "../../utils/guid";

const INITIAL_STATE = {
    loading: true,
    locationLoading: false,
    locationReportLoading: true,
    locationIds: null,
    locationMap: {},
    reportMap: {},
    locationError: null,
    filter: null
};

const locationsSlice = createSlice({
    name: 'locations',
    initialState: INITIAL_STATE,
    reducers: {
        locationsLoading(state, action) {
            state.loading = action.payload;
        },
        locationsSuccess(state, action) {
            state.loading = false;
            state.locationMap = action.payload.reduce((ret, location) => {
                ret[location.id] = location;
                return ret;
            }, state.locationMap);
            state.locationIds = Object.keys(state.locationMap);
        },
        locationsError(state, action) {
            state.loading = false;
        },
        locationReportLoading(state, action) {
            state.locationReportLoading = true;
        },
        locationReportSuccess(state, action) {
            state.locationReportLoading = false;
            state.reportMap[action.payload.locationId] = action.payload.results;
        },
        locationReportError(state, action) {
            state.locationReportLoading = false;
        },
        locationLoading(state, action) {
            state.locationLoading = true;
        },
        locationSuccess(state, action) {
            state.locationLoading = false;
            state.locationMap[action.payload.id] = action.payload;
            state.locationIds = Object.keys(state.locationMap);
        },
        locationError(state, action) {
            state.locationLoading = false;
        },
        locationDelete(state, action) {
            delete state.locationMap[action.payload];
            state.locationIds = Object.keys(state.locationMap);
        }
    }
});

export const {
    locationsLoading, locationsSuccess, locationsError, locationReportLoading, locationReportSuccess, locationReportError,
    locationLoading, locationSuccess, locationError, locationDelete
} = locationsSlice.actions;

export default locationsSlice.reducer;

/**
 * Custom Thunk Actions
 */

export const loadAllLocations = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(locationsLoading(true));
        return api.locations.getAllActiveLocations()
            .then(locations => {
                return dispatch(locationsSuccess(locations));
            })
            .catch(error => {
                console.error(error);
                dispatch(locationsError);
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const loadAdminLocationReport = (setLoading, setReport, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.getAdminLocationReport()
            .then(report => {
                setLoading(false);
                setReport(report);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

/**
 * Loads all the locationManager for a single organization from Firestore
 */
export const loadOrgLocations = (orgIds, enqueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(locationsLoading(true));
        return api.locations.getByOrgId(orgIds)
            .then(locations => {
                return dispatch(locationsSuccess(locations));
            })
            .catch(error => {
                console.error(error);
                dispatch(locationsError());
            });
    }
);

export const loadUserLocations = (orgIds, email, admin, enqueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(locationsLoading(true));
        return api.locations.getByOrgId(orgIds)
            .then(async (orgLocations) => {
                let inactiveLocations = [];
                if (admin) {
                    inactiveLocations = await api.locations.getInactiveLocations();
                }
                const userLocations = await api.locations.getLocationsByEmail(email);

                const locations = orgLocations.concat(inactiveLocations).concat(userLocations);
                locations.sort((location1, location2) => {
                    if (location1.active) {
                        if (location2.active) {
                            return location1.name.localeCompare(location2.name);
                        }
                        return 1;
                    } else if (location2.active) {
                        return -1;
                    }
                    return location1.name.localeCompare(location2.name)
                });

                return dispatch(locationsSuccess(locations));
            })
            .catch(error => {
                console.error(error);
                dispatch(locationsError());
            });
    }
);

export const loadLocationsWithEmail = () => (
    (dispatch, getState, { api }) => {
        dispatch(locationsLoading(true));
        return api.locations.getAllLocationsWithEmailSet()
            .then(locations => {
                return dispatch(locationsSuccess(locations));
            })
            .catch(error => {
                console.error(error);
                dispatch(locationsError());
            });
    }
);

export const fetchLocationReport = (locationId, startDate, endDate) => (
    (dispatch, getState, { api }) => {
        dispatch(locationReportLoading(true));
        return api.getLocationReport(locationId, startDate, endDate)
            .then(results => {
                return dispatch(locationReportSuccess({locationId, results: results.data}));
            })
            .catch(error => {
                console.error(error);
                dispatch(locationReportError());
            });
    }
);

export const fetchLocation = (locationId, enqueueSnackbar, onError) => (
    (dispatch, getState, { api }) => {
        dispatch(locationLoading(true));
        return api.locations.get(locationId)
            .then(location => {
                return dispatch(locationSuccess(location));
            })
            .catch(error => {
                console.error(error);
                dispatch(locationError());
                enqueueSnackbar(error.message, { variant: 'error' });

                if (onError) return onError();
            });
    }
);

export const getBulkImportLocations = (setLoading, setResults, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.getBulkImportLocations()
            .then(response => {
                console.log("response", response);
                setLoading(false);
                setResults(response);
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const bulkImportLocations = (locations, setImporting, onClose, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const bulkUpload = api.createBulkUploadRecord();
        return Promise.all(locations.map(location => {
            location.bulkUploadId = bulkUpload.key;
            return api.saveLocation(location);
        }))
            .then(() => {
                enqueueSnackbar("Locations Imported", {variant: 'success'});
                onClose();
            })
            .catch(err => {
                setImporting(false);
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            })
    }
);

export const deleteLocation = (locationId) => (
    (dispatch, getState, { api }) => {
        return api.locations.delete(locationId)
            .then(() => {
                return dispatch(locationDelete(locationId));
            })
            .catch(error => {
                console.error(error);
            });
    }
);

export const saveLocation = (location, setSaving = () => {}, goBack, geocode, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        if (geocode) {
            const coords = await api.geocodeAddress(`${location.address.line1} ${location.address.city}, ${location.address.stateCode} ${location.address.zip}`);
            if (coords.lat && coords.lng) {
                location.latitude = coords.lat;
                location.longitude = coords.lng;
            } else {
                setSaving(false);
                return enqueueSnackbar("Error Geocoding Address. Please adjust and try again.", {variant: 'error'});
            }
        }

        return api.locations.save(location)
            .then(result => {
                console.log("done", result);
                if (goBack) {
                    goBack();
                }
                console.log("saveLocation result", result);
                return dispatch(locationSuccess(result));
            })
            .catch(error => {
                console.error(error);
                setSaving(false);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const uploadFile = (file, setUploading, photos, handlePhotosChange) => (
    async (dispatch, getState, { api }) => {
        const downloadUrl = await api.uploadFile(file, guid());
        const newPhotos = [...photos, downloadUrl];
        handlePhotosChange(newPhotos);
        setUploading(false);
    }
);

export const unclaimLocation = (location) => (
    async (dispatch, getState, { api }) => {
        return api.saveLocation({
            ...location,
            orgId: null,
        })
            .then(result => dispatch(locationSuccess(result)))
            .catch(error => {
                console.error(error);
            });
    }
);

export const sendBulkMessage = (locationIds, message) => (
    (dispatch, getState, { api }) => {
        return api.createBulkMessage(locationIds, message);
    }
);

export const loadLocationContacts = (locationId, setLoading, setContacts, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.locations.get(locationId)
            .then(location => {
                setLoading(false);
                setContacts([
                    location.phoneNumber,
                    ...(location.users || [])
                ]);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const duplicateLocation = (location, setLoading, history, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const copy = {
            ...location,
            name: `Copy of ${location.name}`,
            active: false
        };
        delete copy.id;
        delete copy.bulkUploadId;

        return api.locations.save(copy)
            .then(result => {
                setLoading(false);
                dispatch(locationSuccess(result));
                history.push(`/locations/${result.id}`);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
)
