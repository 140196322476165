import React from 'react';
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {Formik, Form} from "formik";
import * as Yup from "yup";

import CustomFormikField from "../../_common/formik/customField";

import './index.css';

const LoginForm = ({ loading, onSubmit, loginButtonLabel, helperText }) => {
    return (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email("Please enter a valid email").required()
            })}
            onSubmit={onSubmit}>
            <Form>
                {helperText &&
                    <div className="login-message">
                        {helperText}
                    </div>
                }
                <div className="mt-2">
                    <CustomFormikField
                        name="email"
                        label="Email"
                        placeholder="john.smith@gmail.com"
                        CustomComponent={TextField}
                        fullWidth />
                </div>
                <div className="flex justify-end mt-5">
                    {loading ?
                        <CircularProgress size={35}/>
                        :
                        <Button type="submit" variant="contained" color="primary">
                            {loginButtonLabel}
                        </Button>
                    }

                </div>
            </Form>
        </Formik>
    )
};

LoginForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loginButtonLabel: PropTypes.string,
    helperText: PropTypes.string
};

LoginForm.defaultProps = {
    loginButtonLabel: "Sign In"
};

export default LoginForm;
