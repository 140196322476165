import React from 'react';
import { Formik, Form } from "formik";
import { SCHEMA } from '@devsontap/nica-api/core/models/discountCode';
import { DiscountCodeTypes } from '@devsontap/nica-api/core/models/enums';
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import DiscountCodeTypeSelect from "./typeSelect";
import { useSelector } from "react-redux";
import { saveDiscountCode } from "../../../redux/discountCodes";
import { discountCodeSelector } from "../../../redux/discountCodes/selectors";
import TenderEntryTextField from "../../_common/textfields/tenderEntry";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/PersonAdd";
import Button from "@mui/material/Button";
import PurchasableSelect from "./purchasableSelect";
import MembershipTypeSelect from "./membershipTypeSelect";

const EditDiscountCode = ({ codeId, submitRef, onClose, setSaving }) => {
    const code = useSelector(discountCodeSelector(codeId));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div>
            <Formik
                initialValues={{
                    slug: '',
                    type: DiscountCodeTypes[0].value,
                    discountAmount: '',
                    credits: '',
                    addEmail: '',
                    addPurchasable: '',
                    restrictByEmails: [],
                    restrictByPurchasables: [],
                    restrictByMembershipTypes: [],
                    ...(code || {})
                }}
                validationSchema={SCHEMA}
                onSubmit={(values) => {
                    setSaving(true);
                    dispatch(saveDiscountCode(values, setSaving, enqueueSnackbar, () => {
                        setSaving(false);
                        onClose()
                    }));
                }}>
                {({ values, setFieldValue, errors }) => (
                    <Form autoComplete="off">
                        <input type="Submit" className="hidden" ref={submitRef} />
                        <div className="mb-6 flex">
                            <div className="flex-1 mr-6">
                                <CustomFormikField
                                    CustomComponent={TextField}
                                    name="slug"
                                    placeholder="nica-frands"
                                    variant="standard"
                                    label="Slug"
                                    fullWidth />
                            </div>
                            <div className="flex-1">
                                <CustomFormikField
                                    CustomComponent={DiscountCodeTypeSelect}
                                    name="type"
                                    label="Type"
                                    fullWidth />
                            </div>
                        </div>
                        <div className="flex mb-6">
                            <div className="flex-1 mr-6">
                                {values.type === "PERCENT" ?
                                    <CustomFormikField
                                        CustomComponent={TextField}
                                        name="discountAmount"
                                        type="number"
                                        label="Discount Amount"
                                        variant="standard"
                                        fullWidth /> :
                                    <CustomFormikField
                                        CustomComponent={TenderEntryTextField}
                                        name="discountAmount"
                                        label="Discount Amount"
                                        variant="standard"
                                        setValue={(fieldName, value) => setFieldValue(fieldName, value)}
                                        fullWidth />
                                }
                            </div>
                            <div className="flex-1">
                                <CustomFormikField
                                    CustomComponent={TextField}
                                    name="credits"
                                    type="number"
                                    label="# Credits"
                                    variant="standard"
                                    fullWidth />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-1">
                                <CustomFormikField
                                    CustomComponent={TextField}
                                    name="addEmail"
                                    type="email"
                                    variant="standard"
                                    label="Add Restricted User"
                                    placeholder="sue.smith@gmail.com"
                                    onKeyDown={event => {
                                        if (event.keyCode === 13) {
                                            setFieldValue('restrictByEmails', [...values.restrictByEmails, event.target.value], false);
                                            setFieldValue('addEmail', '');
                                            event.preventDefault();
                                        }
                                    }}
                                    inputProps={{autoComplete: "off"}}
                                    fullWidth />
                            </div>
                            <div className="pt-3 ml-2">
                                <Button variant="text" color="primary" onClick={() => {
                                    if (values.addEmail.length > 0) {
                                        setFieldValue('restrictByEmails', [...values.restrictByEmails, values.addEmail], false);
                                        setFieldValue('addEmail', '');
                                    }
                                }}>
                                    <AddIcon className="mr-1"/> Add
                                </Button>
                            </div>
                        </div>
                        <div className="mt-3">
                            {values.restrictByEmails.map((email, index) => (
                                <div key={email} className="mr-2 mb-1">
                                    <Chip
                                        color="default"
                                        label={email}
                                        onDelete={() => {
                                            const newEmails = [...values.restrictByEmails];
                                            newEmails.splice(index, 1);
                                            setFieldValue('restrictByEmails', newEmails, false);
                                        }}  />
                                </div>
                            ))}
                        </div>
                        {errors.restrictByEmails &&
                            <div className="font-italic text-sm font-sm text-[#ff0000]">
                                <i>{errors.restrictByEmails}</i>
                            </div>
                        }
                        <div className="flex mt-6">
                            <div className="flex-1 max-w-[470px]">
                                <CustomFormikField
                                    CustomComponent={PurchasableSelect}
                                    name="addPurchasable"
                                    fullWidth
                                />
                            </div>
                            <div className="pt-3 ml-2">
                                <Button variant="text" color="primary" onClick={() => {
                                    if (values.addPurchasable.length > 0) {
                                        setFieldValue('restrictByPurchasables', [...values.restrictByPurchasables, values.addPurchasable], false);
                                    }
                                }}>
                                    + Add
                                </Button>
                            </div>
                        </div>
                        <div className="mt-3 flex flex-wrap">
                            {values.restrictByPurchasables.map((purchasableId, index) => (
                                <div key={purchasableId} className="mr-2 mb-2">
                                    <Chip
                                        color="default"
                                        label={purchasableId}
                                        onDelete={() => {
                                            const newEmails = [...values.restrictByPurchasables];
                                            newEmails.splice(index, 1);
                                            setFieldValue('restrictByPurchasables', newEmails, false);
                                        }}  />
                                </div>
                            ))}
                        </div>
                        {errors.restrictByPurchasables &&
                            <div className="font-italic text-sm font-sm text-[#ff0000]">
                                <i>{errors.restrictByPurchasables}</i>
                            </div>
                        }
                        <div className="flex mt-6">
                            <div className="flex-1">
                                <CustomFormikField
                                    CustomComponent={MembershipTypeSelect}
                                    name="addMembershipType"
                                    fullWidth />
                            </div>
                            <div className="pt-3 ml-2">
                                <Button variant="text" color="primary" onClick={() => {
                                    if (values.addMembershipType.length > 0) {
                                        setFieldValue('restrictByMembershipTypes', [...values.restrictByMembershipTypes, values.addMembershipType], false);
                                    }
                                }}>
                                    <AddIcon className="mr-1"/> Add
                                </Button>
                            </div>
                        </div>
                        <div className="mt-3 flex">
                            {values.restrictByMembershipTypes.map((membershipType, index) => (
                                <div key={membershipType} className="mr-2 mb-1">
                                    <Chip
                                        color="default"
                                        label={membershipType}
                                        onDelete={() => {
                                            const newMembershipTypes = [...values.restrictByMembershipTypes];
                                            newMembershipTypes.splice(index, 1);
                                            setFieldValue('restrictByMembershipTypes', newMembershipTypes, false);
                                        }}  />
                                </div>
                            ))}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

export default EditDiscountCode;
