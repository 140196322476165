import {createSelector} from "@reduxjs/toolkit";

import {useShallowEqualSelector} from "../index";
import {primaryOrgIdSelector, orgIdsSelector} from "../login/selectors";

export const orgsLoadingSelector = state => state.orgs.orgsLoading;
export const orgSelector = orgId => state => state.orgs.orgMap[orgId];
const orgMapSelector = state => state.orgs.orgMap
export const selectedOrgIdSelector = state => state.orgs.selectedOrgId;

export const defaultOrgIdSelector = createSelector(
    selectedOrgIdSelector, primaryOrgIdSelector, orgIdsSelector,
    (selectedOrgId, primaryOrgId, orgIds) => {
        return selectedOrgId || primaryOrgId || (orgIds ? orgIds[0] : "")
    }
)

export const orgsSelector = createSelector(
    orgMapSelector, primaryOrgIdSelector,
    (orgMap, primaryOrgId) => {
        const orgs = Object.values(orgMap);
        orgs.sort((o1, o2) => {
            if (o1.id === primaryOrgId) {
                return -1;
            } else if (o2.id === primaryOrgId) {
                return 1;
            }
            return 0;
        });
        return orgs;
    }
);

export const useOrgsSelector = () => {
    return useShallowEqualSelector(({ orgs: { orgsLoading, orgMap } }) => {
        return {
            orgsLoading,
            orgs: orgMap ? Object.keys(orgMap).map(key => orgMap[key]) : null
        }
    });
};

export const useOrgSelector = (orgId) => {
    return useShallowEqualSelector(({ orgs: { orgMap } }) => {
        return orgMap[orgId];
    });
};
