import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import IconButton from "@mui/material/IconButton";

import DropZone from "../../../_common/dropZone";
import {uploadFile} from "../../../../redux/locations";

import remove_btn from '../../../../img/btn-remove-photo.svg';

import './index.css';

const StreetViewOverride = ({streetViewImage, handleChange}) => {
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();

    const handleStreetViewChange = (newPhotos) => {
        handleChange({ target: { name: "google_street_view_image", value: newPhotos[0] }})
    };

    const onUpload = (files) => {
        setUploading(true);
        dispatch(uploadFile(files[0], setUploading, [], handleStreetViewChange));
    };

    const onDeleteClick = () => {
        handleStreetViewChange([null]);
    };

    return (
        <div>
            {!streetViewImage ?
                <DropZone
                    outline={true}
                    accepts=".jpg,.jpeg,.png,.gif"
                    loading={uploading}
                    onDrop={onUpload} />
                :
                <div className="relative flex">
                    <div className="absolute top-0 right-0">
                        <IconButton tooltip="Remove" onClick={onDeleteClick}>
                            <img src={remove_btn} alt="remove button" />
                        </IconButton>
                    </div>
                    <img className="location-details-image-preview" src={streetViewImage} alt="location edit preview" />
                </div>
            }
        </div>
    );
};

export default StreetViewOverride;
